import {
    Logout,
    Login,
    PageNotFound,
    Dashboard,
    ChangePassword,
    TermAndConditions,
    MerchantApproval,
    InvoiceSetting,
    VoucherManagement,
    MerchantManagement
} from 'pages';

const routes = [
    { path: '/login', name: 'Login', component: Login },
    { path: '/logout', name: 'Add Component', component: Logout},
    { path: '/', name: 'Dashboard', component: Dashboard},
    { path: '/reset-password', name: 'ChangePassword', component: ChangePassword},
    { path: '/terms', name: 'TermAndConditions', component: TermAndConditions},
    { path: '/admin/merchant-approval/:merchantId/:referenceId', name: 'MerchantApproval', component: MerchantApproval},
    { path: '/admin/invoice-setting/:shortCode', name: 'InvoiceSetting', component: InvoiceSetting},
    { path: '/admin/voucher-management', name: 'VoucherManagement', component: VoucherManagement},
    { path: '/admin/merchant-management', name: 'MerchantManagement', component: MerchantManagement},
    { path: '/*', name: 'PageNotFound', component: PageNotFound}
];

export default routes;
