import React, { useContext, useReducer } from 'react';
import Actions from '../actions/index';

export const reducer = (state, action) => {
  if (action.type && state[action.type]) {
    return {
      ...state,
      [action.type]: {...action}
    }
  }
  console.debug('The Action Type is invalid: ', action);
  return state;
}

export const initialState = Actions;

export const AppContext = React.createContext();

export const StateProvider = ({ reducer, initialState, children }) => {
  return (
    <AppContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AppContext.Provider>
)};


export function useAppContext() {
  return useContext(AppContext);
}