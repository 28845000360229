import React, { useEffect, useState } from 'react';
import { Modal, Button, FormControl, FormGroup, ControlLabel, InputGroup, utils } from 'react-bootstrap';
import { Grid, Typography } from '@material-ui/core';
import ApiService from 'services/apiService';
import { useUserContext, createUserObj } from 'context/UserContext';
import { useAppContext } from 'context/AppContext';
import { useHistory } from 'react-router-dom';
import iconLock from 'assets/images/password_grey_background.svg';
import iconEye from 'assets/images/eye.svg'
import iconGrayCheck from 'assets/images/gray-checkmark-line.svg';
import iconGreenCheck from 'assets/images/green-checkmark-line.svg';
import iconEyeSlash from 'assets/images/eye-slash.svg'
import classNames from 'classnames';
import _ from 'lodash';
import Actions from 'actions/index';
import imgLoginBg from 'assets/images/login-bg.jpg';
import imgLoginBgXs from 'assets/images/login-bg_xs.jpg';
import { ReactComponent as LogoWhiteIcon } from 'assets/images/logo-tyme-digital-white.svg';
import Constant from 'utils/Constant';

utils.bootstrapUtils.addStyle(Button, 'yellow');
export const inputValidation = [
    {
        validate: input => /[a-z]/.test(input),
        message: 'One lower letter',
        passed: false
    },
    {
        validate: input => /[$&+,:;=?@#|'<>.^*()%!-]/.test(input),
        message: 'One special character (e.q .#*%)',
        passed: false
    },
    {
        validate: input => /[A-Z]/.test(input),
        message: 'One uppercase letter',
        passed: false
    },
    {
        validate: input => input ? input.length >= 8 : false,
        message: '8 characters',
        passed: false
    },
    {
        validate: input => /[0-9]/.test(input),
        message: 'One number',
        passed: false
    }
];
const ChangePassword = () => {
    const setAppState = useAppContext()[1];
    const history = useHistory();
    const { userObj, setUserObj } = useUserContext();
    const [state, setState] = useState({
        newPassword: '',
        showPassword: false,
        newPasswordError: '',
        confirmPassword: '',
        confirmPasswordError: '',
        showconfirmPassword: false,
        inputValidation: inputValidation
    });
    const title = 'Let\'s change your password';
    const className = classNames('confirm-modal');
    useEffect(() => {
        if (!userObj.needUpdatePassword) {
        history.push('/');
        }
    }, [history, userObj.needUpdatePassword]);
    const showHidePassword = (e, name) => {
        if (name === 'password') {
            setState({
                ...state,
                showPassword: !state.showPassword
            });
        } else if (name === 'confirmPassword') {
            setState({
                ...state,
                showconfirmPassword: !state.showconfirmPassword
            });
        }
    }
    const renderIcon = (src) => {
        return (
            <img alt="" src={src} />
        );
    };
    const isValidPassword = () => {
        const { newPassword, confirmPassword } = state;
        let valid = true;
        const errorState = {
            newPasswordError: '',
            confirmPasswordError: ''
        }
        if (Boolean(newPassword)) {
            const count = _.countBy(state.inputValidation, i => i.passed);
            if (Boolean(count.false)) {
                errorState.newPasswordError = 'Your password must contain all requirements below';
                valid = false;
            }
        } else {
            errorState.newPasswordError = 'Please enter your new password';
            valid = false;
        }
        if (Boolean(confirmPassword)) {
            if (Boolean(newPassword) && confirmPassword !== newPassword) {
                errorState.confirmPasswordError = 'The new password and confirmation password do not match';
                valid = false;
            }
        } else {
            errorState.confirmPasswordError = 'Please re-enter your new password';
            valid = false;
        }
        setState({
            ...state,
            ...errorState
        });
        return valid;
    }
    const changePassword = e => {
        if (isValidPassword()) {
            doChangePassword();
        }
    }
    const doChangePassword = () => {
        const { newPassword } = state;
        ApiService.completeNewPassword(userObj.congitoUser, newPassword)
            .then(response => {
                ApiService.logout()
                    .then((res) => {
                        setAppState({
                            type: Actions.toast.type,
                            hidden: false,
                            props: {
                                type: 'success',
                                message: 'Your password has been successfully changed. You can now login to your account.'
                            }
                        });
                        setUserObj(createUserObj());
                        history.replace({ from: { pathname: '/' } });
                    }).catch(err => {
                        console.debug(err);
                    })
            })
            .catch(err => {
                console.debug(err);
                setAppState({
                    type: Actions.toast.type,
                    hidden: false,
                    props: {
                        type: 'error',
                        message: err.message
                    }
                });
            })
    }
    const renderButtons = () => {
        return (
            <div className={classNames('modal-buttons')}>
                <Button
                    bsStyle="yellow"
                    onClick={changePassword}
                >
                    Change password
                  </Button>
            </div>
        );
    };

    const renderValidation = () => {
        return (
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                {inputValidation.map((element, index) => {
                    return <Grid item xs={6} key={`criteria-${index}`} style={{padding: 0, margin: 0}}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'normal' }}>
                            <img alt="" src={state.inputValidation[index].passed ? iconGreenCheck : iconGrayCheck} /> {' '}
                            {element.message}
                        </Typography>
                    </Grid>
                })}
            </Grid>
        );
    }
    const renderInputs = () => {
        return (
            <div>
                <ControlLabel>New password</ControlLabel>
                <FormGroup validationState={Boolean(state.newPasswordError) ? 'error' : null} bsSize='large'>
                    <InputGroup>
                        <FormControl
                            style={{ borderRight: 'None' }}
                            required
                            type={state.showPassword ? 'text' : 'password'}
                            placeholder="Enter your new password"
                            value={state.newPassword}
                            onChange={handleInputChange('newPassword')}
                            maxLength={Constant.PASSWORD_MAX_LENGTH}
                        />
                        <InputGroup.Addon style={{
                            backgroundColor: 'white',
                            borderLeft: 'None',
                            borderTopRightRadius: '6px',
                            borderBottomRightRadius: '6px',
                        }}>
                            <span>
                                <img alt="" src={state.showPassword ? iconEyeSlash : iconEye} onClick={(e) => showHidePassword(e, 'password')} />
                            </span>
                        </InputGroup.Addon>
                    </InputGroup>
                    <div className="error-text">
                        {state.newPasswordError}
                    </div>
                </FormGroup>

                <ControlLabel>Confirm new password</ControlLabel>
                <FormGroup validationState={Boolean(state.confirmPasswordError) ? 'error' : null} bsSize='large'>
                    <InputGroup>
                        <FormControl
                            style={{ borderRight: 'None', height: '48px' }}
                            required
                            type={state.showconfirmPassword ? 'text' : 'password'}
                            autoComplete="username"
                            placeholder="Re-enter your new password"
                            value={state.confirmPassword}
                            onChange={handleInputChange('confirmPassword')}
                            maxLength={Constant.PASSWORD_MAX_LENGTH}
                        // onKeyPress={(e) => handleKeyPress(e, !disableLoginButton)}
                        >
                        </FormControl>
                        <InputGroup.Addon style={{ backgroundColor: 'white', borderLeft: 'None' }}>
                            <span >
                                <img alt="" src={state.showconfirmPassword ? iconEyeSlash : iconEye} onClick={(e) => showHidePassword(e, 'confirmPassword')} />
                            </span>
                        </InputGroup.Addon>
                    </InputGroup>
                    <div className="error-text">
                        {state.confirmPasswordError}
                    </div>
                </FormGroup>
                <FormGroup style={{
                    marginTop: '40px'
                }}>
                    <strong className="font-bold">Your password must contain at least:</strong>
                    {renderValidation()}
                </FormGroup>
            </div>
        );
    }
    const handleInputChange = (name) => (e) => {
        const { value } = e.target;
        const newsShemaValidation = inputValidation.map((item) => {
            item.passed = item.validate(value);
            return item;
        })
        setState({
            ...state,
            [name]: value,
            inputValidation: newsShemaValidation
        });
    };
    return (
        <div className="loginPage">
            <style>
                {`
                        html{
                            overflow: auto;
                        }
                        #root {
                            background: url(${imgLoginBg}) no-repeat center top;
                            background-size: cover;
                        }

                        @media screen and (max-width:767px) {
                            #root {
                                background-image: url(${imgLoginBgXs});
                                background-position: 70% 0;
                            }
                        }
                        footer {
                            position: relative;
                        }
                        .page-footer {
                            margin-top:0;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                        }

                        .text-about-cba {
                            display:block!important;
                        }

                        body{
                            height:auto;
                            min-height:100%;
                            overflow:auto;
                            padding-right: 0px!important;
                        }

                        #body {
                            margin-top: 0;
                            position: relative;
                        }

                        .login-errors-line{
                            font-size: 16px;
                            margin-bottom:1em;
                        }

                        .logo svg {
                            width: 360px;
                            height: 90px
                        }

                        @media screen and (max-width:767px) {
                            .login-errors-line{
                                font-size: 14px;
                            }
                            .logo svg {
                                width: 240px;
                                height: 62px;
                            }
                        }

                    `}
            </style>
            <p className="bg-overlay" />
            <div className="wrapper">
                <div className="header">
                    <div className="container text-center">
                        <a className="logo">
                            <LogoWhiteIcon />
                        </a>
                    </div>
                </div>
                <Modal
                    className={className}
                    show={true}
                    animation
                    backdrop='static'
                >
                    <Modal.Header >
                        <Modal.Title componentClass="span">{renderIcon(iconLock)} {title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {renderInputs()}
                        {renderButtons()}
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    );
};

export default ChangePassword;