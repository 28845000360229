import React from 'react';
import LogoutConfirm from '../logout/LogoutConfirm';
import SessionTimeoutConfirm from './SessionTimeoutConfirm';
import WaitModal from './WaitModal';

/**
 * Contains modals that will be only rendered for Consumers
 */
const Modals = () => {
    return (
        <div>
            <LogoutConfirm />
            {/* <SessionTimeoutConfirm /> */}
            <WaitModal />
        </div>
    );
}
export default Modals;
