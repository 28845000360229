import React, { useEffect, useState } from 'react';
import { Media } from 'react-bootstrap';
import { Box } from 'rebass/styled-components';

import {ReactComponent as LockIcon} from 'assets/images/lock.svg';
import { ReactComponent as LogoIcon } from 'assets/images/logo-tyme-digital.svg';
import { useLocation } from 'react-router-dom';
import { colorMapping } from '../../utils/index';
import Constant from 'utils/Constant';


const Footer = (props) => {
    const phoneNumber = Constant.PHONE_NUMBER;
    const emailSupport = Constant.EMAIL_SUPPORT;
    const location = useLocation();
    const [show, setShow] = useState(false);
    const path = location.pathname;

    useEffect(() => {
        if ('/reset-password' === path || '/forgot-password' === path || '/terms' === path || '/logout' === path ) {
            setShow(false);
        } else {
            setShow(true);
        }
    }, [location.pathname]);
    return (show ?
    <Box as="footer" flexShrink={0} id='login-footer'>
        <div className="page-footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-7 col-xs-7">
                        <Media>
                            <Media.Left className="hidden-xs">
                                <LockIcon className="lock-icon" />
                            </Media.Left>
                            <Media.Body align="middle">
                                <div className="text">
                                    Your security is extremely important to us. All information is constantly protected and monitored. <br />
                                    { '/login' === path ? null :
                                    <>
                                    Need help? Call us on <span className="font-bold" style={{ color: colorMapping('yellow') }}>{phoneNumber}</span> (7 AM SAST to 8 PM SAST 7 days a week) <br />
                                    or contact us via email{' '} <span className="font-bold" style={{ color: colorMapping('yellow') }} >{emailSupport}</span>
                                    </>
                                    }
                                    </div>
                            </Media.Body>
                        </Media>
                    </div>
                    <div className="col-sm-5 col-xs-5">
                        <div className="logo-footer">
                            <LogoIcon className="logo-icon" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Box> : <div></div>
    );
}

export default Footer;

