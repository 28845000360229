import React, { useEffect, useState } from 'react';
import { ConfirmModal } from 'components/common/ConfirmModal';
import { FormControl, FormGroup, ControlLabel, InputGroup } from 'react-bootstrap';
import ApiService from 'services/apiService';
import { inputValidation } from 'pages/reset-password/ChangePassword';
import iconGrayCheck from 'assets/images/gray-checkmark-line.svg';
import iconGreenCheck from 'assets/images/green-checkmark-line.svg';
import iconEye from 'assets/images/eye.svg'
import iconEyeSlash from 'assets/images/eye-slash.svg'
import iconLock from 'assets/images/password_grey_background.svg';
import { Grid, Typography } from '@material-ui/core';
import { useAppContext } from 'context/AppContext';
import _ from 'lodash';
import Actions from 'actions/index';
import NumberFormat from 'react-number-format';
import Constant from 'utils/Constant';

const ForgotPasswordConfirm = (props) => {
  const setAppState = useAppContext()[1];
  const { callBack } = props;
  const [state, setState] = useState({
    userName: '',
    userNameError: '',
    step: '',
    verifyCode: '',
    verifyCodeError: '',
    showVerifyCode: false,
    newPassword: '',
    newPasswordError: '',
    showPassword: false,
    confirmPassword: '',
    confirmPasswordError: '',
    showConfirmPassword: false,
    inputValidation: inputValidation,
    exceededLimit: false
  });
  useEffect(() => {
    setState({
      ...state,
      step: steps.request.name
    })
  }, []);

  const showHidePassword = (e, name) => {
    switch (name) {
      case 'password':
        setState({
          ...state,
          showPassword: !state.showPassword
        });
        break;
      case 'confirmPassword':
        setState({
          ...state,
          showConfirmPassword: !state.showConfirmPassword
        });
        break;
      case 'verifyCode':
        setState({
          ...state,
          showVerifyCode: !state.showVerifyCode
        });
        break;
      default:
        break;
    }
  }
  const renderRequestStep = () => {
    return <>
      <ControlLabel>User Name</ControlLabel>
      <FormGroup bsSize='large' validationState={Boolean(state.userNameError) ? 'error' : null}>
        <InputGroup size='lg' style={{ width: '100%' }}>
          <FormControl
            name='userName'
            required
            type={'text'}
            placeholder="Enter your username"
            value={state.userName}
            onChange={handleInputChange}
            maxLength={Constant.USERNAME_MAX_LENGTH}
          // style={{ borderColor: state.userNameError ? colorMapping('error') : null }}
          />
        </InputGroup>
        <div className="error-text">
          {state.userNameError}
        </div>
      </FormGroup>
    </>
  }
  const renderValidation = () => {
    return (
      <Grid container spacing={1} style={{ marginTop: '10px' }}>
        {inputValidation.map((element, index) => {
          return <Grid item sm={12} lg={6} md={6} xs={6} key={`criteria-${index}`} style={{ padding: 0, margin: 0 }}>
            <Typography style={{ fontSize: '16px', fontWeight: 'normal' }}>
              <img alt="" src={state.inputValidation[index].passed ? iconGreenCheck : iconGrayCheck} /> {' '}
              {element.message}
            </Typography>
          </Grid>
        })}
      </Grid>
    );
  }
  const renderConfirmCodeStep = () => {
    return (
      <>
        <p>If <span className="font-bold">{state.userName}</span> matches with an account on our system, we will send a 6 digits code to the email registered with this account.</p>
        <FormGroup bsSize='large'>
          <ControlLabel>Code</ControlLabel>
          <NumberFormat
            className="form-control"
            name='verifyCode'
            allowNegative={false}
            value={state.verifyCode}
            placeholder="Enter the code"
            onChange={handleInputChange}
            maxLength={6}
          />
          <div className="error-text">
            {state.verifyCodeError}
          </div>
        </FormGroup>

        <FormGroup validationState={Boolean(state.newPasswordError) ? 'error' : null} bsSize='large'>
          <ControlLabel>New password</ControlLabel>
          <InputGroup>
            <FormControl
              name='newPassword'
              style={{ borderRight: 'None' }}
              required
              type={state.showPassword ? 'text' : 'password'}
              placeholder="Enter your new password"
              value={state.newPassword}
              onChange={handleInputChange}
              maxLength={Constant.PASSWORD_MAX_LENGTH}
            />
            <InputGroup.Addon style={{
              backgroundColor: 'white',
              borderLeft: 'None',
              borderTopRightRadius: '6px',
              borderBottomRightRadius: '6px',
            }}>
              <span>
                <img alt="" src={state.showPassword ? iconEyeSlash : iconEye} onClick={(e) => showHidePassword(e, 'password')} />
              </span>
            </InputGroup.Addon>
          </InputGroup>
          <div className="error-text">
            {state.newPasswordError}
          </div>
        </FormGroup>

        <FormGroup validationState={Boolean(state.confirmPasswordError) ? 'error' : null} bsSize='large'>
          <ControlLabel>Confirm new password</ControlLabel>
          <InputGroup>
            <FormControl
              name='confirmPassword'
              style={{ borderRight: 'None', height: '48px' }}
              required
              type={state.showConfirmPassword ? 'text' : 'password'}
              placeholder="Re-enter your new password"
              value={state.confirmPassword}
              onChange={handleInputChange}
              maxLength={Constant.PASSWORD_MAX_LENGTH}
            // onKeyPress={(e) => handleKeyPress(e, !disableLoginButton)}
            >
            </FormControl>
            <InputGroup.Addon style={{ backgroundColor: 'white', borderLeft: 'None' }}>
              <span >
                <img alt="" src={state.showConfirmPassword ? iconEyeSlash : iconEye} onClick={(e) => showHidePassword(e, 'confirmPassword')} />
              </span>
            </InputGroup.Addon>
          </InputGroup>
          <div className="error-text">
            {state.confirmPasswordError}
          </div>
        </FormGroup>
        <FormGroup style={{
          marginTop: '40px'
        }}>
          <strong className="font-bold">Your password must contain at least:</strong>
          {renderValidation()}
        </FormGroup>
      </>
    );
  }
  const hideModal = () => {
    if (callBack) {
      callBack();
    }
  }
  const submitForgotPassword = () => {
    ApiService.forgotPassword(state.userName)
      .then(data => {
        setState({
          ...state,
          step: steps.confirmCode.name,
        })
      })
      .catch(err => {
        setState({
          ...state,
          userNameError: err.message,
          exceededLimit: true
        })
      });
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newsShemaValidation = state.inputValidation;
    if (name === 'confirmPassword' || name === 'newPassword') {
      newsShemaValidation = inputValidation.map((item) => {
        item.passed = item.validate(value);
        return item;
      });
    };
    setState({
      ...state,
      [name]: value,
      inputValidation: newsShemaValidation
    });
  }
  const renderBody = () => {
    if (steps[state.step]) {
      return steps[state.step].render();
    }
    return null;
  }
  const isValidPassword = () => {
    const { newPassword, confirmPassword, verifyCode } = state;
    let valid = true;
    const errorState = {
      newPasswordError: '',
      confirmPasswordError: '',
      verifyCodeError: ''
    }
    if (!Boolean(verifyCode)) {
      errorState.verifyCodeError = 'Please enter the code';
      valid = false;
    }
    if (Boolean(newPassword)) {
      const count = _.countBy(state.inputValidation, i => i.passed);
      if (Boolean(count.false)) {
        errorState.newPasswordError = 'Your password must contain all requirements below';
        valid = false;
      }
    } else {
      errorState.newPasswordError = 'Please enter your new password';
      valid = false;
    }
    if (Boolean(confirmPassword)) {
      if (Boolean(newPassword) && confirmPassword !== newPassword) {
        errorState.confirmPasswordError = 'The new password and confirmation password do not match';
        valid = false;
      }
    } else {
      errorState.confirmPasswordError = 'Please re-enter your new password';
      valid = false;
    }
    setState({
      ...state,
      ...errorState
    });
    return valid;
  }
  const doChangePassword = () => {
    ApiService.forgotPasswordSubmit(state.userName, state.verifyCode, state.newPassword)
      .then(res => {
        hideModal();
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: 'success',
            message: 'Your password has been successfully changed. You can now login to your account.'
          }
        });
      })
      .catch(err => {
        setState({
          ...state,
          verifyCodeError: err.message
        })
      });
  }
  const changePassword = () => {
    if (isValidPassword()) {
      doChangePassword();
    }
  }
  const steps = {
    request: {
      name: 'request',
      title: 'Forgot Password',
      render: renderRequestStep,
      buttons: state.exceededLimit ? [
        {
          name: 'Close',
          classYellow: true,
          click: hideModal,
        }
      ] : [
          {
            name: 'Next',
            classYellow: true,
            click: submitForgotPassword,
          }
        ]
    },
    confirmCode: {
      name: 'confirmCode',
      title: 'Let\'s change your password',
      render: renderConfirmCodeStep,
      buttons: [
        {
          name: 'Change password',
          classYellow: true,
          click: changePassword,
        }
      ]
    }
  }
  return (
    <ConfirmModal
      show={true}
      onHide={hideModal}
      title={steps[state.step] ? steps[state.step].title : ''}
      mode="custom"
      animation
      icon={iconLock}
      buttons={steps[state.step] ? steps[state.step].buttons : []}
    >
      {renderBody()}
    </ConfirmModal>
  );
}

export default ForgotPasswordConfirm;