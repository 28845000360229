export const breakpoints = {
    xs: '420px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
};

export const fonts = {
    hel: 'HelveticaNeue',
    helLight: 'HelveticaNeue-Light',
    helBold: 'HelveticaNeue-Bold',
    helMed: 'HelveticaNeue-Medium',
};

export const colors = {
    greyBg: '#7A7A7A',
    yellow: '#FC0',
    hoverYellow: '#FFD633',
    white: '#FFF',
    darkGray: '#D1D1D1',
    lightGray: '#F2F2F2',
    textPrimary: '#231F20',
    red: '#FF001F',
    green: '#60D154',
    orange: '#F2994A',
    black: 'black',
    gray1: '#F7F7F7',
    gray2: '#CCCCCC',
    gray3: '#E6E6E6',
};
