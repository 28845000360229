import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass/styled-components';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { ReactComponent as NotFoundIcon } from 'assets/images/page-not-found.svg';

import theme, { mediaQueries } from 'utils/theme/index';

const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.lightGray};
    z-index: 10000;

    & div {
        text-align: center;
    }
`;

const NotFoundIconWrap = styled(NotFoundIcon)`
    width: 150px;
    height: 150px;

    ${mediaQueries.tablet`
		width: 300px;
        height: 300px;
    `}
`;

export const ButtonWrap = styled(Button)`
    margin-top: 34px;

    ${mediaQueries.tablet`
		margin-top: 60px;
    `}

    &:active,
    &:focus {
        outline: none;
        border-color: ${theme.colors.yellow};
        box-shadow: none;
    }
`;

export const PageNotFound = ({ ...props }) => {
    const history = useHistory();
    const { pathname } = props;
    if (pathname !== '404') {

    }
    const goToHome = () => {
        history.push('/');
    }

    return(
        <NotFoundContainer>
            <Flex
                flexDirection="column"
                alignItems="center"
                color={theme.colors.textPrimary}
                justifyContent="center">
                <Text fontFamily={theme.fonts.helBold} componentClass="h1" fontSize={['100px', '146px']}>
                    404
                </Text>
                <Text fontSize={['24px', '32px']}>Opps...page not found.</Text>
                <Text
                    fontSize={['14px', '16px']}
                    mt={['16px', '8px']}
                    mb={['8px', '24px']}
                    width={['70%', '100%']}>
                    The link you followed probably broken, or the page has been removed.
                </Text>
                <NotFoundIconWrap />
                <ButtonWrap bsStyle="yellow" onClick={goToHome}>
                    Back to home
                </ButtonWrap>
            </Flex>
        </NotFoundContainer>
    );
};

export default PageNotFound;
