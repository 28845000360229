
import React, { useState } from 'react';
import { FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import ConfirmModal from "components/common/ConfirmModal";
import ApiService from 'services/apiService';
import { useAppContext } from 'context/AppContext';
import Actions from 'actions/index';
import TextArea from 'react-textarea-autosize';
import { colorMapping   } from 'utils';
import iconCompleted from 'assets/images/completed.svg';

const MAX_LENGTH_REASON = 256;
const FIELD_NAME_REASON = 'reason';

const MerchantApprovalConfirm = (props) => {
    const { callBack, action, referenceId, merchantDisplayName, loadData, approveLogo, approveBanner, categoryCodes } = props;
    const actionText = action === 'DENY' ? 'decline' : 'approve';
    const setAppState = useAppContext()[1];
    const initState = {
      reasonError: false,
      reason: ''
    };
    const [state, setState] = useState(initState);
    const hideModal = () => {
        if (callBack) {
          callBack();
        }
    }
  
    const request = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          referenceId: referenceId,
          action: action,
          categoryCodes: categoryCodes
        },
      };
      
    const onConfirmYes = () => {
      let reason = null;
      if( action === 'DENY') {
        if(!validateReason()) {
          return false;
        }
        reason = state.reason.trim()
      } else {
        request.body.defaultLogo=!approveLogo;
        request.body.defaultBanner=!approveBanner;
      }
      request.body.reason = reason;      

      const successMessage = action === 'DENY' ? 'You have successfully declined.' : 'Your approval has been successfully applied.'
      ApiService.approveMerchant(request)
      .then(resData => {
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
              type: 'success',
              message: successMessage
          }
        });
        loadData();
      }).catch(err => {
        const errorMessage = err.response?.data?.errorMessage ? err.message : 'We cannot ' + actionText + ' Merchant, please contact Admin for  more details';
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
              type: 'error',
              message: errorMessage
          }
        });
        console.debug(err);
        // handle log
      }).finally(fn => {
        // after finish
        hideModal();
      })
    };

  const validateReason = () => {
      const isValidateReason = !!state.reason && !!state.reason.trim()
      if (isValidateReason) {
        if (state.reasonError) {
          setState({
            ...state,
            reasonError: false
          });
        }
        return true;
      }
      setState({
        ...state,
        reasonError: true
      });
      return false;
    }

    const inputChange = (e) => {
      const { name, value } = e.target;
      var ranges = [
        '\ud83c[\udf00-\udfff]', 
        '\ud83d[\udc00-\ude4f]',
        '\ud83d[\ude80-\udeff]'
      ];
      const correctValue = value.replace(new RegExp(ranges.join('|'), 'g'), '');
      setState({
        ...state,
        [name]: correctValue,
      });
    };

    return (
        
        <ConfirmModal
            show={true}
            onHide={hideModal}
            title={'Are you sure you want to ' + actionText + ' ' + merchantDisplayName +'?'}
            mode="confirm"
            animation
            onConfirmNo={hideModal}
            onConfirmYes={onConfirmYes}
        >
            { action !== 'DENY' 
              ? 
                <div>
                  <p>Once approved, <span className="font-bold">{merchantDisplayName}</span> will be visible on the app for customers to view</p>
                  <Row style={{marginTop: '10px', height: '34px'}}>
                      <Col xs={6} md={6}>
                        <Row>
                          <Col xs={2} md={2}> 
                            { approveLogo ? <img alt="icon success" src={iconCompleted} style={{marginTop: '12px', height: '17px'}} /> : <FormControl name='approveLogo' type={'checkbox'} disabled={true}/> }
                          </Col>
                          <Col xs={4} md={4}> <ControlLabel style={{marginLeft: '-24px', marginTop: '11px'}}>Logo</ControlLabel></Col>
                        </Row>
                      </Col>
                      <Col xs={6} md={6}>
                        <Row>
                          <Col xs={2} md={2}>
                            {approveBanner ? <img alt="icon success" src={iconCompleted} style={{marginTop: '12px', height: '17px'}} /> : <FormControl name='approveBanner' type={'checkbox'} disabled={true}/>}
                          </Col>
                          <Col xs={4} md={4}>
                            <ControlLabel style={{marginLeft: '-24px', marginTop: '11px'}}>Banner</ControlLabel>
                          </Col>
                        </Row>
                      </Col>

                  </Row>
                </div>
                
              : '' }
            { action === 'DENY' 
              ? 
              <form>
                <p>Please input a decline reason</p>
                <div style={{ marginTop: '10px' }}>
                  <div className='input-group-amount'>
                    <TextArea
                      style={{
                        padding: '10px',
                        fontSize: '18px',
                        borderColor: state.reasonError
                          ? colorMapping('error')
                          : null,
                        resize: 'none',
                      }}
                      name={FIELD_NAME_REASON}
                      value={state.reason}
                      placeholder='Enter the reason to decline merchant'
                      onChange={inputChange}
                      maxLength={MAX_LENGTH_REASON}
                      minRows={4}
                      maxRows={4}
                      rows={4}
                      className='form-control'
                    />
                  </div>
                  <div style={{ display:  state.reasonError ? '' : 'none'}} className={state.reasonError ? 'error-text' : ''}>
                    You need to enter a decline reason to continue
                  </div>
                </div>
              </form>
              : ''}
            
        </ConfirmModal>
    );

}

export default MerchantApprovalConfirm;