import React from 'react';
import { useAppContext } from 'context/AppContext';
import iconError from 'assets/images/error.svg';
import Actions from 'actions';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import Constant from 'utils/Constant';

const TechnicalIssue = (props) => {
  const [appState, setAppState] = useAppContext();

  const title = "We're having some technical issues";

  const hideModal = () => {
    setAppState({
      type: Actions.technicalIssue.type,
      hidden: true,
    });
  };
  const className = classNames('confirm-modal', {
    'animation-show-effect': false,
  });
  return (
    <Modal
      className={className}
      show={!appState.technicalIssue.hidden}
      animation={true}
      onHide={hideModal}
      // backdropClassName={backdropClassName ? 'modal-backdrop--disable' : ''}
    >
      <Modal.Header closeButton={true}>
        <img alt='' src={iconError} />
        <Modal.Title componentClass='span'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        <div className='modal-body__content'>
          {' '}
          <p>
            Sorry about that. Come back and try again later or contact our
            Customer Contact Centre on{' '}
            <span className='font-bold'>{Constant.PHONE_NUMBER}</span>.
          </p>
        </div>
        <div className='modal-buttons'>
          <Button type='button' onClick={hideModal} bsStyle='yellow'>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default TechnicalIssue;
