const VoucherStatus = {
  REDEEM_CONFIRMED: "Redeem confirmed",
  REDEEMED: "Redeemed",
  EXPIRED: "Expired",
  CANCELLED: 'Cancelled',
  REFUND_CONFIRMED: "Fully refunded",
  PARTIAL_REFUND_CONFIRMED: "Partially refunded",
  REFUND_REVERSED: "Failed to refund",
  REDEEM_REVERSED: "Failed to redeem"
}
export default VoucherStatus;