import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useAppContext } from 'context/AppContext';
import LoadingIcon from 'assets/images/loading.svg';

const WaitModal = () => {
    const [appState, setAppState] = useAppContext();
    return (
        <Dialog disableEnforceFocus open={!appState.waitModal.hidden} PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
        }}>
            <img src={LoadingIcon} alt="Loading icon" style={{ width: '72px' }} />
        </Dialog>
    )
}
export default WaitModal;
