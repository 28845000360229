import { API } from '@aws-amplify/api';
import { Auth as AWSAuth } from '@aws-amplify/auth';


export default class ApiService {
    static getCurrentSession = () => {
        return AWSAuth.currentSession();
    }
    static currentAuthenticatedUser = () => {
        return AWSAuth.currentAuthenticatedUser();
    }

    static logout = () => {
        return AWSAuth.signOut();
    };

    static login = (username, password) => {
        try {
            return AWSAuth.signIn(username, password);
        } catch (error) {
            throw error;
        }
    };

    static federatedLogin = (providerName, from) => {
        try {
            return AWSAuth.federatedSignIn({provider: providerName, customState: from});

        } catch (error) {
            throw error;
        }
    };

    static changePassword = (user, oldPasword, newPassword) => {
        return AWSAuth.changePassword(user, oldPasword, newPassword);
    }
    static completeNewPassword = (user, newPassword) => {
        return AWSAuth.completeNewPassword(user, newPassword);
    }
    static submitReversal = (initData) => {
        return API.post('redeem', 'wp/redemption/reversal', initData);
    }
    static submitRedeem = (initData) => {
        return API.post('redeem', 'wp/redeem', initData);
    }
    static getRedeemHistory = (initData, page, limit) => {
        return API.get('redeem', `wp/vouchers?page=${page}&limit=${limit}`, initData);
    }
    static getStores = (initData) => {
        return API.get('redeem', 'wp/merchant', initData);
    }
    static acceptTermAndConditions = (initData) => {
        return API.post('redeem', 'merchants/terms', initData);
    }
    static forgotPassword = (username) => {
        return AWSAuth.forgotPassword(username);
    }
    static forgotPasswordSubmit = (username, code, newPassword) => {
        return AWSAuth.forgotPasswordSubmit(username, code, newPassword);
    }
    static updateUserAttributes = (user, attributes) => {
        return AWSAuth.updateUserAttributes(user, attributes);
    }
    static requestRefundValidation = (initData) => {
        return API.post('refund', 'wp/refund/validation', initData);
    }
    static requestRefund = (initData) => {
        return API.post('refund', 'wp/refund', initData);
    }
    static getMerchantInfo = (initData, referenceId) => {
        return API.get('merchant_approval', `aggregator-mgt/merchant/${referenceId}/approve-info`, initData);
    }
    static getMerchantAttachmentUrl = (initData, referenceId) => {
        return API.get('merchant_approval', `aggregator-mgt/merchant/${referenceId}/attachment`, initData);
    }
    static approveMerchant = (initData) => {
        return API.post('merchant_approval', 'aggregator-mgt/approval', initData);
    }
    static getCategoryList = (initData) => {
        return API.get('merchant_approval', `aggregator-mgt/categories`, initData);
    }
    static getInvoiceCustomSetting = (initData, shortCode, month, year) => {
        return API.get('merchant_portal_setting', `aggregator-mgt/get-merchant-invoice-custom-field?shortCode=${shortCode}&month=${month}&year=${year}`, initData);
    }
    static submitSaveInvoiceCustomField = (initData) => {
        return API.post('merchant_portal_setting', `aggregator-mgt/submit-invoice-custom-field`, initData);
    }
    static getVoucherList = (initData, page, size, keyword) => {
        return API.get('voucher_management', `admin-portal/vouchers?page=${page}&size=${size}&keyword=${keyword}`, initData);
    }
    static redeemReversalVoucher = (initData) => {
        return API.post('voucher_management', `admin-portal/redemption/reversal`, initData);
    }
    static refundVoucher = (initData) => {
        return API.post('voucher_management', `admin-portal/voucher/refund`, initData);
    }
    static getMerchantList = (initData, page, size, aggregator, status, keyword) => {
        return API.get('merchant_management', `aggregator-mgt/get-merchant-aggregator-managements?page=${page}&size=${size}`
        + (aggregator != null ? `&aggregator=${aggregator}` : '') + (status != null ? `&status=${status}` : '')
        + (keyword != '' ? `&keyword=${keyword}` : ''), initData);
    }
    static getAggregatorList = (initData,) => {
        return API.get('merchant_management', `aggregator-mgt/get-aggregators`, initData);
    }
    static getStatusList = (initData) => {
        return API.get('merchant_management', `aggregator-mgt/get-status-types`, initData);
    }
    static getMerchantDetail = (initData, merchantId) => {
        return API.get('merchant_management', `aggregator-mgt/merchants/${merchantId}`, initData);
    }
    static updateMerchant = (initData, aggregatorId) => {
        return API.post('merchant_management', `aggregator-mgt/merchants/${aggregatorId}`, initData);
    }
    static getStoresByMerchantId = (initData, merchantId, page, size, keyword) => {
        return API.get('merchant_management', `aggregator-mgt/merchants/${merchantId}/stores?page=${page}&size=${size}` + (keyword != '' ? `&keyword=${keyword}` : ''), initData);
    }
    static getStoreDetail = (initData, storReferenceId) => {
        return API.get('merchant_management', `aggregator-mgt/merchants/stores/${storReferenceId}`, initData);
    }

    static postPresignUrl = (initData, aggregatorId, referenceId) => {
        return API.post('merchant_management', `aggregator-mgt/merchants/attachment?aggregatorId=${aggregatorId}&referenceId=${referenceId}`, initData);
    }
    static createMerchant = (initData) => {
        return API.post('merchant_management', `/aggregator-mgt/merchants`, initData);
    }
}
