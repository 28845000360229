import {Amplify} from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/stable';

import App from './App';
import { Configuration } from './config';

require('es6-shim');
// require('default-passive-events');

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: Configuration.cognito.REGION,
        userPoolId: Configuration.cognito.USER_POOL_ID,
        userPoolWebClientId: Configuration.cognito.APP_CLIENT_ID
    },
    oauth: {
        
        domain: Configuration.oauth.FEDERATED_LOGIN_DOMAIN,
        scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
        redirectSignIn: Configuration.oauth.REDIRECT_SIGN_IN,
        redirectSignOut: Configuration.oauth.REDIRECT_SIGN_OUT,
        responseType: 'code' 
      },

    API: {
        endpoints: [
            {
                name: 'redeem',
                endpoint: Configuration.apiGateway.URL,
                region: Configuration.apiGateway.REGION,
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
                }
            },
            {
                name: 'refund',
                endpoint: Configuration.apiGateway.URL,
                region: Configuration.apiGateway.REGION,
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
                }
            },
            {
                name: 'merchant_approval',
                endpoint: Configuration.apiGateway.URL,
                region: Configuration.apiGateway.REGION,
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
                }
            },
            {
                name: 'merchant_portal_setting',
                endpoint: Configuration.apiGateway.URL,
                region: Configuration.apiGateway.REGION,
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
                }
            },
            {
                name: 'voucher_management',
                endpoint: Configuration.apiGateway.URL,
                region: Configuration.apiGateway.REGION,
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
                }
            },
            {
                name: 'merchant_management',
                endpoint: Configuration.apiGateway.URL,
                region: Configuration.apiGateway.REGION,
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
                }
            }
            
        ]
    }
});

export default ReactDOM.render(
    <App />,
    document.getElementById('root') || document.createElement('div'),
);
