import Constant from "./Constant";
import moment from "moment";

/**
 * This is global utilities that don't relate to any functionality
 */
export function colorMapping(color) {
  switch (color) {
    case "yellow":
      return "#ffcc00";
    case "error":
      return "#FF001F";
    default:
      return "white";
  }
}

export const displayAmount = (value, decimalScale = 2) => {
  if (value != null) {
    // remove prefix and space
    let tempValue = String(value).replaceAll(/[R ]/g, "");
    // find dot '.' position
    const dotPos = tempValue.indexOf(".");
    if (dotPos !== -1) {
      tempValue = tempValue.slice(0, dotPos + 3);
    }
    return (
      "R" +
      parseFloat(tempValue)
        .toFixed(decimalScale)
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    );
  }
  return null;
};

export const displayVoucherCode = (value) => {
  if (value) {
    const temp = value + "";
    return temp.replaceAll(/[0-9]{4}/g, (group) => group + " ");
  }
  return null;
};

export const formatDate = (value, format) => {
  try {
    return moment(new Date(value)).format(format);
  } catch (error) {
    return "";
  }
};

export const convertAmountToNumber = (value) => {
  if (value && typeof value === "string") {
    return value.replaceAll(/ |R/g, "");
  }
  return 0;
};

export const convertVoucherToNumber = (value) => {
  if (value && typeof value === "string") {
    return value.replaceAll(" ", "");
  }
  return 0;
};

export const validateVoucherNumber = (value) => {
  return /[0-9]{13}/.test(value);
};

export const validateAmountNumber = (value) => {
  try {
    return parseFloat(value);
  } catch (error) {
    console.debug(error);
  }
  return false;
};
