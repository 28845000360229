import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../../assets/images/loading.svg';

export default class Spinner extends React.Component {
    static propTypes = {
        text: PropTypes.string,
        show: PropTypes.bool,
        cssClass: PropTypes.string,
    };

    static defaultProps = {
        text: '',
        show: false,
        cssClass: '',
    };

    render() {
        const { text, show, cssClass } = this.props;
        const className = `align-center ${cssClass}`;
        return (
            <div className={className}>
                {show && <React.Fragment><div className="row">{text}</div> <br /></React.Fragment>} 
                <div className="spinner">
                    <img src={LoadingIcon} alt="Loading icon" style={{ width: '33px' }} />
                </div>
            </div>
        );
    }
}
