import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import ApiService from 'services/apiService';
import { useUserContext, createUserObj } from 'context/UserContext';

import Spinner from 'components/common/Spinner';

const Logout = ({ ...rest }) => {
    const { setUserObj } = useUserContext();
    const history = useHistory();
    const handleLogout = () => {
        ApiService.logout().finally(() => {
            setTimeout(() => {
                setUserObj(createUserObj());
                history.push('/login');
            }, 1000)
        });

    };

    useEffect(() => {
        handleLogout();
    });

    return (
        <div className="container logout-container">
            <h1>&nbsp;</h1>
            <div className="box">
                <div className="row text-center">
                    <div className="col-xs-12">
                        <p className="title font-bold">Logging you out...</p>
                        <Spinner />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logout;
