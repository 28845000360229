import React from 'react';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { Icon, IconButton, Snackbar } from '@material-ui/core';
import classNames from 'classnames';

import Actions from 'actions/index';
import iconSuccess from '../../assets/images/completed.svg';
import iconError from '../../assets/images/error.svg';
import { useAppContext } from 'context/AppContext';


const breakpointValues = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};
const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });

const useStyles = makeStyles({
    snackBar: {
        top: '52px', // height of top fixed bar
        display: 'block',
        left: 0,
        right: 0,
        transform: 'none',

        [theme.breakpoints.up('md')]: {
            top: '0px',
            zIndex: 1100,
        },
    },
    snackBarContentSuccess: {
        fontFamily: 'HelveticaNeue-Light,sans-serif',
        fontSize: 'inherit',
        backgroundColor: '#899197',
        borderRadius: '0',
        width: '100%',
        height: '80px',
        margin: 'auto',
        boxShadow: 'none',
        flexWrap: 'nowrap',
        paddingLeft: 12,
        paddingRight: 12,

        [theme.breakpoints.up('md')]: {
            minWidth: '0',
            maxWidth: '100%',
            borderRadius: 0,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
    snackBarContentError: {
        fontFamily: 'HelveticaNeue-Light,sans-serif',
        fontSize: 'inherit',
        backgroundColor: '#899197',
        borderRadius: '0',
        width: '100%',
        margin: 'auto',
        boxShadow: 'none',
        flexWrap: 'nowrap',
        paddingLeft: 12,
        paddingRight: 12,

        [theme.breakpoints.up('md')]: {
            minWidth: '0',
            maxWidth: '100%',
            borderRadius: 0,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
        },
    },

    button: {
        color: '#fff',
    },
    snackBarFluid: {
        top: '0', // height of top fixed bar
    },
    snackBarContentFluid: {
        maxWidth: '100%',
    },
});


const Toast = () => {
    const classes = useStyles();
    const [appState, setAppState] = useAppContext();
    const defaultProps = Actions.toast.props;
    const { hidden, props: {
        type, message
    } } = appState.toast;
    const handleClose = () => {
        setAppState({
            type: Actions.toast.type,
            hidden: true,
            props: defaultProps
        });
    };

    const showFluidSnackBar = Boolean(type === 'error');
    const showErrorIcon = Boolean(type === 'error');
    return (
        <Snackbar
            open={!hidden}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => handleClose()}
            className={classNames({ [`${classes.snackBarFluid}`]: showFluidSnackBar }, classes.snackBar)}
            ContentProps={{
                className: classNames({ [`${classes.snackBarContentFluid}`]: showFluidSnackBar }, classes.snackBarContentSuccess),
            }}

            message={
                <span id="snackbar-fab-message-id">
                    <div className="msg-icon msg-text"><img className="snackbar-icon" src={showErrorIcon ? iconError : iconSuccess} alt="" />{' '} {message}</div>
                </span>
            }
            action={
                <IconButton className={classes.button} aria-label="Close" onClick={() => handleClose()}>
                    <Icon fontSize='large' >close</Icon>
                </IconButton>
            }
        />
    );
}
export default Toast;
