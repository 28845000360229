const Actions = {
  sessionConfirmModal: {
    type: 'sessionConfirmModal',
    hidden: true
  },
  logoutConfirmModal: {
    type: 'logoutConfirmModal',
    hidden: true
  },
  toast: {
    type: 'toast',
    hidden: true,
    props: {
      type: 'success',
      message: 'This is message toast'
    }
  },
  waitModal: {
    type: 'waitModal',
    hidden: true
  },
  technicalIssue: {
    type: 'technicalIssue',
    hidden: true,
  }
}

export default Actions;