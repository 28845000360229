import React, { useState, useEffect } from 'react';
import { Hub } from '@aws-amplify/core';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import './styles/index.css';
import ApiService from 'services/apiService';
import { createUserObj, UserContext } from './context/UserContext';
import { StateProvider, initialState, reducer } from './context/AppContext';
import routes from './routes';
import PrivateRoute from 'components/routes/PrivateRoute';
import TechnicalIssue  from 'components/common/TechnicalIssue';
import Constant from 'utils/Constant';


const App = (props) => {
    const [userObj, setUserObj] = useState(createUserObj());
    const [fromPath, setFromPath] = useState('');
    const [isFederatedLogin, setIsFederatedLogin] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    const getUser = () => {
        return ApiService.currentAuthenticatedUser().then((userAuth) => {
            if (userAuth && userAuth.username) {
                let merchantId = null;
                let storeId = null;
                let termsAndConditions = null;
                let userTitle = 'Unknown';
                let featureRestriction = [];
                let userRole = [];
                if (userAuth.attributes) {
                    if (userAuth.attributes['custom:merchant_id']) {
                        merchantId = userAuth.attributes['custom:merchant_id'];
                    }
                    if (userAuth.attributes['custom:store_id']) {
                        storeId = userAuth.attributes['custom:store_id'];
                    }
                    if (userAuth.attributes['custom:terms_n_conditions']) {
                        termsAndConditions = userAuth.attributes['custom:terms_n_conditions'];
                    }
                    if (userAuth.attributes['custom:full_name']) {
                        userTitle = userAuth.attributes['custom:full_name'];
                    }
                    if(userAuth.attributes['custom:restriction']){
                        featureRestriction = userAuth.attributes['custom:restriction'].split(',');
                    }
                    if(userAuth.attributes['custom:role']){
                        userRole = userAuth.attributes['custom:role'].split(',');
                        if(userRole.indexOf(Constant.ADMIN_PORTAL_ROLE ) >= 0 && !termsAndConditions) {
                            termsAndConditions = !!termsAndConditions ? termsAndConditions : '1';
                            featureRestriction = featureRestriction.length !== 0 ? featureRestriction : [Constant.FEATURE_RESTRICTION.REDEMPTION, Constant.FEATURE_RESTRICTION.REFUND];
                        }
                    }
                }
                let loggedInTime = new Date().getTime();
                if (userAuth.signInUserSession && userAuth.signInUserSession.accessToken && userAuth.signInUserSession.accessToken.payload && userAuth.signInUserSession.accessToken.payload.auth_time) {
                    loggedInTime = userAuth.signInUserSession.accessToken.payload.auth_time * 1000;
                }
                sessionStorage.clear();
                sessionStorage.setItem('username', userAuth.username);
                setUserObj(createUserObj(userAuth.username, userTitle, true, false, userAuth, merchantId, storeId, loggedInTime, null, termsAndConditions, featureRestriction, userRole));
            }
            return userAuth;
        }).catch((err) => {
            console.debug(err);
        }).finally(() => {
            setIsAuthenticating(false);
        });
    }

    const federatedLogin = () => {
        Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser();
                    break;
                case 'signOut':
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    break;
                case "customOAuthState":
                    setIsFederatedLogin(true);
                    setFromPath(payload.data);
            }
          });
      
          getUser();
    }

    useEffect(() => {
        federatedLogin();
    }, []);

    return (
        !isAuthenticating &&
        <StateProvider initialState={initialState} reducer = {reducer}>
            <UserContext.Provider value={{ userObj, setUserObj }}>
                <BrowserRouter>
                    <Switch>
                        {routes.map((route, index) => (
                            <PrivateRoute
                                props={{ ...props}}
                                exact
                                key={index}
                                path={route.path}
                                name={route.name}
                                from={fromPath}
                                federatedLogin={isFederatedLogin}
                                >
                                {route.component}
                            </PrivateRoute>
                        ))}
                    </Switch>
                </BrowserRouter>
                <TechnicalIssue/>
            </UserContext.Provider>
        </StateProvider>
    );

}
export default App;
