import React from "react";

const BaseBox = ({ title, children, ...rest }) => (
  <div {...rest}>
    <div className="box-header box-header-accounts font-bold">
      {typeof title === "string" ? <h3>{title}</h3> : title}
    </div>
    <div className="box">
      <div className="box-content table box-content-accounts">{children}</div>
    </div>
  </div>
);

export default BaseBox;