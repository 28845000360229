import React from 'react';
import iconError from 'assets/images/error.svg';
import { useRefundRequestContext } from './RefundRequest';
import { Modal, Button } from 'react-bootstrap';
import Styled from 'styled-components';
import Constant from 'utils/Constant';

const StyledSpan = Styled.span`
white-space: nowrap;
`;

const VoucherRefundingError = (props) => {
  const [refundRequestState, setRefundRequestState] = useRefundRequestContext();

  const title = 'We’re still finalising the previous reversal for this voucher';

  const hideModal = () => {
    setRefundRequestState({
      ...refundRequestState,
      isVoucherRefunding: false,
    });
  };

  return (
    <Modal
      show={refundRequestState.isVoucherRefunding}
      animation={true}
      onHide={hideModal}
    >
      <Modal.Header closeButton={true}>
        <img alt='' src={iconError} />
        <Modal.Title componentClass='span'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        <div className='modal-body__content'>
          <p>
            Wait until you receive a confirmation SMS for the previous reversal
            before trying again. For help with this issue, contact customer
            service on{' '}
            <StyledSpan className='font-bold'>{Constant.PHONE_NUMBER}.</StyledSpan>
          </p>
        </div>
        <div className='modal-buttons'>
          <Button type='button' onClick={hideModal} bsStyle='yellow'>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default VoucherRefundingError;
