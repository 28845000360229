import { useContext, createContext } from "react";

export const UserContext = createContext(null);

export function useUserContext() {
  return useContext(UserContext);
}

export function createUserObj(username = null, userTitle='Unknown', isAuthenticated = false, needUpdatePassword = false, congitoUser = null, merchantId = null, storeId = null, loggedInTime = null, timerId = null, termsAndConditions = '0', featureRestriction = [], userRole = []) {
  return {
    'username': username,
    'userTitle': userTitle,
    'isAuthenticated': isAuthenticated,
    'needUpdatePassword': needUpdatePassword,
    'merchantId': merchantId,
    'storeId': storeId,
    'congitoUser': congitoUser,
    'loggedInTime': loggedInTime,
    'timerId': timerId,
    'termsAndConditions': termsAndConditions,
    'featureRestriction': featureRestriction,
    'userRole': userRole
  };
}