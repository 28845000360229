import React, { useContext, useReducer, createContext } from 'react';
import Banner from 'components/layout/Banner';
import PurchaseSubmission from './PurchaseSubmission';
import PurchaseVoucherConfirm from './PurchaseVoucherConfirm';
import RefundRequest from 'components/returnGoods/RefundRequest';
import PurchaseHistory from './PurchaseHistory';
import { useUserContext } from 'context/UserContext';
import { Row, Col, Tab, NavItem, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import Constant from 'utils/Constant';

const reducer = (state, action) => {
  return {
    ...state,
    ...action,
  };
};
export const initialState = {
  isHiddenModal: true,
  voucherCode: null,
  amount: null,
  title: '',
  errorMessage: '',
  referenceId: '',
  step: 'confirm',
  forceReload: false,
  errButtonTitle: 'OK',
  reloadAction: '',
};

const DashboardContext = createContext(initialState);

export function useDashboardContext() {
  return useContext(DashboardContext);
}

const StyledNavItem = styled(NavItem)`
  a {
    background-color: #e0e0e0;
    color: black;
    margin-right: 0px;
  }
`;
const StyledSpan = styled.span`
  font-size: 16px;
  color: #000000;
`;

const Dashboard = (props) => {
  const { userObj } = useUserContext();

  let allowRedemption = true;
  let allowRefund = true;
  
  if(userObj && userObj.featureRestriction && userObj.featureRestriction.length > 0){
    allowRedemption = userObj.featureRestriction.indexOf(Constant.FEATURE_RESTRICTION.REDEMPTION) < 0;
    allowRefund = userObj.featureRestriction.indexOf(Constant.FEATURE_RESTRICTION.REFUND) < 0;
  }

  return (
    <DashboardContext.Provider value={useReducer(reducer, initialState)}>
      <div className='container'>
        <Banner key='Banner' gradientBackground={true} />
        {(userObj.storeId && (allowRedemption || allowRefund)) && (
          <Tab.Container id="dashboardtab" defaultActiveKey={allowRedemption ? 'purchaseSubmission' : 'refundRequest'}>
            <Row className='clearfix'>
              <Col sm={12}>
                <Nav bsStyle='tabs'>
                  {allowRedemption && (
                    <StyledNavItem style={{ marginRight: '0px' }} eventKey='purchaseSubmission'>
                      <StyledSpan className='text-bold'>New purchase</StyledSpan>
                    </StyledNavItem>
                  )}
                  {allowRefund && (
                    <StyledNavItem eventKey='refundRequest'>
                      <StyledSpan className='text-bold'>New return</StyledSpan>
                    </StyledNavItem>
                   )}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                {allowRedemption && (
                    <Tab.Pane eventKey='purchaseSubmission'>
                      <PurchaseSubmission key='PurchaseSubmission' />
                    </Tab.Pane>
                )}
                {allowRefund && (
                    <Tab.Pane eventKey='refundRequest'>
                      <RefundRequest key='RefundRequest' />
                    </Tab.Pane>
                )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        )}
        <PurchaseVoucherConfirm key='PurchaseVoucherConfirm' />
        <PurchaseHistory key='Dashboard-PurchaseHistory' />
      </div>
    </DashboardContext.Provider>
  );
};
export default Dashboard;
