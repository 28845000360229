import React from 'react';
import { Modal, Button, utils } from 'react-bootstrap';
import PropTypes from 'prop-types';
import uid from 'uuid';
import classNames from 'classnames';

import success from '../../assets/images/success.png';
import info from '../../assets/images/information.svg';
import ButtonLoader from './ButtonLoader';

utils.bootstrapUtils.addStyle(Button, 'yellow');

export class ConfirmModal extends React.Component {
    static propsTypes = {
        show: PropTypes.bool,
        onHide: PropTypes.func,
        title: PropTypes.string,
        mode: PropTypes.oneOf(['confirm', 'success', 'custom']),
        onConfirmYes: PropTypes.func,
        onConfirmNo: PropTypes.func,
        onDone: PropTypes.func,
        animation: PropTypes.bool,
        backdropClassName: PropTypes.bool,
        buttons: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            classYellow: PropTypes.bool,
            addonClass: PropTypes.string,
            inprogress: PropTypes.bool,
            click: PropTypes.func,
        })),
    };

    static defaultProps = {
        show: false,
        onHide: () => { },
        title: '',
        mode: 'confirm',
        animation: true,
        backdropClassName: false,
        onConfirmYes: () => { }, // is require if mode is confirm
        onConfirmNo: () => { }, // is require if mode is confirm
        onDone: () => { }, // is require if mode is success
        buttons: [
            {
                name: 'Done',
                classYellow: false,
                addonClass: '',
                inprogress: false,
                click: () => { },
            },
        ],
    };

    renderIcon() {
        let iconModal;
        const { mode, icon } = this.props;
        if (mode === 'success') {
            iconModal = (
                <img alt="icon success" src={success} />
            );
        } else if (mode === 'custom') {
            iconModal = (
                icon ?
                    <img alt="" src={icon} /> : null
            );
        } else {
            iconModal = (
                <img alt="info" src={info} />
            );
        }
        return iconModal;
    }

    renderButton() {
        const {
            mode,
            onConfirmYes,
            onConfirmNo,
            onDone,
            buttons
        } = this.props;
        let buttonModal;
        if (mode === 'success') {
            buttonModal = (
                <div className="modal-buttons">
                    <Button
                        type="button"
                        onClick={onDone}
                        bsStyle="yellow"
                    >
                        Done
                  </Button>
                </div>
            );
        } else if (mode === 'custom') {
            buttonModal = (
                <div className={classNames('modal-buttons', { 'modal-buttons--custom': buttons.length > 1 })}>
                    {buttons.map(button => (
                        <ButtonLoader
                            key={uid()}
                            type="button"
                            onClick={button.click}
                            className={`${!button.classYellow && 'btn-default'} ${button.addonClass}`}
                            bsStyle={button.classYellow ? 'yellow' : 'default'}
                            loading={button.inprogress}
                            disabled={button.inprogress}
                        >
                            {button.name}
                        </ButtonLoader>

                    ))}
                </div>
            );
        } else {
            buttonModal = (
                <div className="modal-buttons">
                    <Button
                        type="button"
                        onClick={onConfirmYes}
                        bsStyle="yellow"
                    >
                        Yes
                  </Button>
                    <Button
                        type="button"
                        onClick={onConfirmNo}
                    >
                        No
                  </Button>
                </div>
            );
        }
        return buttonModal;
    }

    render() {
        const {
            animation,
            backdropClassName,
            className: cls,
            backdrop,
            show,
            onHide,
            onExited,
            close,
            title,
            children,
            showClose
        } = this.props;
        const className = classNames(cls, 'confirm-modal', { 'animation-show-effect': backdropClassName });
        return (
            <Modal
                className={className}
                show={show}
                onHide={onHide}
                onExited={onExited}
                animation={animation}
                backdropClassName={backdropClassName ? 'modal-backdrop--disable' : ''}
                backdrop={backdrop}
            >
                <Modal.Header onHide={close} closeButton={showClose === undefined ? true : showClose }>
                    {this.renderIcon()}
                    <Modal.Title componentClass="span">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: '40px'}}>
                    <div className="modal-body__content">{children}</div>
                    {this.renderButton()}
                </Modal.Body>
            </Modal>
        );
    }
}
export default ConfirmModal;
