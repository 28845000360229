import React, { useEffect, useState, Fragment, useCallback } from "react";
import { useParams } from "react-router";
import { useTable } from "react-table";
import { Divider, Grid, TableFooter, TablePagination } from "@material-ui/core";
import { Flex } from "rebass/styled-components";
import Button from "react-bootstrap/lib/Button";
import Tabs from "react-bootstrap/lib/Tabs";
import Tab from "react-bootstrap/lib/Tab";
import { formatDate } from "utils";
import PropTypes from "prop-types";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import Spinner from "components/common/Spinner";
import { FormControl } from "react-bootstrap";
import ApiService from "services/apiService";
import MerchantApprovalConfirm from "./MerchantApprovalConfirm";
import ModalEditStore from "components/admin/merchant-approval/modal-edit-store/ModalEditStore";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useAppContext } from "context/AppContext";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Styled from "styled-components";
import ModalEditMerchant from "components/admin/merchant-approval/modal-edit-merchant/ModalEditMerchant";
import Actions from "actions";
import ModalAddStore from "components/admin/merchant-approval/modal-add-store";
import ImageUploading from "react-images-uploading";
import axios from "axios";

const Header = ({ headers }) => {
  return (
    <Flex fontSize={["14px", "16px"]} py={3} className="font-bold">
      {headers.map((header, index) =>
        header.isVisible ? (
          <Box key={`purchase-header-${index}`} width={header.width}>
            {header.Header}
          </Box>
        ) : null
      )}
    </Flex>
  );
};

const BoxContent = ({ children, ...rest }) => (
  <Box {...rest}>
    <Box px={[2, 3, 4]}>{children}</Box>
  </Box>
);

const DataTable = ({ data, hideOldValueColumn }) => {
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const columns = [
      {
        Header: "Field Name",
        accessor: "fieldName",
        width: "20%",
      },
      {
        Header: "Waiting Approve Data",
        accessor: "newValue",
        width: "35%",
      },
      {
        Header: "Onboarded Data",
        accessor: "oldValue",
        width: hideOldValueColumn ? "75%" : "35%",
      },
    ];
    setColumns(columns);
  }, [hideOldValueColumn]);

  const hiddenColumns = hideOldValueColumn ? ["oldValue"] : [];
  // Init a instance of table by usetable
  const instanceTable = useTable({
    columns,
    data,
    initialState: {
      // default sort
      sortBy: [
        {
          id: "fieldName",
          desc: false,
        },
      ],
      hiddenColumns: hiddenColumns,
    },
  });
  const { headers, rows, prepareRow } = instanceTable;

  return (
    <Fragment>
      <BoxContent>
        <Header headers={headers} />
      </BoxContent>
      <BoxContent>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <div key={`row-${row?.values.field}-${rowIndex}`}>
              <Divider />
              <Flex fontSize={["14px", "16px"]} py={3}>
                {row?.cells.map((cell, cellIndex) => {
                  return (
                    <Box
                      width={cell.column.width}
                      key={`Cell-${rowIndex}-${cellIndex}`}
                      style={{ wordBreak: "break-word", marginRight: "3px" }}
                    >
                      {cell.render("Cell")}
                    </Box>
                  );
                })}
              </Flex>
            </div>
          );
        })}
      </BoxContent>
      <Box mx={[2, 4]} className="hidden-xs">
        <Divider />
      </Box>
    </Fragment>
  );
};
function createData(items) {
  return items;
}
const rows = [
  createData({
    externalMerchantId: {
      label: "Address",
      onboarded: "AAA-BBB-CCC-DDD-EEE",
      waitingApprove: "AAA-BBB-CCC-DDD-EEE",
    },
    name: {
      label: "Name",
      onboarded: "NAME ONBOARED",
      waitingApprove: "NAME WATING",
    },
  }),
];

const LogoBanner = ({ attachmentInfo, status, onApproveLogoBannerChange }) => {
  let { referenceId } = useParams();
  let { merchantId } = useParams();
  const displayLogo = attachmentInfo.logoUrl;
  const displayBanner = attachmentInfo.bannerUrl;
  const displayOnboard =
    attachmentInfo.onboardLogoUrl || attachmentInfo.onboardBannerUrl;
  const isInActionStatus = status === "AWAITING_APPROVAL";

  return (
    <Tab
      eventKey="contact"
      title="Logo/Banner"
      style={{ marginLeft: "15px", minHeight: "400px" }}
    >
      <div style={{ padding: 24,display:"flex",flexDirection: "column",gap:"38px"}}>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col sm={2}>
            {isInActionStatus && displayLogo && (
              <FormControl
                name="approveLogo"
                type={"checkbox"}
                defaultChecked={false}
                onChange={onApproveLogoBannerChange}
              />
            )}
          </Col>
          <Col sm={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap:"12px"
              }}
            >
              {attachmentInfo.logoUrl && <><img
                width={192}
                height={192}
                src={attachmentInfo.logoUrl}
                alt=""
              ></img>
              Waiting Approve Logo</>}
            </div>
          </Col>
          <Col sm={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap:"12px"
              }}
            >
               {attachmentInfo.onboardLogoUrl && <><img
                width={192}
                height={192}
                src={attachmentInfo.onboardLogoUrl}
                alt=""
              ></img>
              Onboarded Logo</>}
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col sm={2}>
            {isInActionStatus && displayBanner &&(
              <FormControl
                name="approveBanner"
                type={"checkbox"}
                defaultChecked={false}
                onChange={onApproveLogoBannerChange}
              />
            )}
          </Col>
          <Col sm={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap:"12px"
              }}
            >
             {attachmentInfo.bannerUrl && <><img
                width={400}
                height={480}
                src={attachmentInfo.bannerUrl}
                alt=""
              ></img>
              Waiting Approve Banner</>}
            </div>
          </Col>
          <Col sm={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap:"12px"
              }}
            >
              {attachmentInfo.onboardBannerUrl && <><img
                width={400}
                height={480}
                src={attachmentInfo.onboardBannerUrl}
                alt=""
              ></img>
              Onboarded Banner</>}
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "flex-end" }}>
          <Col sm={2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></div>
          </Col>
          <Col sm={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UploadPanel w={192} h={192} type={"logo"}></UploadPanel>
            </div>
          </Col>

          <Col sm={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UploadPanel w={400} h={480} type={"banner"}></UploadPanel>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div>
        <style>
          {`
          .card {
            margin-top: 20px;
          }
        `}
        </style>
        <Row
          style={{
            display:
              !attachmentInfo.logoUrl && !attachmentInfo.onboardLogoUrl
                ? "block"
                : "block",
          }}
        >
          {isInActionStatus && displayReview ? (
            <Col xs={1} md={1} style={{ marginTop: "12%" }}>
              {attachmentInfo.logoUrl ? (
                <FormControl
                  name="approveLogo"
                  type={"checkbox"}
                  defaultChecked={false}
                  onChange={onApproveLogoBannerChange}
                />
              ) : (
                ""
              )}
            </Col>
          ) : (
            ""
          )}
          {displayReview ? (
            <UploadPanel url={attachmentInfo.logoUrl} type="logo" />
          ) : (
            ""
          )}
          {displayOnboard ? (
            <ImageColumn
              url={attachmentInfo.onboardLogoUrl}
              text="Onboard Logo"
              type="LOGO"
            />
          ) : (
            ""
          )}
        </Row>
        <Row
          style={{
            display:
              !attachmentInfo.bannerUrl && !attachmentInfo.onboardBannerUrl
                ? "block"
                : "block",
          }}
        >
          {isInActionStatus && displayReview ? (
            <Col xs={1} md={1} style={{ marginTop: "20%" }}>
              {attachmentInfo.bannerUrl ? (
                <FormControl
                  name="approveBanner"
                  type={"checkbox"}
                  defaultChecked={false}
                  onChange={onApproveLogoBannerChange}
                />
              ) : (
                ""
              )}
            </Col>
          ) : (
            ""
          )}

          {displayReview ? (
            <UploadPanel url={attachmentInfo.bannerUrl} type="banner" />
          ) : (
            ""
          )}
          {displayOnboard ? (
            <ImageColumn
              url={attachmentInfo.onboardBannerUrl}
              text="Onboard Banner"
              type="BANNER"
            />
          ) : (
            ""
          )}

          {/* <ImageUploading
        single
        value={bannerImage}
        onChange={onBannerChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
            &nbsp;
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onUpdateImage('banner', image)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading> */}
      {/* </Row> */}
      {/* </div> */}
    </Tab>
  );
};

const propsUploadPanel = {
  type: PropTypes.string,
  w: PropTypes.number,
  h: PropTypes.number,
};
/**
 * UPLOAD IMAGE
 */
const UploadPanel = (props) => {
  const { type } = props;
  let { referenceId } = useParams();
  let { merchantId } = useParams();
  const [images, setImages] = useState([]);
  const setAppState = useAppContext()[1];
  // useEffect(() => {
  //   setImages(images);
  // }, [images]);

  // data_url: http
  //data_url:
  // const [image, setImage] = useState([])
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const getPresignUrl = (type, image) => {
    const initData = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        attachmentType: type,
      },
    };
    ApiService.postPresignUrl(initData, merchantId, referenceId)
      .then((resData) => {
        uploadImage(resData.presignedURL, image);
      })
      .catch((err) => {
        console.debug(err);
      });
  };

  const uploadImage = async (url, file) => {
    sendPresignedUrlWithFile(url, file.data_url);
    
    
  };

  const sendPresignedUrlWithFile = async (url, file) => {
    try {
      const buffer = Buffer.from(
        file.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      const result = await axios.put(url, buffer, {
        headers: {
          "access-control-allow-origin": "*",
          "x-amz-acl": "bucket-owner-full-control",
          "Content-Type": "image/png",
          "Content-Encoding": "base64",
        },
      });
      const successMessage = "Upload image successfully, please wait about 5 minutes to scan virus";
      setAppState({
        type: Actions.toast.type,
        hidden: false,
        props: {
          type: "success",
          message: successMessage,
        },
      });
      setTimeout(()=>{
        window.location.reload(false);
      },2000)
      return result;
    } catch (e) {
      const errorMessage = e.message;
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
      console.log(e.message);
    }
  };

  const onUpdateImage = (type, imageList) => {
    {
      imageList.map((localImage, index) => getPresignUrl(type, localImage));
    }
  };

  // url={!image.data_url || image.data_url === undefined ? reviewUrl : image.data_url}
  return (
    <ImageUploading
      single
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      acceptType={["png"]}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="upload__image-wrapper"
            onClick={onImageUpdate}
            style={{ border: "solid 1.5px #000", position: "relative" }}
            {...dragProps}
          >
            {imageList?.map((image, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: props?.w,
                  height: props?.h,
                }}
              >
                <img
                  src={image?.data_url}
                  alt=""
                  width={props?.w || 192}
                  height="auto"
                />
              </div>
            ))}

            {imageList.length === 0 && (
              <div
                style={{
                  display: "block",
                  width: props?.w,
                  height: props?.h,
                }}
              >
                <span
                  style={{ position: "absolute", top: "37.5%", left: "10%" }}
                >
                  Click or drag file to this area to upload
                </span>
              </div>
            )}
          </div>
          <span style={{ width: 400 }}>
            *{props?.w === 400 ? 'Banner' : 'Logo'} image size must be {props?.w} x {props.h} and image type must
            be .png
          </span>
          <button
            className="btn btn-default"
            onClick={() => onUpdateImage(type, imageList)}
          >
            Click to Upload
          </button>
        </div>
      )}
    </ImageUploading>
  );
};

const MerchantApproval = () => {
  //state
  let { referenceId } = useParams();
  let { merchantId } = useParams();
  const setAppState = useAppContext()[1];
  const [approvalInformation, setApprovalInformation] = useState([]);
  const [merchantApproveFieldList, setMerchantApproveFieldList] = useState([]);
  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const [isDisableLogoBannerTab, setIsDisableLogoBannerTab] = useState(false);
  const reload = false;
  const [action, setAction] = useState();
  const [showMerchantApproval, setShowMerchantApproval] = useState(false);
  const [isShowLoadMore, setShowMore] = useState(true);
  const [page, setPage] = useState(0);
  const [valueSearch, setValueSearch] = useState("");

  const ingoreMerchantProperties = [
    "stores",
    "referenceId",
    "status",
    "lastUpdatedDate",
    "approvalNote",
  ];
  const ingoreStoreProperties = ["referenceId"];
  const initState = {
    approveLogo: false,
    merchantStoreId: "",
    approveBanner: false,
    merchantDictionaryList: [],
  };
  const [state, setState] = useState(initState);
  const [merchantDetail, setMerchantDetail] = useState({});
  // const [merchantStoreId, setMerchantStoreId] = useState();
  const [storeList, setStoreList] = useState([]);
  const [modalAddStoreVisible, setModalAddStoreVisible] = useState(false);

  /** ADD NEW STORE */
  const openModalAddStore = () => {
    setModalAddStoreVisible(true);
  };
  const closeModalAddStore = useCallback(() => {
    setModalAddStoreVisible(false);
  }, []);

  const onSubmitAddStore = useCallback((storeDetail) => {
    updateMerchant(storeDetail, false, true);
    setModalAddStoreVisible(false);
  }, []);

  const compareMerchant = (
    onboardMerchant,
    reviewMerchant,
    columnDisplayDictionaryList
  ) => {
    const isOnlyDisplayOnboardData = reviewMerchant.status === "COMPLETED";
    onboardMerchant = !onboardMerchant ? [] : onboardMerchant;
    setMerchantApproveFieldList(
      compareObject(
        onboardMerchant,
        reviewMerchant,
        ingoreMerchantProperties,
        isOnlyDisplayOnboardData,
        columnDisplayDictionaryList
      )
    );

    // let approveStoreList = [];
    // reviewMerchant.stores = !reviewMerchant.stores ? [] : reviewMerchant.stores;
    // onboardMerchant.stores = !onboardMerchant.stores
    //   ? []
    //   : onboardMerchant.stores;
    // reviewMerchant.stores.forEach((store) => {
    //   let onboardStore = onboardMerchant.stores.find(
    //     (s) => s.referenceId === store.referenceId
    //   );
    //   let changedStoreArray = compareObject(
    //     onboardStore,
    //     store,
    //     ingoreStoreProperties,
    //     isOnlyDisplayOnboardData,
    //     columnDisplayDictionaryList
    //   );
    //   if (changedStoreArray.length > 0) {
    //     changedStoreArray["firstApprove"] = !onboardStore;
    //     changedStoreArray["referenceId"] = store.referenceId;
    //     approveStoreList.push(changedStoreArray);
    //   }
    // });
    // setMerchantApproveStoreList(approveStoreList);
  };

  const RowCollapseTable = (props) => {
    //props
    const { row } = props;
    // console.log("de",row)
    //state
    const [open, setOpen] = useState(false);
    const [editStoreVisible, setEditStoreVisible] = useState(false);
    const [storeDetail, setStoreDetail] = useState({});
    const loadStoreDetail = (storeReferenceId) => {
      const initHeader = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      ApiService.getStoreDetail(initHeader, storeReferenceId)
        .then((resData) => {
          setStoreDetail(resData);
        })
        .catch((err) => {
          console.debug(err);
        });
    };

    //handle
    const openModalEditStore = (storeReferenceId) => {
      loadStoreDetail(storeReferenceId);
      if (storeDetail) {
        setEditStoreVisible(true);
      }
    };

    const closeModalEditStore = useCallback(() => {
      setEditStoreVisible(false);
    }, []);

    const onSubmitEditStore = useCallback((storeDetail) => {
      updateMerchant(storeDetail, false, true);
      setEditStoreVisible(false);
    }, []);

    return (
      <React.Fragment>
        <ModalEditStore
          open={editStoreVisible}
          onClose={closeModalEditStore}
          onSubmit={onSubmitEditStore}
          storeDetail={storeDetail}
          listField={state.merchantDictionaryList}
        ></ModalEditStore>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.dataMerge?.name?.waitingApprove && row?.dataMerge?.referenceId
              ? `${row?.dataMerge?.name?.waitingApprove} (${row?.dataMerge?.referenceId?.waitingApprove})`
              : row?.dataMerge?.referenceId?.waitingApprove}
          </TableCell>
          <TableCell></TableCell>
          <TableCell />
          <TableCell />
          <TableCell>
            <Button
              onClick={() =>
                openModalEditStore(
                  row?.dataMerge?.referenceId?.onboarded
                    ? row?.dataMerge?.referenceId?.onboarded
                    : row?.dataMerge?.referenceId?.waitingApprove
                )
              }
            >
              Edit
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Field Name</TableCell>
                      <TableCell>Waiting Approve Data</TableCell>
                      <TableCell>Onboarded Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(row?.dataMerge).map((value, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {value?.label}
                        </TableCell>
                        <TableCell> {value?.waitingApprove}</TableCell>
                        <TableCell>{value?.onboarded}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const CollapsibleTable = ({ stores, loadMoreData }) => {
    //state
    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //handle
    const loadMoreClick = () => {
      setLoading(true);
      if (loadMoreData) {
        loadMoreData(afterLoadMore);
      }
    };
    const afterLoadMore = () => {
      setLoading(false);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const StyledGrid = Styled(Grid)`
    * {
      font-size: 16px !important;
      font-family: 'HelveticaNeue-Light', Arial, sans-serif !important;
    }
    *.MuiTableCell-head{
      font-size: 16px !important;
      font-family: "HelveticaNeue-Bold" !important;
    }
  `;
    return (
      <StyledGrid>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableBody>
              {stores.map((row, index) => (
                <RowCollapseTable key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Flex p={3} justifyContent="center">
          {loading ? (
            <Spinner />
          ) : isShowLoadMore ? (
            <Button onClick={loadMoreClick}>Load more stores</Button>
          ) : (
            "End of list."
          )}
        </Flex>
      </StyledGrid>
    );
  };

  const MerchantApproveAction = () => {
    return (
      <Flex p={3} className="approve-action">
        <Button
          mr={2}
          className="btn btn-yellow"
          onClick={() => showMerchantApprovalConfirm(true, "APPROVE")}
        >
          Approve
        </Button>
        <Button
          mr={2}
          onClick={() => showMerchantApprovalConfirm(true, "DENY")}
        >
          Decline
        </Button>
      </Flex>
    );
  };

  const compareObject = (
    onboardData,
    reviewData,
    ignoreField,
    isOnlyDisplayOnboardData,
    columnDisplayDictionaryList
  ) => {
    let differentArray = [];
    onboardData = !onboardData ? [] : onboardData;
    onboardData.publishDate = formatDateTime(onboardData.publishDate);
    reviewData.publishDate = formatDateTime(reviewData.publishDate);

    for (const [key, value] of Object.entries(reviewData)) {
      if (ignoreField.indexOf(key) >= 0) {
        continue;
      }
      if (key === "categories") {
        compareCategories(
          onboardData[key],
          value,
          key,
          differentArray,
          isOnlyDisplayOnboardData,
          columnDisplayDictionaryList
        );
      } else {
        compareValue(
          onboardData[key],
          value,
          key,
          differentArray,
          isOnlyDisplayOnboardData,
          columnDisplayDictionaryList
        );
      }
    }
    for (const [key, value] of Object.entries(onboardData)) {
      if (
        ignoreField.indexOf(key) >= 0 ||
        differentArray.findIndex((field) => field.key === key) >= 0
      ) {
        continue;
      }
      if (key === "categories") {
        compareCategories(
          value,
          reviewData[key],
          key,
          differentArray,
          isOnlyDisplayOnboardData,
          columnDisplayDictionaryList
        );
      } else {
        compareValue(
          value,
          reviewData[key],
          key,
          differentArray,
          isOnlyDisplayOnboardData,
          columnDisplayDictionaryList
        );
      }
    }
    return differentArray;
  };

  const formatDateTime = (dateTime) => {
    if (!dateTime) {
      return "";
    }
    return formatDate(dateTime, "YYYY-MM-DD HH:mm:ss");
  };

  const compareCategories = (
    onboardCategories,
    reviewCategories,
    key,
    differentArray,
    isOnlyDisplayOnboardData,
    columnDisplayDictionaryList
  ) => {
    const onboardValue = onboardCategories
      ? onboardCategories.map((category) => category.name).join(" | ")
      : "";
    const reviewValue = reviewCategories
      ? reviewCategories.map((category) => category.name).join(" | ")
      : "";
    compareValue(
      onboardValue,
      reviewValue,
      key,
      differentArray,
      isOnlyDisplayOnboardData,
      columnDisplayDictionaryList
    );
  };

  const compareValue = (
    onboardValue,
    reviewValue,
    key,
    differentArray,
    isOnlyDisplayOnboardData,
    columnDisplayDictionaryList
  ) => {
    if (onboardValue === reviewValue && !isOnlyDisplayOnboardData) {
      return;
    }
    const obj = {
      key: key,
      fieldName:
        columnDisplayDictionaryList.find((c) => c["key"] === key)?.value || key,
      oldValue: addPrefixSuffix(key, onboardValue || ""),
      newValue: addPrefixSuffix(
        key,
        isOnlyDisplayOnboardData ? "" : reviewValue || ""
      ),
    };
    differentArray.push(obj);
  };

  const addPrefixSuffix = (key, value) => {
    if (!value) {
      return "";
    }
    switch (key) {
      case "commissionRate":
        return value.toFixed(2) + "%";
      case "transactionFee":
        return "R " + value.toFixed(2);

      default:
        return value;
    }
  };

  const loadMerchantInfo = (callBack = null) => {
    // make header request
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // make a http call to fetch data
    ApiService.getMerchantInfo(initHeader, referenceId)
      .then((resData) => {
        if (resData) {
          state.merchantDictionaryList = resData.columnDisplayDictionaryList;
          state.merchantStoreId = resData.id;
          setApprovalInformation({
            firstApprove: !resData.onboardData,
            status: resData.reviewData.status,
            merchantDisplayName:
              resData.onboardData?.displayName ||
              resData.reviewData.displayName,
            lastUpdated: formatDateTime(resData.reviewData.lastUpdatedDate),
          });
          compareMerchant(
            resData.onboardData,
            resData.reviewData,
            resData.columnDisplayDictionaryList
          );
        }
      })
      .catch((err) => {
        console.debug(err);
        // handle log
      })
      .finally((fn) => {
        if (callBack) {
          callBack();
        }
      });
  };

  const toogleChange = (event) => {
    const { name } = event.target;
    setState({
      ...state,
      [name]: !state[name],
    });
  };

  const loadAttachmentUrl = (callBack = null) => {
    // make header request
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // make a http call to fetch data
    ApiService.getMerchantAttachmentUrl(initHeader, referenceId)
      .then((resData) => {
        if (resData) {
          setAttachmentInfo({
            logoUrl: resData.logoUrl,
            bannerUrl: resData.bannerUrl,
            onboardLogoUrl: resData.onboardLogoUrl,
            onboardBannerUrl: resData.onboardBannerUrl,
          });
        }
        setIsDisableLogoBannerTab(
          !resData ||
            (!resData.logoUrl &&
              !resData.bannerUrl &&
              !resData.onboardLogoUrl &&
              !resData.onboardBannerUrl)
        );
      })
      .catch((err) => {
        console.debug(err);
        // handle log
      })
      .finally((fn) => {
        if (callBack) {
          callBack();
        }
      });
  };

  const loadData = () => {
    loadMerchantInfo();
    loadAttachmentUrl();
  };

  const loadMoreData = async (calback = null) => {
    await getDataStoreByEventKey(
      "store",
      (curStores) => {
        setStoreList([...storeList, ...curStores]);
        setPage(page + 1);
        calback();
      },
      page + 1
    );
  };

  const loadMerchantDetail = () => {
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    ApiService.getMerchantDetail(initHeader, state.merchantStoreId)
      .then((resData) => {
        setMerchantDetail(resData);
      })
      .catch((err) => {
        console.debug(err);
      });
  };

  const showMerchantApprovalConfirm = (show, action) => {
    setShowMerchantApproval(show);
    setAction(action);
  };
  useEffect(() => {
    loadData();
  }, [reload]);

  const [editMerchantVisible, setEditMerchantVisible] = React.useState(false);

  const openModalEditMerchant = () => {
    loadMerchantDetail();
    if (merchantDetail) {
      setEditMerchantVisible(true);
    }
  };

  const closeModalEditMerchant = () => {
    setEditMerchantVisible(false);
  };

  const onSubmitEditMerchant = (merchantDetail) => {
    updateMerchant(merchantDetail, true, false);
    setEditMerchantVisible(false);
  };
  const updateMerchant = (
    merchantDetail,
    reloadMerchant = false,
    reloadStore = false
  ) => {
    const initData = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        referenceId: referenceId,
        merchant: merchantDetail,
      },
    };
    const successMessage = "Update merchant successfully";
    ApiService.updateMerchant(initData, merchantId)
      .then((resData) => {
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "success",
            message: successMessage,
          },
        });
        if (reloadMerchant) {
          loadData();
          loadMerchantDetail();
        }
        if (reloadStore) {
          loadData();
          loadStores("store");
        }
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Internal server error";
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
        console.debug(err);
        // handle log
      })
      .finally((fn) => {});
  };

  const getDataStoreByEventKey = async (
    eventKey,
    callback = null,
    curPage = page
  ) => {
    if (eventKey === "store") {
      const initData = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      /**
       *
       * LIST_STORE_API
       * STEP 1: columnDisplayDictionaryList
       * 1.1 Filter list field store
       * STEP 2: search list store of merchant
       */

      /**
       * 1.1
       */
      // console.log(dictFieldStore);

      let promiseGetListStoreByMerchant = ApiService.getStoresByMerchantId(
        initData,
        state.merchantStoreId,
        curPage,
        5,
        valueSearch
      );

      Promise.all([promiseGetListStoreByMerchant]).then((values) => {
        const [dataListStore] = values;
        if (dataListStore?.length > 0) {
          setShowMore(true);
        } else {
          setShowMore(false);
        }
        const columnDisplayDictionaryList = state.merchantDictionaryList
          .filter((e) => e.type === "STORE")
          .map((i) => ({
            fieldCode: i.key,
            fieldName: i.value,
          }));
        const d = dataListStore.map((i) => ({
          ...i,
          dataMerge: columnDisplayDictionaryList
            .map((c) => ({
              [c.fieldCode]: {
                label: c.fieldName,
                onboarded: i.storeOnboard ? i.storeOnboard[c.fieldCode] : "",
                waitingApprove: i.storeWaitingApprove
                  ? i.storeWaitingApprove[c.fieldCode]
                  : "",
              },
            }))
            .reduce((a, v) => ({ ...a, ...v }), {}),
        }));
        // console.log(d);
        callback(d);
      });
    }
  };

  const loadStores = async (eventKey) => {
    await getDataStoreByEventKey(
      eventKey,
      (stores) => {
        setStoreList(stores);
      },
      0
    );
  };

  const onSearchStore = () => {
    loadStores("store");
  };

  return (
    <div className="container" style={{ position: "relative" }}>
      <style>
        {`
            #body {
              margin-top: 75px;
              position: relative;
              display: flex;
            }

            .approve-action {
              clear: both;
              position: absolute;
              bottom: 0;
              right: 0;
            }
            
            button {
              margin-right: 5px
            }
            .information-header {
              padding: 10px 0px;
            }
            .mb-90 {
              margin-bottom: 90px;
            }
            .d-none {
              display: none important;
            }
            .tab-content > .active {
              background-color: white;
          }

        `}
      </style>
      <Container className="information-header">
        {showMerchantApproval ? (
          <MerchantApprovalConfirm
            action={action}
            loadData={loadData}
            referenceId={referenceId}
            merchantDisplayName={approvalInformation.merchantDisplayName}
            callBack={() => setShowMerchantApproval(false)}
            approveLogo={state.approveLogo}
            approveBanner={state.approveBanner}
          />
        ) : null}
        <Row>
          <Col xs={2} md={2}>
            Merchant Trading Name:
          </Col>
          <Col xs={2} md={4}>
            {approvalInformation.merchantDisplayName}
          </Col>
        </Row>
        <Row>
          <Col xs={4} md={2}>
            Reference Id:
          </Col>
          <Col xs={4} md={4}>
            {referenceId}
          </Col>
        </Row>
        <Row>
          <Col xs={4} md={2}>
            Status:
          </Col>
          <Col xs={4} md={4}>
            {approvalInformation.status}
          </Col>
        </Row>
        <Row>
          <Col xs={4} md={2}>
            Last Updated:
          </Col>
          <Col xs={4} md={4}>
            {approvalInformation.lastUpdated}
          </Col>
        </Row>
      </Container>

      <ModalEditMerchant
        open={editMerchantVisible}
        onClose={closeModalEditMerchant}
        merchantDetail={merchantDetail}
        onSubmit={onSubmitEditMerchant}
      />

      <ModalAddStore
        open={modalAddStoreVisible}
        onClose={closeModalAddStore}
        onSubmit={onSubmitAddStore}
        listField={state.merchantDictionaryList}
      />
      <Tabs
        defaultActiveKey="home"
        id="noanim-tab-example"
        className="mb-90"
        onSelect={loadStores}
      >
        <Tab eventKey="home" title="Merchant Info">
          <Container
            style={{
              display: "flex",
              "flex-direction": "row-reverse",
              padding: 30,
            }}
          >
            <Button className="btn btn-yellow" onClick={openModalEditMerchant}>
              Edit
            </Button>
          </Container>
          <div
            style={{
              display: merchantApproveFieldList.length === 0 ? "none" : "block",
            }}
          >
            <DataTable
              key={`DataTable-Merchant-Info`}
              data={merchantApproveFieldList}
              hideOldValueColumn={approvalInformation.firstApprove}
              className="background-white"
            />
          </div>
        </Tab>
        <Tab
          eventKey="store"
          title="Store Info"
          // disabled={merchantApproveStoreList.length === 0}
        >
          <Fragment>
            <div style={{ display: "flex", padding: 30 }}>
              <Input
                style={{ height: 48, marginRight: 12 }}
                placeholder="Search By: ( MID or RefID, StoreName)"
                type="text"
                value={valueSearch}
                defaultValue={valueSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearchStore();
                  }
                }}
                onChange={(e) => {
                  setValueSearch(e.target.value);
                }}
              />
              <Button style={{ marginRight: 12 }} onClick={onSearchStore}>
                Search
              </Button>
              <Button
                onClick={openModalAddStore}
                className="btn btn-yellow"
                style={{ marginRight: 12 }}
              >
                Add Store
              </Button>
            </div>

            <CollapsibleTable
              key="MerchantStoreTable"
              stores={storeList}
              className="background-white"
              loadMoreData={loadMoreData}
              isShowLoadMore={isShowLoadMore}
            />
          </Fragment>

          {/* <MerchantStoreTable
            key="MerchantStoreTable"
            stores={merchantApproveStoreList}
            className="background-white"
          /> */}
        </Tab>
        <Tab
          eventKey="contact"
          title="Logo/Banner"
          // disabled={isDisableLogoBannerTab}
        >
          <LogoBanner
            attachmentInfo={attachmentInfo}
            status={approvalInformation.status}
            className="background-white"
            onApproveLogoBannerChange={toogleChange}
          />
        </Tab>
      </Tabs>
      {approvalInformation.status === "AWAITING_APPROVAL" ? (
        <MerchantApproveAction />
      ) : (
        ""
      )}
    </div>
  );
};

export default MerchantApproval;
