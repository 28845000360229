export default class Constant {
  static PHONE_NUMBER = '0860 000 387';
  static EMAIL_SUPPORT = 'moretymemerchantsupport@tymebank.co.za';
  static USERNAME_MAX_LENGTH = 128;
  static PASSWORD_MAX_LENGTH = 256;
  static FEATURE_RESTRICTION = {
    REDEMPTION: "redemption",
    REFUND: "refund"
  };
  static ADMIN_PORTAL_ROLE = 'moretyme-merchant-admin';
  static ACCEPTED_EMAIL_DOMAIN_ACCOUNT = ['@TYME.COM', '@TYMEDIGITAL.COM', '@TYMEDIGITAL.INFO'];
  static FEDERATED_PROVIDER_NAME = ['Tyme.com','TymeDigital.com', 'Tymedigital.info'];
}