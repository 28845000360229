import React, { useEffect } from 'react';
import { Redirect, Route, useLocation , useHistory} from 'react-router-dom';
import { useUserContext } from 'context/UserContext';
import { Flex, Box } from 'rebass/styled-components';
import Header from 'components/layout/Header';
import Modals from 'components/common/Modals';
import Footer from 'components/layout/Footer';
import Toast from 'components/common/Toast';
import { useAppContext } from 'context/AppContext';
import Actions from 'actions';
import ApiService from 'services/apiService'
import Constant from 'utils/Constant';

const Layout = ({ children, ...props }) => {
  const RealComponent = children;
  return (
    <Flex padding={0} flexDirection="column" minHeight="100%">
      <Header />
      <Box id="body" flex="1 0 auto">
        <RealComponent {...props}></RealComponent>
      </Box>
      <Footer />
      <Toast />
      <Modals />
    </Flex>
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const setAppState = useAppContext()[1];
  const { userObj, setUserObj } = useUserContext();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  useEffect(() => {
    if(rest.federatedLogin && rest.from != from) {
      history.replace(rest.from);
    }

    if (userObj.timerId) {
      console.debug('Clean TimerId: ', userObj.timerId);
      clearInterval(userObj.timerId)
      setUserObj({
        ...userObj,
        timerId: null
      });
    }
    if (location.pathname === '/login' || location.pathname === '/reset-password' || location.pathname === '/login') {
      setAppState({
        type: Actions.sessionConfirmModal.type,
        hidden: true
      });
    } else {
      ApiService.getCurrentSession().then(res => {
        const timerId = setInterval(() => {
          ApiService.getCurrentSession()
            .catch(err => {
              if (err.code !== "NetworkError") {
                clearInterval(timerId)
              }
              if (err.response) {
                setAppState({
                  type: Actions.sessionConfirmModal.type,
                  hidden: false
                });
              }
            })
        }, 5000);
        setUserObj({
          ...userObj,
          timerId: timerId
        });
      }).catch(err => {
        setAppState({
          type: Actions.sessionConfirmModal.type,
          hidden: false
        });
      })
    }
  }, [location.pathname]);

  return (
    <Route {...rest}
      render={({ location }) =>
        rest.path === '/logout' ? <Layout {...location} children={children} /> :
          // If user already authenticated, then
          userObj.isAuthenticated ? (
            // Check need to force change password?
            userObj.needUpdatePassword ?
              (
                // show Change password page
                rest.path === '/reset-password' ? <Layout {...location} children={children} /> : null
              )
              :
              // If current path is login, then
              (
                userObj.termsAndConditions !== '1' ?
                  (
                    rest.path === '/terms' ? <Layout {...location} children={children} /> : <Redirect
                      to={{
                        pathname: "/terms",
                        state: { from: location }
                      }}
                    />
                  )
                  :
                  rest.path === '/login' ?
                    (
                      // Redirect to root
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: location }
                        }}
                      />
                    ) :
                    (
                      rest.path.match(/admin\/+?.*/) && userObj.userRole && userObj.userRole.indexOf(Constant.ADMIN_PORTAL_ROLE ) < 0 ?
                      // Must have merchantApprover to approve merchant
                      <Redirect
                        to={{
                          pathname: "/PageNotFound",
                          state: { from: location }
                        }}
                      />
                      :
                      (
                        // show the page that need
                        <Layout {...location} children={children} />
                      )
                    )
              )
          ) :
            // If not authenticate yet and current is not in log in page, then redirect to login page
            (rest.path !== '/login' ?
              (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location }
                  }}
                />
              ) :
              (
                <Layout {...location} children={children} />
              )
            )
      }
    >
    </Route>
  );
};

export default PrivateRoute;