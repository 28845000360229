import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { Flex, Box } from "rebass/styled-components";
import { Divider } from "@material-ui/core";
import _ from "lodash";
import ApiService from "services/apiService";
import iconPurchaseHistory from "assets/images/purchase-history.svg";
import Spinner from "components/common/Spinner";
import Button from "react-bootstrap/lib/Button";
import { Col, Input } from "reactstrap";
import Actions from "actions/index";
import { useAppContext } from "context/AppContext";
import moment from "moment";
import Select from "react-select";
import ModalAddMerchant from "components/admin/merchant-approval/modal-add-merchant/ModalAddMerchant";
import { Row } from "react-bootstrap";
const MerchantManagement = () => {
  const initState = {
    keyword: "",
    merchantId: "",
    valueError: "",
    note: "",
    reasonError: false,
  };
  const [state, setState] = useState(initState);
  const setAppState = useAppContext()[1];
  const [isShowLoadMore, setShowMore] = useState(true);
  const [data, setHistories] = useState([]);
  const [page, setPage] = useState(0);
  const [optionAggregators, setOptionAggregators] = useState([]);
  const [optionAggregatorsAddMerchant, setOptionAggregatorsAddMerchant] = useState([]);
  const [selectedAggregator, setSelectedAggregator] = useState();
  const [optionStatus, setOptionStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const defaultLimit = 10;
  const [openModalAddNewMerchant, setOpenModalAddNewMerchant] = useState(false);
  // openModalAddNewMerchant
  const closeModalAddMerchantHandle = () => {
    setOpenModalAddNewMerchant(false);
  };
  const openModalAddMerchantHandle = () => {
    setOpenModalAddNewMerchant(true);
  };

  const onSubmitAddNewMerchantHandle =(e)=>{

    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        aggregatorId: e.aggregatorId,
        merchant: e,
      },
    };

    const successMessage = "Create merchant successfully";
    ApiService.createMerchant(initHeader)
      .then((resData) => {
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "success",
            message: successMessage,
          },
        });
        setOpenModalAddNewMerchant(false)
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Internal server error";
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
        console.debug(err);
        // handle log
      })
      .finally((fn) => {});
  }

  const BoxContent = ({ children, ...rest }) => (
    <Box {...rest}>
      <Box px={[2, 3, 4]}>{children}</Box>
    </Box>
  );

  const Header = ({ headers }) => {
    return (
      <Flex fontSize={["14px", "16px"]} py={3} className="font-bold">
        {headers.map((header, index) =>
          header.isVisible ? (
            <Box key={`purchase-header-${index}`} width={header.width}>
              {header.Header}
            </Box>
          ) : null
        )}
      </Flex>
    );
  };

  const MerchantManagementTable = () => {
    // init one time
    const [columns] = useState(
      useMemo(
        () => [
          {
            Header: "MerchantId",
            accessor: "merchantId",
            width: "20%",
          },
          {
            Header: "Merchant",
            accessor: "merchantName",
            width: "20%",
            style: "cursor:pointer",
            Cell: (props) => (
              <a onClick={() => sendValue(props.row.original)}>{props.value}</a>
            ),
          },
          {
            Header: "Reference ID",
            accessor: "referenceId",
            width: "20%",
            Cell: (props) => (
              <a
                onClick={() => redirectMerchantApprovePage(props.row.original)}
              >
                {props.value}
              </a>
            ),
          },
          {
            Header: "Status",
            accessor: "status",
            width: "20%",
          },
          {
            Header: "Onboard Date",
            accessor: "onboardDate",
            width: "20%",
            Cell: ({ value }) => {
              if (value) {
                return moment(new Date(value)).format("DD MMM YYYY, HH:mm:ss");
              }
              return value;
            },
            filter: "between",
          },
          {
            Header: "Last Updated Date",
            accessor: "lastUpdatedDate",
            width: "20%",
            Cell: ({ value }) => {
              if (value) {
                return moment(new Date(value)).format("DD MMM YYYY, HH:mm:ss");
              }
              return value;
            },
            filter: "between",
          },
          {
            Header: "Short Code",
            accessor: "shortCode",
            width: "20%",
          },
          {
            Header: "Aggregator Name",
            accessor: "aggregatorName",
            width: "20%",
          },
        ],
        []
      )
    );

    // Init a instance of table by usetable
    const instanceTable = useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: ["merchantId"],
        },
      },
      useFilters,
      useSortBy
    );
    const { headers, rows, prepareRow } = instanceTable;
    const loadMoreData = (callBack = null, curdata = data, curPage = page) => {
      // make header request
      const initHeader = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      // make a http call to fetch data
      let aggregator = selectedAggregator ? selectedAggregator.value : null;
      let status = selectedStatus ? selectedStatus.value : null;

      ApiService.getMerchantList(
        initHeader,
        curPage,
        defaultLimit,
        aggregator,
        status,
        state.keyword
      )
        .then((resData) => {
          if (resData) {
            if (resData.length === 0) {
              setShowMore(false);
            } else {
              const tempHistory = [...curdata, ...resData];
              const histories = _.uniq(tempHistory, "keyword");
              setHistories(histories);
              setPage(curPage + 1);
            }
          }
        })
        .catch((err) => {
          // handle log
        });
    };

    return (
      <Fragment>
        <BoxContent>
          <Header headers={headers} />
        </BoxContent>
        <BoxContent>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <div key={`row-${row.values.merchantId}`}>
                <Divider />
                <Flex fontSize={["14px", "16px"]} py={3}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Box
                        width={cell.column.width}
                        key={`Cell-${rowIndex}-${cellIndex}`}
                      >
                        {cell.render("Cell")}
                      </Box>
                    );
                  })}
                </Flex>
              </div>
            );
          })}
        </BoxContent>
        <Box mx={[2, 4]} className="hidden-xs">
          <Divider />
        </Box>
        <EndingSection
          isShowLoadMore={isShowLoadMore}
          loadMoreData={loadMoreData}
        />
      </Fragment>
    );
  };

  const EndingSection = ({ isShowLoadMore, loadMoreData }) => {
    const [loading, setLoading] = useState(false);
    const loadMoreClick = () => {
      setLoading(true);
      if (loadMoreData) {
        loadMoreData(afterLoadMore);
      }
    };
    const afterLoadMore = () => {
      setLoading(false);
    };
    return (
      <Flex p={3} justifyContent="center">
        {loading ? (
          <Spinner />
        ) : isShowLoadMore ? (
          <Button onClick={loadMoreClick}>Load more transactions</Button>
        ) : (
          "End of list."
        )}
      </Flex>
    );
  };

  const search = () => {
    let curPage = 0;
    const initData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let aggregator = selectedAggregator ? selectedAggregator.value : null;
    let status = selectedStatus ? selectedStatus.value : null;

    ApiService.getMerchantList(
      initData,
      curPage,
      defaultLimit,
      aggregator,
      status,
      state.keyword
    )
      .then((resData) => {
        if (resData) {
          if (resData.length === 0) {
            setShowMore(false);
            const tempHistory = [...resData];
            const histories = _.uniq(tempHistory, "merchantId");
            setHistories(histories);
          } else {
            setShowMore(true);
            const tempHistory = [...resData];
            const histories = _.uniq(tempHistory, "merchantId");
            setHistories(histories);
            setPage(curPage + 1);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Internal server error";
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
        // handle log
      })
      .finally((fn) => {});
  };

  const inputChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const loadAggregator = (callBack = null) => {
    // make header request
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // make a http call to fetch data
    ApiService.getAggregatorList(initHeader)
      .then((resData) => {
        const { _optionCategories } = mapCategories(resData);
        const data = [{value:null,label:"All Aggreator"}].concat(_optionCategories)
        setOptionAggregators(data);
        setOptionAggregatorsAddMerchant(_optionCategories)

      })
      .catch((err) => {
        console.debug(err);
        // handle log
      })
      .finally((fn) => {});
  };

  const loadStatus = (callBack = null) => {
    // make header request
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // make a http call to fetch data
    ApiService.getStatusList(initHeader)
      .then((resData) => {
        const { _optionCategories } = mapCategories(resData);
        const data = [{value:null,label:"All Status"}].concat(_optionCategories)
        setOptionStatus(data);
      })
      .catch((err) => {
        console.debug(err);
        // handle log
      })
      .finally((fn) => {});
  };

  function mapCategories(fullCategoryList,defaultTitle) {
    const _optionCategories = [];
    // _optionCategories.push({ label: defaultTitle, value: null });
    fullCategoryList.forEach((category) => {
      _optionCategories.push({ label: category.name, value: category.code });
    });
    return { _optionCategories };
  }

  const sendValue = (value) => {
    //////
  };

  const redirectMerchantApprovePage = (value) => {
    window.location.href =
      "/admin/merchant-approval/" +
      value.aggregatorId +
      "/" +
      value.referenceId;
  };

  useEffect(() => {
    loadAggregator();
    loadStatus();
  }, []);
  return (
    <div className="box" style={{ overflow: "visible", zIndex: 0 }}>
      {/* <Flex alignItems="center">
        <Box mx={5} py={2}>
          <h3>{title}</h3>
        </Box>
      </Flex>
      <Box mx={[2, 4]}>
        <Divider />
      </Box>
      <Flex alignItems="center">
        <Box
          mx={5}
          py={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            Aggregator:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <div
            style={{
              width: "350px",
              marginLeft: "-2px",
            }}
          >
            
          </div>
        </Box>
      </Flex> */}
      {/* <Flex alignItems="center">
        <Box
          mx={5}
          py={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            Status:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <div
            style={{
              width: "350px",
            }}
          >
            <Select
              defaultValue={{ label: "All", value: null }}
              value={selectedStatus}
              onChange={setSelectedStatus}
              options={optionStatus}
            />
          </div>
        </Box>
      </Flex> */}
      <div style={{ margin: 12 }}>
        <Row>
          <Col sm={4}>
            <Input
              // style={{height:48}}
              name="keyword"
              placeholder="Enter the keyword"
              value={state.keyword}
              defaultValue={state.keyword}
              onChange={inputChange}
              type="text"
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col sm={2}>
            {optionAggregators && (
              <Select
                defaultValue={{ label: "All Aggregator", value: null }}
                value={selectedAggregator}
                onChange={setSelectedAggregator}
                options={optionAggregators}
              />
            )}
          </Col>
          <Col sm={2}>
            <Select
              defaultValue={{ label: "All Status", value: null }}
              value={selectedStatus}
              onChange={setSelectedStatus}
              options={optionStatus}
            />
          </Col>
          <Col sm={2}>
            <Button style={{ height: 38, lineHeight: "38px" }} onClick={search}>
              Search
            </Button>
          </Col>
          <Col sm={2}>
          <Button className="btn-yellow" style={{ height: 38, lineHeight: "38px" }} onClick={openModalAddMerchantHandle}>
              Add New Merchant
            </Button>
          </Col>
        </Row>

       
      </div>
      {optionAggregatorsAddMerchant?.length > 0 && openModalAddNewMerchant && (
          <ModalAddMerchant
            open={openModalAddNewMerchant}
            onClose={closeModalAddMerchantHandle}
            onSubmit = {onSubmitAddNewMerchantHandle}
            aggregators={optionAggregatorsAddMerchant}
          ></ModalAddMerchant>
        )}
      <div
        style={{
          marginLeft: "130px",
        }}
        className="error-text"
      >
        {state.valueError}
      </div>
      <Box mx={[2, 4]}>
        <Divider />
      </Box>
      <MerchantManagementTable
        key="MerchantManagementTable"
        merchantId={state.merchantId}
      />
    </div>
  );
};

export default MerchantManagement;
