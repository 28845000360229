export const Configuration = {
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  },
  apiGateway: {
    REGION: process.env.REACT_APP_API_GATEWAY_AWS_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_ENDPOINT
  },
  oauth: {
    FEDERATED_LOGIN_DOMAIN: process.env.REACT_APP_FEDERATED_LOGIN_DOMAIN,
    REDIRECT_SIGN_IN: process.env.REACT_APP_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT: process.env.REACT_APP_REDIRECT_SIGN_OUT
  },
  webClient: {
    SESSION_TIMEOUT: process.env.REACT_APP_SESSION_TIMEOUT
  },
  termAndCondition: {
    VERSION: process.env.REACT_APP_TERM_AND_CONDITION_VERSION
  }
}