import React, { memo, useState, useEffect} from "react";
import { Well, Media, Grid, Col, Form } from "react-bootstrap";
import { ConfirmModal } from "../../../common/ConfirmModal";

import iconWarningHelp from "assets/images/warning-help.svg";
import Styled from "styled-components";
import PropTypes from "prop-types";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import ApiService from "services/apiService";
const StyledCol = Styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

const SecondStyledCol = Styled(Col)`
  float: right;
  padding-right: 0px;
`;

const StyledGrid = Styled(Grid)`
  padding-top: 30px;
  padding-bottom: 32px;
`;

const StyledRefundAmountInfo = Styled.div`
  font-size: 20px
`;

const SpanValue = Styled.span`
  padding-left: 10px;
  white-space: nowrap;
`;

const StyledWell = Styled(Well)`
    margin-left: -40px;
    margin-right: -40px;
    height: 90px;
    padding-top: 22px;
    padding-left: 40px;
    padding-right: 40px;
    border-style: none;
    box-shadow: none;
`;
const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  merchantDetail:PropTypes.object,
};


const merchantCategoryOptionsValueRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}) => (
  <div className={`item-renderer ${disabled && "disabled"}`}>
    <input
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <span>
      {option.label} ({option.value})
    </span>
  </div>
);

const ModalEditMerchant = (props) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [optionCategories, setOptionCategories] = useState([]);
  const [merchantDetailUpdate, setMerchantDetailUpdate] = useState({})
  const SELECTED_CATEGORY_MAXIMUM = 5;

  function toSelectedCategoryCodes() {
    const _selectedCategoryCodes = [];
    selectedCategories.forEach((category) => {
      _selectedCategoryCodes.push(category.value);
    });
    return _selectedCategoryCodes;
  }

  const merchantCategorySelectedValueRenderer = (selected, options) => {
    const selectedNum = selected.length;
    if (selectedNum >= SELECTED_CATEGORY_MAXIMUM) {
      options.forEach((category) => {
        if (selected.findIndex((item) => item.value === category.value) < 0) {
          // Not in [selected]
          category.disabled = true;
        }
      });
      return selected.map(({ label }) => label).join(" | ");
    } else {
      options.forEach((category) => {
        category.disabled = false;
      });
      if (selectedNum > 0) {
        return selected.map(({ label }) => label).join(" | ");
      }
      return "Please select the maximum of 5 categories...";
    }
  };

  const { open, onClose, onSubmit, merchantDetail} = props;
  const buttons = [
   
    {
        name: 'Save',
        classYellow: true,
        click:(e)=> {
          var request = {
            ...merchantDetailUpdate,
            categoryCodes: toSelectedCategoryCodes()
          }
          onSubmit(request)
        }
      },
      {
        name: 'Cancel',
        classYellow: false,
        // click:(e)},
        click:(e)=> onClose(e)
      },
  ];
  /**
   *
   * @param {*} fullCategoryList
   * @param {*} selectedCategoryList
   */
   function mapCategories(fullCategoryList, selectedCategoryList) {
    const _optionCategories = [];
    const _selectedCategories = [];
    fullCategoryList.forEach((category) => {
      _optionCategories.push({ label: category.name, value: category.code });
    });
    selectedCategoryList.forEach((category) => {
      _selectedCategories.push({ label: category.name, value: category.code });
    });
    return { _optionCategories, _selectedCategories };
  }

  const inputChange = (e) => {
    const { name, value } = e.target;
    setMerchantDetailUpdate({
      ...merchantDetailUpdate,
      [name]: value,
    });
  };


  const loadCategoryList = (selectedCategoryList, callBack = null) => {
    // make header request
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // make a http call to fetch data
    ApiService.getCategoryList(initHeader)
      .then((resData) => {
        const { _optionCategories, _selectedCategories } = mapCategories(
          resData,
          selectedCategoryList
        );
        setOptionCategories(_optionCategories);
        setSelectedCategories(_selectedCategories);
      })
      .catch((err) => {
        console.debug(err);
        // handle log
      })
      .finally((fn) => {
        if (callBack) {
          callBack();
        }
      });
  };  

  useEffect(() => {
    if(open && Object.keys(merchantDetail).length > 0 && optionCategories.length === 0){
      loadCategoryList(merchantDetail.categories);
    }
    if(!open) {
      setOptionCategories([])
    }
  }, [open, merchantDetail]);

  return (
    <ConfirmModal
      show={open}
      onHide={onClose}
      onConfirmYes={onSubmit}
      ctitle="Edit Merchant details"
      mode="custom"
      buttons={buttons}
      animation
    >
      <StyledRefundAmountInfo>Edit Merchant details</StyledRefundAmountInfo>
      <StyledGrid fluid={true}>
        <Form>
          <FormGroup row>
            <Label for="businessName" sm={4}>
              Business Name
            </Label>
            <Col sm={8}>
              <Input
                id="businessName"
                name="fullName"
                defaultValue={merchantDetail.fullName}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="type" sm={4}>
              Type
            </Label>
            <Col sm={8}>
              <Input
                id="type"
                name="type"
                defaultValue={merchantDetail.type}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="merchantTradingName" sm={4}>
              Merchant Trading Name
            </Label>
            <Col sm={8}>
              <Input
                id="merchantTradingName"
                name="displayName"
                defaultValue={merchantDetail.displayName}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="websiteUrl" sm={4}>
              Website URL
            </Label>
            <Col sm={8}>
              <Input
                id="websiteUrl"
                name="websiteURL"
                defaultValue={merchantDetail.websiteURL}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>
              Email
            </Label>
            <Col sm={8}>
              <Input
                id="email"
                name="email"
                defaultValue={merchantDetail.email}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="vatNumber" sm={4}>
              Vat number
            </Label>
            <Col sm={8}>
              <Input
                id="vatNumber"
                name="vatNumber"
                defaultValue={merchantDetail.vatNumber}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="registeredNumber" sm={4}>
              Registered Number
            </Label>
            <Col sm={8}>
              <Input
                id="registeredNumber"
                name="registrationNumber"
                defaultValue={merchantDetail.registrationNumber}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="physicalAddress" sm={4}>
              Physical Address
            </Label>
            <Col sm={8}>
              <Input
                id="physicalAddress"
                name="physicalAddress"
                defaultValue={merchantDetail.physicalAddress}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="commissionRate" sm={4}>
            Commission Rate (Exclusive VAT)
            </Label>
            <Col sm={8}>
              <Input
                id="commissionRate"
                name="commissionRate"
                readOnly
                defaultValue={merchantDetail.commissionRate}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="transactionFee" sm={4}>
            Transaction Fee (Exclusive VAT)
            </Label>
            <Col sm={8}>
              <Input
                id="transactionFee"
                name="transactionFee"
                readOnly
                defaultValue={merchantDetail.transactionFee}
              />
            </Col>
          </FormGroup>

          <FormGroup row style={{ paddingTop: "5px" }}>
            <Col sm={4} style={{ paddingTop: "5px" }}>
              Category:
            </Col>
            <Col sm={8}>
              <MultiSelect
                options={optionCategories}
                value={selectedCategories}
                onChange={setSelectedCategories}
                valueRenderer={merchantCategorySelectedValueRenderer}
                ItemRenderer={merchantCategoryOptionsValueRenderer}
                hasSelectAll={false}
              />
            </Col>
          </FormGroup>
        </Form>
      </StyledGrid>
      <StyledWell>
        <Media>
          <Media.Left className="hidden-xs">
            <img src={iconWarningHelp} alt="" />
          </Media.Left>
          <Media.Body align="middle">
            <div className="text">
              When you take this action, any changes will only be temporarily
              recorded until you Approve
            </div>
          </Media.Body>
        </Media>
      </StyledWell>
    </ConfirmModal>
  );
};
ModalEditMerchant.propTypes = propTypes;
export default memo(ModalEditMerchant);