import React, { memo, useState, useEffect } from "react";
import { Well, Media, Grid, Col, Form } from "react-bootstrap";
import { ConfirmModal } from "../../../common/ConfirmModal";

import iconWarningHelp from "assets/images/warning-help.svg";
import Styled from "styled-components";
import PropTypes from "prop-types";
import { FormGroup, FormText, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import ApiService from "services/apiService";
const StyledGrid = Styled(Grid)`
  padding-top: 30px;
  padding-bottom: 32px;
`;

const StyledRefundAmountInfo = Styled.div`
  font-size: 20px
`;

const StyledWell = Styled(Well)`
    margin-left: -40px;
    margin-right: -40px;
    height: 90px;
    padding-top: 22px;
    padding-left: 40px;
    padding-right: 40px;
    border-style: none;
    box-shadow: none;
`;
const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  merchantDetail: PropTypes.object,
  aggregators: PropTypes.array,
  categories: PropTypes.array,
};
const SELECTED_CATEGORY_MAXIMUM = 5;
const merchantCategorySelectedValueRenderer = (selected, options) => {
  const selectedNum = selected.length;
  if (selectedNum >= SELECTED_CATEGORY_MAXIMUM) {
    options.forEach((category) => {
      if (selected.findIndex((item) => item.value === category.value) < 0) {
        // Not in [selected]
        category.disabled = true;
      }
    });
    return selected.map(({ label }) => label).join(" | ");
  } else {
    options.forEach((category) => {
      category.disabled = false;
    });
    if (selectedNum > 0) {
      return selected.map(({ label }) => label).join(" | ");
    }
    return "Please select the maximum of 5 categories...";
  }
};
const merchantCategoryOptionsValueRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}) => (
  <div className={`item-renderer ${disabled && "disabled"}`}>
    <input
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <span>
      {option.label} ({option.value})
    </span>
  </div>
);

const ModalAddMerchant = (props) => {
  //state
  const initState = {
    valueError: "",
  };
  const [state, setState] = useState(initState);
  const [aggregatorsList, setAggregatorsList] = useState([]);
  const [merchantCategory, setMerchantCategory] = useState([]);
  const [merchantCreateData, setMerchantCreateData] = useState({});
  const [selectedMerchantCategory, setSelectedMerchantCategory] = useState([]);
  const [aggregatorSelected, setAggregatorSelected] = useState("");
  const [typeMerchantSelected, setTypeMerchantSelected] = useState(null);

  const { open, onClose, onSubmit, aggregators } = props;

  const buttons = [
    {
      name: "Save",
      classYellow: true,
      click: (e) => {
        var request = {
          ...merchantCreateData,
          categoryCodes: toSelectedCategoryCodes(),
        };
        if (!validate(request)) {
          return;
        }
        onSubmit(request);
      },
    },
    {
      name: "Cancel",
      classYellow: false,
      // click:(e)},
      click: (e) => onClose(e),
    },
  ];

  const validate = (request) => {
    console.log(request);
    if (request == null) {
      setState({
        ...state,
        valueError: "Please input missing field",
      });
      return false;
    }
    if (request.aggregatorId == null) {
      setState({
        ...state,
        valueError: "Please select aggregator",
      });
      return false;
    }
    if (request.fullName == null || request.fullName === "") {
      setState({
        ...state,
        valueError: "Please input Business Name or Merchant Trading Name",
      });
      return false;
    }
    if (request.displayName == null || request.displayName === "") {
      setState({
        ...state,
        valueError: "Please input Business Name or Merchant Trading Name",
      });
      return false;
    }
    if (request.type == null || request.type === "") {
      setState({
        ...state,
        valueError: "Please input type",
      });
      return false;
    }
    if (
      request.type != "ECOMMERCE" &&
      request.type != "BOTH" &&
      request.type != "OUTLET"
    ) {
      setState({
        ...state,
        valueError: "Field type must only ECOMMERCE or BOTH or OUTLET",
      });
      return false;
    }

    if (request.type === "ECOMMERCE" || request.type === "BOTH") {
      if (request.websiteURL == null || request.websiteURL === "") {
        setState({
          ...state,
          valueError: "Please input website URL when type is ECOMMERCE or BOTH",
        });
        return false;
      }
    }
    setState({
      ...state,
      valueError: "",
    });
    return true;
  };

  const inputChange = (e) => {
    const { name, value } = e.target;
    setMerchantCreateData({
      ...merchantCreateData,
      [name]: value,
    });
  };

  useEffect(() => {
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    ApiService.getCategoryList(initHeader)
      .then((resData) => {
        if (resData) {
          setMerchantCategory(
            resData.map((vv, kk) => {
              return {
                label: vv.name,
                value: vv.code,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.debug(err);
        // handle log
      })
      .finally((fn) => {});
  }, []);

  useEffect(() => {
    setAggregatorsList(aggregators);
  }, [aggregators, merchantCreateData]);

  const handleSelectTypeMerchant = (valueSelect) => {
    setTypeMerchantSelected(valueSelect);
    setMerchantCreateData({
      ...merchantCreateData,
      type: valueSelect.value,
    });
  };

  const handleSelectAggregator = (newValue) => {
    setAggregatorSelected(newValue);
    setMerchantCreateData({
      ...merchantCreateData,
      aggregatorId: newValue.value,
    });
  };
  function toSelectedCategoryCodes() {
    const _selectedCategoryCodes = [];
    selectedMerchantCategory.forEach((category) => {
      _selectedCategoryCodes.push(category.value);
    });
    return _selectedCategoryCodes;
  }
  return (
    <ConfirmModal
      show={open}
      onHide={onClose}
      onConfirmYes={onSubmit}
      ctitle="Edit Merchant details"
      mode="custom"
      buttons={buttons}
      animation
    >
      <StyledRefundAmountInfo>
        Create New Merchant Request
      </StyledRefundAmountInfo>
      <StyledGrid fluid={true}>
        <Form>
          <FormGroup row style={{ paddingTop: "5px" }}>
            <Col sm={4} style={{ paddingTop: "5px" }}>
              Aggregator
            </Col>
            <Col sm={8}>
              <Select
                defaultValue={{ label: "Select Aggregator", value: null }}
                value={aggregatorSelected}
                onChange={handleSelectAggregator}
                // value={{ label:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status,value:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status}}
                options={aggregatorsList}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fullName" sm={4}>
              Business Name (*)
            </Label>
            <Col sm={8}>
              <Input
                id="fullName"
                name="fullName"
                // defaultValue={merchantDetail?.fullName}
                onChange={inputChange}
                placeholder="Business Name"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="type" sm={4}>
              Type (*)
            </Label>
            <Col sm={8}>
              <Select
                defaultValue={{ label: "Select Aggregator", value: null }}
                value={typeMerchantSelected}
                onChange={handleSelectTypeMerchant}
                // value={{ label:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status,value:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status}}
                options={[
                  {
                    label: "ECOMMERCE",
                    value: "ECOMMERCE",
                  },
                  {
                    label: "OUTLET",
                    value: "OUTLET",
                  },
                  {
                    label: "BOTH",
                    value: "BOTH",
                  },
                ]}
              />
              {/* <Input
                id="type"
                name="type"
                // defaultValue={merchantDetail?.type}
                onChange={inputChange}
                placeholder="ECOMMERCE"
              /> */}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="displayName" sm={4}>
              Merchant Trading Name (*)
            </Label>
            <Col sm={8}>
              <Input
                id="displayName"
                name="displayName"
                placeholder="Merchant Trading Name"
                // defaultValue={merchantDetail?.displayName}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="websiteUrl" sm={4}>
              Website URL
            </Label>
            <Col sm={8}>
              <Input
                id="websiteUrl"
                name="websiteURL"
                placeholder="Website URL"
                // defaultValue={merchantDetail?.websiteURL}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>
              Email
            </Label>
            <Col sm={8}>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                // defaultValue={merchantDetail?.email}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="vatNumber" sm={4}>
              Vat number
            </Label>
            <Col sm={8}>
              <Input
                id="vatNumber"
                name="vatNumber"
                placeholder="Vat number"
                // defaultValue={merchantDetail?.vatNumber}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="registeredNumber" sm={4}>
              Registered Number
            </Label>
            <Col sm={8}>
              <Input
                id="registeredNumber"
                name="registrationNumber"
                // defaultValue={merchantDetail?.registrationNumber}
                onChange={inputChange}
                placeholder="Registered Number"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="physicalAddress" sm={4}>
              Physical Address
            </Label>
            <Col sm={8}>
              <Input
                id="physicalAddress"
                name="physicalAddress"
                onChange={inputChange}
                placeholder="Physical Address"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="commissionRate" sm={4}>
              Commission Rate (Exclusive VAT)
            </Label>
            <Col sm={8}>
              <Input
                id="commissionRate"
                name="commissionRate"
                placeholder="Commission Rate"
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="transactionFee" sm={4}>
              Transaction Fee (Exclusive VAT)
            </Label>
            <Col sm={8}>
              <Input
                id="transactionFee"
                name="transactionFee"
                placeholder="Transaction Fee"
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row style={{ paddingTop: "5px" }}>
            <Col sm={4} style={{ paddingTop: "5px" }}>
              Category
            </Col>
            <Col sm={8}>
              <MultiSelect
                options={merchantCategory}
                value={selectedMerchantCategory}
                onChange={setSelectedMerchantCategory}
                valueRenderer={merchantCategorySelectedValueRenderer}
                ItemRenderer={merchantCategoryOptionsValueRenderer}
                hasSelectAll={false}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bankAccountNumber" sm={4}>
              Bank Account Number
            </Label>
            <Col sm={8}>
              <Input
                id="bankAccountNumber"
                name="bankAccountNumber"
                onChange={inputChange}
                placeholder="Bank Account Number"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bankName" sm={4}>
              Bank Name
            </Label>
            <Col sm={8}>
              <Input
                id="bankName"
                name="bankName"
                onChange={inputChange}
                placeholder="Bank Name"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="brandCode" sm={4}>
              Bank Brand Code
            </Label>
            <Col sm={8}>
              <Input
                id="brandCode"
                name="brandCode"
                // defaultValue={merchantDetail?.brandCode}
                onChange={inputChange}
                placeholder="Bank Brand Code"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="authorizedContactName" sm={4}>
              Authorized Contact Name
            </Label>
            <Col sm={8}>
              <Input
                id="authorizedContactName"
                name="authorizedContactName"
                // defaultValue={merchantDetail?.authorizedContactName}
                onChange={inputChange}
                placeholder="Authorized Contact Name"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="authorizedContactPhone" sm={4}>
              Authorized Contact Phone
            </Label>
            <Col sm={8}>
              <Input
                id="authorizedContactPhone"
                name="authorizedContactPhone"
                // defaultValue={merchantDetail?.authorizedContactPhone}
                onChange={inputChange}
                placeholder="Authorized Contact Phone"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="authorizedContactEmail" sm={4}>
              Authorized Contact Email
            </Label>
            <Col sm={8}>
              <Input
                id="authorizedContactEmail"
                name="authorizedContactEmail"
                // defaultValue={merchantDetail?.authorizedContactEmail}
                onChange={inputChange}
                placeholder="Authorized Contact Email"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="authorizedFinancialContactName" sm={4}>
              Authorized Financial Contact Name
            </Label>
            <Col sm={8}>
              <Input
                id="authorizedFinancialContactName"
                name="authorizedFinancialContactName"
                // defaultValue={merchantDetail?.authorizedFinancialContactName}
                onChange={inputChange}
                placeholder="Authorized Financial Contact Name"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="authorizedFinancialContactPhone" sm={4}>
              Authorized Financial Contact Phone
            </Label>
            <Col sm={8}>
              <Input
                id="authorizedFinancialContactPhone"
                name="authorizedFinancialContactPhone"
                // defaultValue={
                //   merchantDetail?.authorizedFinancialContactPhone
                // }
                onChange={inputChange}
                placeholder="Authorized Financial Contact Phone"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="authorizedFinancialContactEmail" sm={4}>
              Authorized Financial Contact Email
            </Label>
            <Col sm={8}>
              <Input
                id="authorizedFinancialContactEmail"
                name="authorizedFinancialContactEmail"
                // defaultValue={
                //   merchantDetail?.authorizedFinancialContactEmail
                // }
                onChange={inputChange}
                placeholder="Authorized Financial Contact Email"
              />
            </Col>
          </FormGroup>
        </Form>
      </StyledGrid>
      <StyledWell>
        <Media>
          <Media.Left className="hidden-xs">
            <img src={iconWarningHelp} alt="" />
          </Media.Left>
          <Media.Body align="middle">
            <div className="text">
              Once you take this action, any changes will be logged only
              temporarily until this request is Approved
            </div>
          </Media.Body>
        </Media>
      </StyledWell>
      <div className="error-text">{state.valueError}</div>
    </ConfirmModal>
  );
};
ModalAddMerchant.propTypes = propTypes;
export default memo(ModalAddMerchant);
