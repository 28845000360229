import React from 'react';
import PropTypes from 'prop-types';
import { Button, utils } from 'react-bootstrap';

const propTypes = {
    bsStyle: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    loading: PropTypes.bool,
};
utils.bootstrapUtils.addStyle(Button, 'yellow');
function ButtonLoader({
    bsStyle = 'default',
    children = null,
    disabled = false,
    // icon = null,
    loading = false,
    ...rest
}) {
    const buttonDisabled = disabled || loading;
    return (
        <Button type="button" bsStyle={bsStyle} data-style="zoom-in" disabled={buttonDisabled} {...rest}>
            {/* {loading && renderIcon()}  */}
            <span>{children}</span>
        </Button>);
}

ButtonLoader.propTypes = propTypes;

export default ButtonLoader;
