import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import iconPurchaseHistory from 'assets/images/purchase-history.svg'
import { Flex, Box } from 'rebass/styled-components';
import { Divider } from '@material-ui/core';
import Button from 'react-bootstrap/lib/Button';
import { Dropdown, MenuItem } from 'react-bootstrap';
import Spinner from 'components/common/Spinner';
import moment from 'moment';
import { useUserContext } from 'context/UserContext';
import ApiService from 'services/apiService';
import _ from 'lodash';
import { useDashboardContext } from './Dashboard';
import { displayAmount, displayVoucherCode } from 'utils';
import VoucherStatus from './VoucherStatus';

const Header = ({ headers }) => {
  return (
    <Flex fontSize={['14px', '16px']} py={3} className="font-bold" >
      {headers.map((header, index) => (
        header.isVisible ?
          <Box key={`purchase-header-${index}`} width={header.width}>
            {header.Header}
          </Box> : null
      ))}
    </Flex>
  )
};
const BoxContent = ({ children, ...rest }) => (
  <Box {...rest}>
    <Box px={[2, 3, 4]}>{children}</Box>
  </Box>
);
const PurchaseHistoryTable = ({ storeId }) => {
  // init one time
  const defaultLimitHistories = 10;
  const [page, setPage] = useState(0);
  const [columns] = useState(
    useMemo(() => [
      {
        Header: 'Store ID',
        accessor: 'storeId',
        isVisible: false
      },
      {
        Header: 'Store',
        accessor: 'storeName',
        width: '20%'
      },
      {
        Header: 'Date',
        accessor: 'date',
        width: '20%',
        Cell: ({ value }) => {
          if (value) {
            return moment(new Date(value)).format("DD MMM YYYY, HH:mm")
          }
          return value;
        },
        filter: 'between'
      },
      {
        Header: 'Reference ID',
        accessor: 'referenceNumber',
        width: '20%'
      },
      {
        Header: 'Voucher number',
        accessor: 'voucherNumber',
        width: '20%',
        Cell: ({ value }) => displayVoucherCode(value)
      },
      {
        Header: 'Voucher amount',
        accessor: 'amount',
        width: '20%',
        Cell: ({ value }) => displayAmount(value)
      },
      {
        Header: 'Voucher status',
        accessor: 'status',
        width: '20%',
        Cell: ({ value }) => VoucherStatus[value] ? VoucherStatus[value] : value
      },
    ], [])
  );
  const [data, setHistories] = useState([]);
  const [isShowLoadMore, setShowMore] = useState(true);
  const [dashboardState, setDashboardState] = useDashboardContext();

  // Init a instance of table by usetable
  const instanceTable = useTable({
    columns, data, initialState: {
      // default sort
      sortBy: [
        {
          id: 'date',
          desc: true
        }
      ],
      hiddenColumns: [
        'storeId'
      ]
    }
  }, useFilters, useSortBy);
  const { headers, rows, prepareRow, setFilter, setAllFilters } = instanceTable;
  const loadMoreData = (callBack = null, curdata = data, curPage = page) => {
    // make header request
    const initHeader = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    // make a http call to fetch data
    ApiService.getRedeemHistory(initHeader, curPage, defaultLimitHistories)
      .then(resData => {
        if (resData) {
          if (resData.length === 0) {
            setShowMore(false);
          } else {
            const tempHistory = [...curdata, ...resData];
            const histories = _.uniq(tempHistory, 'voucherNumber')
            setHistories(histories);
            setPage(curPage + 1);
          }
        }
      }).catch(err => {
        // handle log
      }).finally(fn => {
        if (callBack) {
          callBack();
        }
      })
  };

  useEffect(() => {
    if (dashboardState.forceReload) {
      loadMoreData(null, [], 0);
    } else {
      loadMoreData();
    }
    setDashboardState({
      ...dashboardState,
      reloadAction: '',
    })
      
  }, [dashboardState.forceReload, dashboardState.reloadAction]);

  useEffect(() => {
    // keep filter on data changed
    if (storeId) {
      if (storeId === -1) {
        // Clean filter
        setAllFilters([]);
      } else {
        // add filter
        setFilter('storeId', storeId);
      }
    }
  }, [storeId, data, setAllFilters, setFilter]);
  return (
    <Fragment>
      <BoxContent >
        <Header headers={headers} />
      </BoxContent>
      <BoxContent>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return <div key={`row-${row.values.voucherNumber}`}>
            <Divider />
            <Flex fontSize={['14px', '16px']} py={3}>
              {row.cells.map((cell, cellIndex) => {
                return <Box width={cell.column.width} key={`Cell-${rowIndex}-${cellIndex}`}>
                  {cell.render('Cell')}
                </Box>
              })}
            </Flex>
          </div>
        })}
      </BoxContent>
      <Box mx={[2, 4]} className="hidden-xs">
        <Divider />
      </Box>
      <EndingSection isShowLoadMore={isShowLoadMore} loadMoreData={loadMoreData} />
    </Fragment>
  )
}

const EndingSection = ({ isShowLoadMore, loadMoreData }) => {
  const [loading, setLoading] = useState(false);
  const loadMoreClick = () => {
    setLoading(true);
    if (loadMoreData) {
      loadMoreData(afterLoadMore);
    }
  }
  const afterLoadMore = () => {
    setLoading(false);
  }
  return (
    <Flex p={3} justifyContent="center">
      {loading ? <Spinner /> : isShowLoadMore ? <Button onClick={loadMoreClick}>Load more transactions</Button> : 'End of history.'}
    </Flex>
  );
};

const PurchaseHistory = () => {
  const [storeName, setStoreName] = useState('Store: All Stores')
  const { userObj } = useUserContext();
  const title = <span className="font-bold"> <img src={iconPurchaseHistory} alt="" /> {'Voucher history'}</span>;
  const [stores, setStores] = useState([
    {

      id: -1,
      name: 'All Stores'
    }
  ]);
  const [storeId, setStoreId] = useState(-1);
  const selectStore = (eventKey, event) => {
    setStoreId(eventKey);
    const store = _.find(stores, ['id', eventKey]);
    setStoreName(store.name);
  }

  useEffect(() => {
    // load store drop down
    if (userObj.merchantId && !Boolean(userObj.storeId)) {
      const initHeader = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      ApiService.getStores(initHeader)
        .then(res => {
          setStores([...stores, ...res]);
        }).catch(err => {
          // handle error
          console.debug(err.response);
        })
      if (userObj.storeId) {
        setStoreId(userObj.storeId);
      }
    }
  }, []);

  return (
    <div className="box" style={{ overflow: 'visible', zIndex: 0 }} >
      <Flex alignItems='center'>
        <Box mx={5} py={2}>
          {typeof title === 'string' ? <h3>{title}</h3> : title}
        </Box>
        <Box mx='auto'/>
        {userObj.merchantId && !Boolean(userObj.storeId) ? <Box mx={[0, 4]} py={2}>
          <Dropdown key= 'Dropdown-Store' id='Dropdown-Store' className="btn-group-lg" onSelect={selectStore} pullRight>
            <Dropdown.Toggle>
              {storeName}
          </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors" style={{ maxHeight: '300px', overflowY: 'auto', position: 'absolute', zIndex: '20 !important'  }}>
              {stores.map((store, index) => {
                return [<MenuItem key={`menu-item-${store.id}`} eventKey={store.id}>{store.name}</MenuItem>, <MenuItem divider key={`menu-item-divider-${store.id}`} />];
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Box> : null}
      </Flex>
      <Box mx={[2, 4]}>
        <Divider />
      </Box>
      <PurchaseHistoryTable key='PurchaseHistoryTable' storeId={storeId} />
    </div>
  );
}
export default PurchaseHistory;
