import React, { useEffect, useState } from 'react';

import { useHistory } from "react-router-dom";
import { useAppContext } from 'context/AppContext';
import iconLock from '../../assets/images/lock_black_background.svg';
import { ConfirmModal } from '../common/ConfirmModal';
import Actions  from 'actions/index';


const LogoutConfirm = () => {
    const [ appState, setAppState ] = useAppContext();
    const defautCountDown = 30;
    const [intervalId, setIntervalId] = useState(null);
    const [countDown, setCountDown] = useState(defautCountDown);
    let history = useHistory();
    useEffect(() => {
        if (!appState.logoutConfirmModal.hidden && countDown === defautCountDown) {
            let count = 0;
            const intervalIdTmp = setInterval(() => {
                count++;
                // if time over then auto confirm to logout
                if (count === defautCountDown) {
                    clearInterval(intervalIdTmp);
                    confirmLogout();
                } else {
                    setCountDown(defautCountDown - count);
                }
            }, 1000);
            setIntervalId(intervalIdTmp);
        } else {
            clearInterval(intervalId);
            setCountDown(defautCountDown);
        }
    }, [appState.logoutConfirmModal.hidden]);
    const hideModal = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setAppState({
            type: Actions.logoutConfirmModal.type,
            hidden: true
        });
    };

    const confirmLogout = () => {
        hideModal();
        history.push('/logout');
    };
    const title = 'You are about to logout of MoreTyme Merchant';
    const buttons = [
        {
            name: 'Logout',
            classYellow: false,
            click: confirmLogout,
        },
        {
            name: 'Stay',
            classYellow: true,
            click: hideModal,
        },
    ];
    return (
        <ConfirmModal
            show={!appState.logoutConfirmModal.hidden}
            onHide={hideModal}
            title={title}
            mode="custom"
            animation
            onConfirmYes={confirmLogout}
            onConfirmNo={hideModal}
            icon={iconLock}
            buttons={buttons}
        >
            <p>Are you sure you want to log out? <br /> You will be automatically logged out in <strong>{countDown}</strong> seconds</p>
        </ConfirmModal>
    );
}

export default LogoutConfirm;
