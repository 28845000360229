import React, { useState, useEffect } from 'react';
import BaseBox from 'components/common/BaseBox';
import { Row, Col, Button, utils } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import TextArea from 'react-textarea-autosize';
import { Flex } from 'rebass/styled-components';
import {
  colorMapping,
  displayVoucherCode,
  displayAmount,
  convertVoucherToNumber,
  convertAmountToNumber,
  validateAmountNumber,
  validateVoucherNumber,
} from 'utils';
import Styled from 'styled-components';
import Actions from 'actions';
import { useAppContext, initialState } from 'context/AppContext';
import uuid from 'uuid';
import ApiService from 'services/apiService';
import { useRefundRequestContext } from './RefundRequest';
import VoucherRefundingError from './VoucherRefundingError';

utils.bootstrapUtils.addStyle(Button, 'yellow');

const StyledBaseBox = Styled(BaseBox)`
.box {
    border-top: 0px;
    border-radius: 0px 0px 3px 3px;
};
`;

const FIELD_NAME_VOUCHER = 'voucherNumber';
const FIELD_NAME_AMOUND = 'amount';
const FIELD_NAME_NOTE = 'note';
const ERROR_MSG_EMPTY_VOUCHER = 'Enter a voucher number before continuing';
const ERROR_MSG_INVALID_VOUCHER = 'That’s not a valid voucher number';
const ERROR_MSG_INVALID_AMOUNT = 'Enter an amount before continuing';
const ERROR_MSG_OVER_PURCHASE_AMOUNT = "You can't return more than the purchase amount of".concat(
  ' '
);
const MAX_LENGTH_REASON = 256;

const errorMapping = (errCode) => {
  switch (errCode) {
    case '12620041':
      return {
        errorMessage:
          'The full value of this voucher has already been refunded',
        errorField: FIELD_NAME_VOUCHER,
        errorCode: errCode,
      };
    case '12620042':
      return {
        errorMessage: 'The voucher is being refund',
        errorField: FIELD_NAME_VOUCHER,
        errorCode: errCode,
      };
    case '12620013':
      return {
        errorMessage: 'That’s not a valid voucher number',
        errorField: FIELD_NAME_VOUCHER,
        errorCode: errCode,
      };
    default:
      return null;
  }
};

const RefundRequestSubmission = () => {
  const [refundRequestState, setRefundRequestState] = useRefundRequestContext();
  const initState = {
    voucherNumber: '',
    voucherNumberError: false,
    voucherNumberErrorMsg: '',
    amount: '',
    amountError: false,
    amountErrorMsg: '',
    note: '',
  };
  const [state, setState] = useState(initState);
  const [charCount, setCharCount] = useState(MAX_LENGTH_REASON);
  const [appState, setAppState] = useAppContext();

  useEffect(() => {
    if (refundRequestState.isAccepted) {
      clearValue();
    }
  }, [refundRequestState.isAccepted]);

  const inputChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    if (name === FIELD_NAME_NOTE) {
      setCharCount(MAX_LENGTH_REASON - value.length);
    }
  };

  const submitRefundRequest = () => {
    if (validateVoucher(state.voucherNumber) && validateAmount(state.amount)) {
      validateRefundRequestInput();
    }
  };
  const validateVoucher = (value) => {
    const tempAmount = convertVoucherToNumber(value);
    if (validateVoucherNumber(tempAmount)) {
      if (state.voucherNumberError !== '') {
        setState({
          ...state,
          voucherNumberError: false,
          voucherNumberErrorMsg: '',
        });
      }
      return true;
    }
    setState({
      ...state,
      voucherNumberError: true,
      voucherNumberErrorMsg:
        value && value.trim() !== ''
          ? ERROR_MSG_INVALID_VOUCHER
          : ERROR_MSG_EMPTY_VOUCHER,
    });
    return false;
  };

  const validateAmount = (value) => {
    const tempAmount = convertAmountToNumber(value);
    if (tempAmount) {
      if (validateAmountNumber(tempAmount)) {
        setState({
          ...state,
          amountError: false,
          amountErrorMsg: '',
        });
        return true;
      }
    }
    setState({
      ...state,
      amountError: true,
      amountErrorMsg: ERROR_MSG_INVALID_AMOUNT,
    });
    return false;
  };

  const validateInput = (e) => {
    const { name, value } = e.target;
    if (FIELD_NAME_VOUCHER === name) {
      validateVoucher(value);
    } else if (FIELD_NAME_AMOUND === name) {
      validateAmount(value);
    }
  };

  const validateRefundRequestInput = async () => {
    try {
      setAppState({
        type: Actions.waitModal.type,
        hidden: false,
      });
      const request = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          id: uuid(),
          amount: convertAmountToNumber(state.amount),
          voucherNumber: convertAmountToNumber(state.voucherNumber),
          note: state.note,
        },
      };
      const responseData = await ApiService.requestRefundValidation(request);
      handleSuccess(responseData);
    } catch (error) {
      handleFailed(error);
    } finally {
      setAppState({
        type: Actions.waitModal.type,
        hidden: true,
      });
    }
  };

  const handleSuccess = (res) => {
    setTimeout(() => {
      showConfirm(res);
    }, 1000);
  };

  const handleFailed = (error) => {
    setTimeout(() => {
      if (error && error.response && error.response.data) {
        showError(error.response.data.errorCode);
      } else {
        showTechnicalError();
      }
    }, 1000);
  };

  const showConfirm = (res) => {
    if (convertAmountToNumber(state.amount) > res.approvedRefundAmount) {
      setState({
        ...state,
        amountError: true,
        amountErrorMsg: ERROR_MSG_OVER_PURCHASE_AMOUNT.concat(
          displayAmount(res.approvedRefundAmount)
        ),
      });
    } else {
      setRefundRequestState({
        voucherNumber: res.voucherNumber,
        referenceNumber: res.referenceNumber,
        purchaseDate: res.purchaseDate,
        purchaseAmount: res.purchaseAmount,
        approvedRefundAmount: res.approvedRefundAmount,
        note: state.note,
        isValidated: true,
      });
    }
  };

  const showTechnicalError = () => {
    setTimeout(() => {
      setAppState({
        type: Actions.technicalIssue.type,
        hidden: false,
      });
    }, 1000);
  };

  const showError = (errorCode) => {
    if (errorCode === '12620042') {
      return setRefundRequestState({
        ...refundRequestState,
        isVoucherRefunding: true,
      });
    }
    const error = errorMapping(errorCode);
    if (null === error) {
      return showTechnicalError();
    }
    if (FIELD_NAME_VOUCHER === error.errorField) {
      setState({
        ...state,
        voucherNumberError: true,
        voucherNumberErrorMsg: error.errorMessage,
      });
    } else if (FIELD_NAME_AMOUND === error.errorField) {
      setState({
        ...state,
        amountError: true,
        amountErrorMsg: error.errorMessage,
      });
    }
  };

  const clearValue = () => {
    setState(initState);
    setCharCount(MAX_LENGTH_REASON);
  };

  return (
    <>
      <StyledBaseBox>
        <form style={{ padding: '40px' }}>
          <Row>
            <Col xs={12} sm={6}>
              <div>
                <label className='text-bold' style={{ fontSize: '20px' }}>
                  Voucher number
                </label>
                <div className='input-group-amount'>
                  <NumberFormat
                    style={{
                      fontSize: '36px',
                      height: '72px',
                      borderColor: state.voucherNumberError
                        ? colorMapping('error')
                        : null,
                    }}
                    name={FIELD_NAME_VOUCHER}
                    allowNegative={false}
                    value={state.voucherNumber}
                    placeholder='0000 0000 0000 0'
                    onChange={inputChange}
                    maxLength={16}
                    onBlur={validateInput}
                    className='form-control'
                    format={displayVoucherCode}
                  />
                </div>
                <div className={state.voucherNumberError ? 'error-text' : ''}>
                  {state.voucherNumberErrorMsg}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div>
                <label className='text-bold' style={{ fontSize: '20px' }}>
                  Return amount
                </label>
                <div className='input-group-amount'>
                  <span className='input-group-amount__currency'>
                    <i
                      style={{
                        height: '100%',
                        verticalAlign: 'middle',
                        fontSize: '36px',
                        lineHeight: '70px',
                      }}
                    >
                      R{' '}
                    </i>
                  </span>
                  <NumberFormat
                    style={{
                      borderColor: state.amountError
                        ? colorMapping('error')
                        : null,
                    }}
                    name={FIELD_NAME_AMOUND}
                    fixedDecimalScale
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator=' '
                    value={state.amount === null ? '' : state.amount}
                    className='amount form-control unit-amount'
                    placeholder='0.00'
                    onBlur={validateInput}
                    onChange={inputChange}
                    autoComplete='off'
                  />
                </div>
                <div className={state.amountError ? 'error-text' : ''}>
                  {state.amountErrorMsg}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <div style={{ marginTop: '40px' }}>
                <label
                  className='label-input'
                  style={{ fontWeight: 300, fontSize: '16px' }}
                >
                  Return reason (optional)
                </label>
                <div className='input-group-amount'>
                  <TextArea
                    style={{ padding: '10px', fontSize: '18px' }}
                    name={FIELD_NAME_NOTE}
                    value={state.note}
                    placeholder='Enter the reason for the return here'
                    onChange={inputChange}
                    maxLength={MAX_LENGTH_REASON}
                    minRows={4}
                    maxRows={4}
                    rows={4}
                    className='form-control'
                  />
                </div>
                <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                  <span>{charCount}</span>{' '}
                  <span>{charCount > 1 ? 'characters' : 'character'}</span>{' '}
                  <span>remaining</span>
                </div>
              </div>
            </Col>
          </Row>
          <Flex p={3} justifyContent='center'>
            <Button
              style={{ width: '180px', marginTop: '35px', marginRight: '10px' }}
              block
              onClick={clearValue}
              type='button'
            >
              Clear value
            </Button>
            <Button
              style={{ width: '180px', marginTop: '35px', marginLeft: '10px' }}
              bsStyle='yellow'
              block
              onClick={submitRefundRequest}
              type='button'
            >
              Continue
            </Button>
          </Flex>
        </form>
      </StyledBaseBox>
      <VoucherRefundingError />
    </>
  );
};

export default RefundRequestSubmission;
