import React, { useEffect, useState } from 'react';
import BaseBox from 'components/common/BaseBox';
import { Row, Col, Button, utils } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useDashboardContext } from './Dashboard';
import { Flex } from 'rebass/styled-components';
import { colorMapping, displayAmount, displayVoucherCode } from 'utils';
import Styled from 'styled-components';

utils.bootstrapUtils.addStyle(Button, 'yellow');

const StyledBaseBox = Styled(BaseBox)`
.box {
    border-top: 0px;
    border-radius: 0px 0px 3px 3px;
};
`;

const PurchaseSubmission = () => {
  const [dashboardState, setDashboardState] = useDashboardContext();
  const initState = {
    voucherCode: '',
    voucherCodeError: false,
    voucherCodeErrorMsg: '',
    amount: null,
    amountError: false,
    amountErrorMsg: 'The minimum amount allowed is R50.00',
  };
  const [state, setState] = useState(initState);
  useEffect(() => {
    setState({
      ...state,
      voucherCode: dashboardState.voucherCode,
      amount: dashboardState.amount,
    });
  }, [dashboardState.amount, dashboardState.voucherCode]);

  const inputChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  const convertAmountToNumber = (value) => {
    if (value && typeof value === 'string') {
      return value.replaceAll(/ |R/g, '');
    }
    return 0;
  };
  const convertVoucherToNumber = (value) => {
    if (value && typeof value === 'string') {
      return value.replaceAll(' ', '');
    }
    return 0;
  };
  const submitVoucherCode = () => {
    if (validateVoucher(state.voucherCode) && validateAmount(state.amount)) {
      setDashboardState({
        isHiddenModal: false,
        title: 'Sure you want to redeem this payment voucher?',
        amount: convertAmountToNumber(state.amount),
        voucherCode: convertVoucherToNumber(state.voucherCode),
      });
    }
  };
  const validateVoucher = (value) => {
    const tempAmount = convertVoucherToNumber(value);
    if (/[0-9]{13}/.test(tempAmount)) {
      if (state.voucherCodeError !== '') {
        setState({
          ...state,
          voucherCodeError: false,
          voucherCodeErrorMsg: '',
        });
      }
      return true;
    }
    setState({
      ...state,
      voucherCodeError: true,
      voucherCodeErrorMsg: 'Please enter a valid voucher number',
    });
    return false;
  };
  const validateAmount = (value) => {
    const tempAmount = convertAmountToNumber(value);
    if (tempAmount) {
      if (parseFloat(tempAmount) >= 50) {
        setState({
          ...state,
          amountError: false,
          amountErrorMsg: 'The minimum amount allowed is R50.00',
        });
        return true;
      } else {
        setState({
          ...state,
          amountError: true,
          amountErrorMsg: 'The minimum amount allowed is R50.00',
        });
        return false;
      }
    }
    setState({
      ...state,
      amountError: true,
      amountErrorMsg: 'Please enter a valid amount',
    });
    return false;
  };
  const validateInput = (e) => {
    const { name, value } = e.target;
    if ('voucherCode' === name) {
      validateVoucher(value);
    } else if ('amount' === name) {
      validateAmount(value);
    }
  };
  const clearValue = () => {
    setState(initState);
  };
  return (
    <div>
      <StyledBaseBox>
        <form style={{ padding: '40px' }}>
          <Row>
            <Col xs={12} sm={6}>
              <div>
                <label className='text-bold' style={{ fontSize: '20px' }}>
                  Voucher number
                </label>
                <div className='input-group-amount'>
                  <NumberFormat
                    style={{
                      fontSize: '36px',
                      height: '72px',
                      borderColor: state.voucherCodeError
                        ? colorMapping('error')
                        : null,
                    }}
                    name='voucherCode'
                    allowNegative={false}
                    value={state.voucherCode}
                    placeholder='0000 0000 0000 0'
                    onChange={inputChange}
                    maxLength={16}
                    onBlur={validateInput}
                    className='form-control'
                    format={displayVoucherCode}
                  />
                </div>
                <div className={state.voucherCodeError ? 'error-text' : ''}>
                  {state.voucherCodeErrorMsg}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div>
                <label className='text-bold' style={{ fontSize: '20px' }}>
                  Enter an amount
                </label>
                <div className='input-group-amount'>
                  <span className='input-group-amount__currency'>
                    <i
                      style={{
                        height: '100%',
                        verticalAlign: 'middle',
                        fontSize: '36px',
                        lineHeight: '70px',
                      }}
                    >
                      R{' '}
                    </i>
                  </span>
                  <NumberFormat
                    style={{
                      borderColor: state.amountError
                        ? colorMapping('error')
                        : null,
                    }}
                    name='amount'
                    fixedDecimalScale
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator=' '
                    value={state.amount === null ? '' : state.amount}
                    className='amount form-control unit-amount'
                    placeholder='0.00'
                    onBlur={validateInput}
                    onChange={inputChange}
                    autoComplete='off'
                  />
                </div>
                <div className={state.amountError ? 'error-text' : ''}>
                  {state.amountErrorMsg}
                </div>
              </div>
            </Col>
          </Row>
          <Flex p={3} justifyContent='center'>
            <Button
              style={{ width: '180px', marginTop: '45px', marginRight: '10px' }}
              block
              onClick={clearValue}
              type='button'
            >
              Clear value
            </Button>
            <Button
              style={{ width: '180px', marginTop: '45px', marginLeft: '10px' }}
              bsStyle='yellow'
              block
              onClick={submitVoucherCode}
              type='button'
            >
              Continue
            </Button>
          </Flex>
        </form>
      </StyledBaseBox>
    </div>
  );
};

export default PurchaseSubmission;
