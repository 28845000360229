import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { ReactComponent as HomeIcon } from 'assets/images/logo-home.svg';
import { ReactComponent as LogoWhiteIcon } from 'assets/images/logo-tyme-digital-white.svg';
import { ReactComponent as LoggedIcon } from 'assets/images/logged-on.svg';
import { useAppContext } from 'context/AppContext';

import iconRectangleVertical from 'assets/images/rectangle-vertical.svg';
import iconMoretyme from 'assets/images/moretyme_icon.svg';
import Actions from 'actions/index';
import { useUserContext } from 'context/UserContext';

const Header = () => {
    const setAppState = useAppContext()[1];
    const [show, setShow] = useState(false);
    const location = useLocation();
    const { userObj } = useUserContext();
    const [adminPortalPage, setAdminPortalPage] = useState(false);
    useEffect(() => {
        const path = location.pathname;
        // hide on login and reset password page
        if ('/login' === path || '/reset-password' === path || '/forgot-password' === path || '/terms' === path || '/logout' === path || !userObj.isAuthenticated) {
            setShow(false);
        } else {
            setShow(true);
        }
        setAdminPortalPage(path.startsWith('/admin/merchant-approval/'))
    }, [location.pathname]);

    const handleLogoutClick = () => {
        setAppState({
            type: Actions.logoutConfirmModal.type,
            hidden: false
        });
    };

    const handleSelect = (selectedKey) => {
        if (selectedKey === 'navLogout') {
            handleLogoutClick();
        }
        // TODO: temporary deactive
        // if (selectedKey === 'navContactUs') {
        //     window.open(config.externalLinks.contactUs, '_blank');
        // }
    };
    const leftNavItems = () => {
        return <Nav className="hidden-xs">
            <NavItem>
                {/* hidden item */}
            </NavItem>
            <NavItem>
                <img src={iconRectangleVertical} alt="RectangleVertical" />
            </NavItem>
            <NavItem>
                <img style={{ margin: 0, position: 'absolute', top: '50%', msTransform: 'translateY(-50%)', transform: 'translateY(-50%)'}} src={iconMoretyme} alt="MoretymeText" />
            </NavItem>
        </Nav>
    }
    const rightNavItems = () => {
        return <Nav pullRight key={2} onSelect={key => handleSelect(key)}>
            {/* <NavItem eventKey="navContactUs">
                <ContactUsIcon className="contact-us-icon" />
                <span className="hidden-sm hidden-md hidden-lg">Contact us</span>
            </NavItem> */}
            <NavItem eventKey="navLogout">
                <LoggedIcon className="logged-icon" />
                <span className="hidden-sm hidden-md hidden-lg header-logout">Logout</span>
            </NavItem>
        </Nav>
    }
    if (show) {
        return (
            <Navbar collapseOnSelect fixedTop>
                <Navbar.Header>
                        { adminPortalPage 
                            ?
                            <Navbar.Brand style={{textAlign: 'left'}}>
                                <Link to='#' className="hidden-xs border-right">
                                    <HomeIcon className="home-icon" />
                                </Link>
                                <Link to='#' className="logo">
                                    <LogoWhiteIcon />
                                </Link>
                            </Navbar.Brand>
                            :
                            <Navbar.Brand style={{textAlign: 'left'}}>
                                <Link to="/" className="hidden-xs border-right">
                                    <HomeIcon className="home-icon" />
                                </Link>
                                <Link to="/" className="logo">
                                    <LogoWhiteIcon />
                                </Link>
                            </Navbar.Brand>
                        }
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                {leftNavItems()}
                {rightNavItems()}
                </Navbar.Collapse>
            </Navbar>
        )
    };
    return null;
}

export default Header;