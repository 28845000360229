import React, { useEffect, useState, Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import { formatDate } from "utils";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import ApiService from "services/apiService";
import Actions from "actions/index";
import { useAppContext } from "context/AppContext";
import { useParams } from "react-router";

const InvoiceSetting = () => {
  let { shortCode } = useParams();
  const [listInvoiceCustomSetting, setInvoiceCustomSetting] = useState([]);
  const reload = false;
  const setAppState = useAppContext()[1];
  const now = new Date();
  const formatDateTime = (dateTime) => {
    if (!dateTime) {
      return "";
    }
    return formatDate(dateTime, "DD/MM/YYYY ");
  };
  const firstDay = formatDateTime(
    new Date(now.getFullYear(), now.getMonth(), 1)
  );
  const lastDay = formatDateTime(
    new Date(now.getFullYear(), now.getMonth() + 1, 0)
  );

  const [state, setState] = useState({
    value: "",
    valueError: "",
  });

  const handleChange = (event) => {
    let index = listInvoiceCustomSetting.findIndex(
      (x) => "customField_" + x.merchantConfigId == event.target.id
    );
    let setting = listInvoiceCustomSetting[index];
    if (setting) {
      setting.value = event.target.value;
      setInvoiceCustomSetting(listInvoiceCustomSetting);
    }
  };

  const loadInvoiceCustomSetting = (shortCode, month, year) => {
    // make header request
    const initHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // make a http call to fetch data
    ApiService.getInvoiceCustomSetting(initHeader, shortCode, month, year)
      .then((resData) => {
        if (resData) {
          setInvoiceCustomSetting(resData);
        }
      })
      .catch((err) => {
        console.debug(err);
        // handle log
      })
      .finally((fn) => {});
  };

  const submitSaveInvoiceCustomField = () => {
    if (!validate()) return;

    setState({
      valueError: "",
    });
    const initData = {
      headers: {
        "Content-Type": "application/json",
      },
      body: listInvoiceCustomSetting.map((p) => {
        p.submitDate = now.getTime();
        return p;
      }),
    };

    const successMessage = "Submit successfully";
    ApiService.submitSaveInvoiceCustomField(initData)
      .then((resData) => {
        if (resData) {
          setAppState({
            type: Actions.toast.type,
            hidden: false,
            props: {
              type: "success",
              message: successMessage,
            },
          });
          loadData();
        }
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Internal server error";
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
        console.debug(err);
        // handle log
      })
      .finally((fn) => {});
  };

  const validate = () => {
    let status = true;
    listInvoiceCustomSetting.forEach((p) => {
      if (p.value == null || p.value === "") {
        setState({
          valueError: "Please enter a value to continue",
        });
        status = false;
      }
    });
    return status;
  };

  const MerchantPortalSetting = ({ setting }) => {
    return (
      <Row>
        <Col xs={4} md={2}>
          <div id={"merchantConFig_" + setting.merchantConfigId}>
            {setting.labelName}
          </div>
        </Col>
        <Col xs={4} md={2}>
          <input
            id={"customField_" + setting.merchantConfigId}
            name={"customField_" + setting.merchantConfigId}
            defaultValue={setting.value}
            type="text"
            onBlur={handleChange}
          />
        </Col>
      </Row>
    );
  };

  const loadData = () => {
    loadInvoiceCustomSetting(
      shortCode.toUpperCase(),
      now.getMonth() + 1,
      now.getFullYear()
    );
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="container" style={{ position: "relative" }}>
      <style>
        {`
            #body {
              margin-top: 75px;
              position: relative;
              display: flex;
            }

            .approve-action {
              clear: both;
              position: absolute;
              bottom: 0;
              right: 0;
            }
            
            button {
              margin-right: 5px
            }
            .information-header {
              padding: 10px 0px;
            }
            .mb-90 {
              margin-bottom: 90px;
            }
            .d-none {
              display: none important;
            }
            .tab-content > .active {
              background-color: white;
          }

        `}
      </style>
      <Container className="information-header" loadData={loadData}>
        <div>
          <h2>
            <b>{shortCode.toUpperCase()} purchase orders</b>
          </h2>
          <Row>
            <Col xs={4} md={4}>
              Period: {firstDay} - {lastDay}
            </Col>
          </Row>

          {listInvoiceCustomSetting.map((row, rowIndex) => {
            return (
              <MerchantPortalSetting
                setting={row}
                key={`setting-${rowIndex}`}
                style={{ paddingTop: "20px" }}
              ></MerchantPortalSetting>
            );
          })}
          <Row>
            <Col xs={4} md={2} />
            <Col xs={4} md={2}>
              <Button style={{
                padding: '4px 8px',
                marginTop: '10px',
                height: '32px',
                lineHeight: '16px'
              }} onClick={() => submitSaveInvoiceCustomField()}>
                Save
              </Button>
            </Col>
          </Row>
          <div className="error-text">{state.valueError}</div>
        </div>
      </Container>
    </div>
  );
};

export default InvoiceSetting;
