import React from 'react';
import classNames from 'classnames';
// import { isMobile } from '../utils/browserDetection';
import { colorMapping } from '../../utils/index';

import bannerImg from '../../assets/images/top-banner.jpg';
import { useUserContext } from 'context/UserContext';

const Banner = (props) => {
    const { userObj } = useUserContext();
    const {
        message,
        messageColor,
        mobileBanner,
        desktopBanner,
    } = props.content;
    const linear = props.gradientBackground ? 'linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #000000 100%),' : '';

    const bannerStyle = {
        backgroundImage: `${linear} url(${props.isMobile ? mobileBanner : desktopBanner})`,
    };
    const textStyle = {
        color: colorMapping(messageColor),
    };

    const bannerClasses = classNames('banner top-banner', {
        'banner-mobile': props.isMobile,
    });
    return (
        <div className={bannerClasses} style={bannerStyle}>
            <div className="top-banner__content">
                <span style={{ color: colorMapping('yellow') }}>Hello {userObj.userTitle},</span>
                <br />
                <span style={textStyle}>{message}</span>
            </div>
        </div>
    );
}

Banner.defaultProps = {
    content: {
        message: 'Welcome to the MoreTyme merchant portal',
        messageColor: 'white',
        mobileBanner: bannerImg,
        desktopBanner: bannerImg,
    },
    isMobile: false,
    gradientBackground: false,
};

export default Banner;
