import { css } from 'styled-components';
import { fonts, colors, breakpoints } from './variables';

/**
 * Mobile first approach
 */
const devices = {
    mobile: breakpoints.xs,
    tablet: breakpoints.sm,
    desktop: breakpoints.md,
    largeDesktop: breakpoints.lg,
};

export const mediaQueries = Object.keys(devices).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${devices[label]}) {
            ${css(...args)};
        }
    `;
    return acc;
}, {});

export default {
    fonts,
    colors,
    breakpoints: [breakpoints.sm, breakpoints.md, breakpoints.lg],
};
