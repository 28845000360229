import React, { memo, useEffect, useState } from "react";
import { Well, Media, Grid, Row, Col, Form } from "react-bootstrap";
import { ConfirmModal } from "../../../common/ConfirmModal";

import iconWarningHelp from "assets/images/warning-help.svg";
import Styled from "styled-components";
import PropTypes from "prop-types";
import {
  Button,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";

const StyledGrid = Styled(Grid)`
  padding-top: 30px;
  padding-bottom: 32px;
`;

const StyledRefundAmountInfo = Styled.div`
  font-size: 20px
`;

const StyledWell = Styled(Well)`
    margin-left: -40px;
    margin-right: -40px;
    height: 90px;
    padding-top: 22px;
    padding-left: 40px;
    padding-right: 40px;
    border-style: none;
    box-shadow: none;
`;
const propTypes = {
  open: PropTypes.bool,
  addNew: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  storeDetail: PropTypes.object,
  listField: PropTypes.object,
};

const ModalEditStore = (props) => {
  // console.log("RENDER")
  const initState = {
    valueError: "",
    reasonError: false,
  };
  const [state, setState] = useState(initState);
  const { open, onClose, onSubmit, storeDetail, addNew, listField } = props;
  const [storeDetailUpdate, setStoreDetailUpdate] = useState({});

  useEffect(() => {
    if (open) {
      console.log("RENDER");
      console.log(listField)
    }
  }, [listField,storeDetailUpdate]);

  // if (listField) {
  //   listField
  //     .filter((x) => x.type === "STORE")
  //     .map((v, k) => {
  //       // console.log(v);
  //     });
  // }

  const buttons = [
    {
      name: "Save",
      classYellow: true,
      click: (e) => {
        var request = {
          stores: [
            addNew
              ? { ...storeDetailUpdate }
              : {
                  ...storeDetailUpdate,
                  referenceId: storeDetail?.referenceId,
                },
          ],
        };
        // console.log(request)
        onSubmit(request);
      },
    },
    {
      name: "Cancel",
      classYellow: false,
      click: (e) => onClose(),
    },
  ];

  const validate = (value) => {
    if (value == null || value === "") {
      return false;
    }
    return true;
  };
  const inputChange = (e) => {
    const { name, value } = e.target;
    setStoreDetailUpdate({
      ...storeDetailUpdate,
      [name]: value,
    });
  };

  const handleChange = (
    newValue
    
  ) => {
    console.group('Value Changed');
    console.log(newValue);
    setStoreDetailUpdate({
      ...storeDetailUpdate,
      status: newValue.value
    })
  };

  return (
    <ConfirmModal
      show={open}
      onHide={onClose}
      onConfirmYes={onSubmit}
      ctitle="EDIT STORE DETAIL"
      mode="custom"
      buttons={buttons}
      animation
    >
      <StyledRefundAmountInfo>
        {addNew ? "Add New Store" : "Edit Store Detail"}
      </StyledRefundAmountInfo>
      <StyledGrid fluid={true}>
        <Form>
          {listField
              ?.filter((x) => x.type === "STORE" && x.key !=="referenceId")
              .map((v, k) => (
                <FormGroup row>
                  <Label for={v.key} sm={4}>
                    {v.value}
                  </Label>
                  <Col sm={8}>
                    {v.key !== "status" ? (
                      <Input
                        id={v.key}
                        name={v.key}
                        defaultValue={storeDetail ? storeDetail[v.key] : ""}
                        onChange={inputChange}
                      />
                    ) : (
                      <Select
                        defaultValue={ { label:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status,value:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status}}
                        // value={selectedAggregator}
                        onChange={handleChange}
                        value={ { label:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status,value:storeDetailUpdate?.status?storeDetailUpdate?.status:storeDetail?.status}}
                        options={[
                          { label: "ACTIVE", value: "ACTIVE" },
                          { label: "INACTIVE", value: "INACTIVE" }
                        ]}
                      />
                    )}
                    {/* <FormFeedback invalid>
                Oh noes! that {v.value} is required.
              </FormFeedback> */}
                  </Col>
                </FormGroup>
              ))}
        </Form>
        {/* <Form>
          <FormGroup row>
            <Label for="externalMerchantId" sm={4}>
              External MID
            </Label>
            <Col sm={8}>
              <Input
                id="externalMerchantId"
                name="externalMerchantId"
                defaultValue={storeDetail?.externalMerchantId}
                onChange={inputChange}
                
              />
              
              <FormFeedback>
                Oh noes! that name is already taken
              </FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="name" sm={4}>
              Store Name
            </Label>
            <Col sm={8}>
              <Input
                id="name"
                name="name"
                defaultValue={storeDetail?.name}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="address" sm={4}>
              Street Address
            </Label>
            <Col sm={8}>
              <Input
                id="address"
                name="address"
                defaultValue={storeDetail?.address}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="suburb" sm={4}>
              Suburb
            </Label>
            <Col sm={8}>
              <Input
                id="suburb"
                name="suburb"
                defaultValue={storeDetail?.suburb}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="city" sm={4}>
              City
            </Label>
            <Col sm={8}>
              <Input
                id="city"
                name="city"
                defaultValue={storeDetail?.city}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="province" sm={4}>
              Province
            </Label>
            <Col sm={8}>
              <Input
                id="province"
                name="province"
                defaultValue={storeDetail?.province}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="contactName" sm={4}>
              Store Contact Name
            </Label>
            <Col sm={8}>
              <Input
                id="contactName"
                name="contactName"
                defaultValue={storeDetail?.contactName}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="contactPhone" sm={4}>
              Store Contact Number
            </Label>
            <Col sm={8}>
              <Input
                id="contactPhone"
                name="contactPhone"
                defaultValue={storeDetail?.contactPhone}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="contactEmail" sm={4}>
              Store Contact Email
            </Label>
            <Col sm={8}>
              <Input
                id="contactEmail"
                name="contactEmail"
                defaultValue={storeDetail?.contactEmail}
                onChange={inputChange}
              />
            </Col>
          </FormGroup>
        </Form> */}
      </StyledGrid>
      <StyledWell>
        <Media>
          <Media.Left className="hidden-xs">
            <img src={iconWarningHelp} alt="" />
          </Media.Left>
          <Media.Body align="middle">
            <div className="text">
              When you take this action, any changes will only be temporarily
              recorded until you Approve
            </div>
            <div
              style={{ display: state.reasonError ? "" : "none" }}
              className={state.reasonError ? "error-text" : ""}
            ></div>
          </Media.Body>
        </Media>
      </StyledWell>
    </ConfirmModal>
  );
};
ModalEditStore.propTypes = propTypes;
export default memo(ModalEditStore);
