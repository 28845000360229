import React, { useState } from 'react';
import iconCompleted from 'assets/images/completed.svg';
import { ConfirmModal } from '../common/ConfirmModal';
import {
  useRefundRequestContext,
  getInitialRefundRequestState,
} from './RefundRequest';
import { displayVoucherCode } from 'utils';

const RefundRequestSuccess = () => {
  const [refundRequestState, setRefundRequestState] = useRefundRequestContext();

  const hideModal = () => {
    resetRefundRequestState();
  };

  const resetRefundRequestState = () => {
    setRefundRequestState({ ...getInitialRefundRequestState() });
  };

  const buttons = [
    {
      name: 'Close',
      classYellow: true,
      click: (e) => hideModal(false, true),
    },
  ];

  return (
    <ConfirmModal
      show={refundRequestState.isAccepted}
      onHide={hideModal}
      title='The reversal was successfully processed'
      mode='custom'
      animation
      icon={iconCompleted}
      buttons={buttons}
      onExited={resetRefundRequestState}
    >
      <>
        <p>
          The customer will receive an SMS from TymeBank with the details of
          their refund.
        </p>
        <div>
          Voucher number:{' '}
          <span className='font-bold'>
            {displayVoucherCode(refundRequestState.voucherNumber)}
          </span>
        </div>
      </>
    </ConfirmModal>
  );
};
export default RefundRequestSuccess;
