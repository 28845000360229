import React, { useEffect, useState } from 'react';
import { Well, Media } from 'react-bootstrap';
import { useDashboardContext, initialState } from './Dashboard';
import iconCompleted from 'assets/images/completed.svg';
import iconWarningHelp from 'assets/images/warning-help.svg';
import iconError from 'assets/images/error.svg';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import ApiService from 'services/apiService';
import uuid from 'uuid';
import Actions from 'actions';
import { useAppContext } from 'context/AppContext';
import { displayAmount, displayVoucherCode } from 'utils';
import { setTimeout } from 'core-js';
import Constant from 'utils/Constant';

const PurchaseVoucherConfirm = () => {
    const [dashboardState, setDashboardState] = useDashboardContext();
    const [appState, setAppState] = useAppContext();
    // Default display popup confirm
    const [step, setStep] = useState('confirm');
    const [inprogress, setInprogress] = useState(false);
    useEffect(() => {
        if (!dashboardState.isHiddenModal) {
            // display popup based on `step` from `dashboardState`
            setStep(dashboardState.step);
        }
    }, [dashboardState]);

    const hideModal = (forceReload = false, cancel = false) => {
        setInprogress(false);
        if (cancel) {
            setDashboardState({
                ...dashboardState,
                isHiddenModal: true,
                step: steps.confirm.name
            });
        } else {
            setDashboardState({
                ...initialState,
                forceReload: forceReload,
                reloadAction: 'redeem'
            });

        }
    };
    const reversalVoucher = (retried = 0, originId) => {
        setAppState({
            type: Actions.waitModal.type,
            hidden: false
        });
        const initHeader = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "id": uuid(),
                "originalId": originId,
                "voucherNumber": dashboardState.voucherCode
            }
        };
        let race = Promise.race([
            clientTimeout(),
            ApiService.submitReversal(initHeader)
        ])
        race.then((res) => {
            // if reversal succeed => redeem is failed
            setDashboardState({
                isHiddenModal: false,
                title: errorCodes['REDEEM_REVERSED'].title,
                errorMessage: errorCodes['REDEEM_REVERSED'].body,
                step: steps.error.name,
                errButtonTitle: errorCodes['REDEEM_REVERSED'].errButtonTitle
            });
        }).catch(err => {
            const erroResponse = err.response;
            let title = errorCodes['12620001'].title;
            let body = errorCodes['12620001'].body;
            let name = steps.error.name;
            if (erroResponse) {
                if (erroResponse.status >= 500) {
                    // revert the voucher
                    if (retried < 3) {
                        reversalVoucher(retried + 1, originId);
                        return;
                    }
                } else if (erroResponse.status >= 400) {
                    if (erroResponse.data.errorCode === '12620016') {
                        title = steps.succeed.title;
                        name = steps.succeed.name;
                    }
                }
                setDashboardState({
                    isHiddenModal: false,
                    title: title,
                    errorMessage: body,
                    step: name
                });
            }
        }).finally(() => {
            setAppState({
                type: Actions.waitModal.type,
                hidden: true
            });
        })
    }
    const clientTimeout = () => {
        // Create a promise that rejects in 30 milliseconds
        const ms = 30000;
        return new Promise((resolve, reject) => {
            let timerId = setTimeout(() => {
                clearTimeout(timerId);
                reject({
                    response: {
                        status: 504
                    }
                })
            }, ms)
        })
    }
    const confirmSubmitVoucher = () => {
        setInprogress(true);
        setAppState({
            type: Actions.waitModal.type,
            hidden: false
        });
        const originId = uuid();
        const initHeader = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "id": originId,
                "amount": dashboardState.amount,
                "voucherNumber": dashboardState.voucherCode
            }
        };

        let race = Promise.race([
            clientTimeout(),
            ApiService.submitRedeem(initHeader)
        ])
        race.then(res => {
            setTimeout(() => {
                setDashboardState({
                    isHiddenModal: false,
                    title: steps.succeed.title,
                    referenceId: res.referenceNumber,
                    step: steps.succeed.name,
                    voucherCode: res.voucherNumber,
                    amount: res.amount
                });
            }, 1000);
        }).catch(err => {
            const erroResponse = err.response;
            let errorTitle = errorCodes['12620001'].title;
            let errorBody = errorCodes['12620001'].body;
            let errButtonTitle = 'OK';
            if (erroResponse) {
                if (erroResponse.status >= 500) {
                    // revert the voucher
                    reversalVoucher(0, originId);
                    return;
                }
                if (erroResponse.data) {
                    if (errorCodes[erroResponse.data.errorCode]) {
                        errorTitle = errorCodes[erroResponse.data.errorCode].title;
                        errorBody = errorCodes[erroResponse.data.errorCode].body;
                        errButtonTitle = errorCodes[erroResponse.data.errorCode].errButtonTitle;
                    }
                }
            }
            setDashboardState({
                isHiddenModal: false,
                title: errorTitle,
                errorMessage: errorBody,
                step: steps.error.name,
                errButtonTitle: errButtonTitle
            });
        }).finally(() => {
            setAppState({
                type: Actions.waitModal.type,
                hidden: true
            });
        })
    };
    const confirmWaring = 'First double-check that the voucher code and amount are correct because redeeming the voucher can\'t be reversed';
    const errorCodes = {
        '504': {
            title: 'Network Error',
            body: <>
                <p>Something is temporarily wrong with your network connection. Please make sure you are connecte to the internet and then reload your browser.</p>
            </>,
            errButtonTitle: 'OK'
        },
        'REDEEM_REVERSED': {
            title: 'We weren\'t able to redeem the payment voucher',
            body: <>
                <p>There may have been a technical issue. Ask the customer if they'd like to create a new payment voucher and then try again.</p>
            </>,
            errButtonTitle: 'Try again'
        },
        '12620001': {
            title: 'We\'re having some technical issues',
            body: <>
                <p>Sorry about that. Come back and try again later or contact our Customer Contact Centre on <span className="font-bold">{Constant.PHONE_NUMBER}</span>.</p>
            </>,
            errButtonTitle: 'OK'
        },
        '12620009': {
            title: 'Can\'t connect to MoreTyme',
            body: <>
                <p>This store has become inactive. You won't be able to process any MoreTyme payments until the store becomes active.</p>
            </>,
            errButtonTitle: 'OK'
        },
        '12620010': {
            title: 'The voucher can\'t cover the full payment amount',
            body: <>
                <p>Check the maximum available spend on the customer's MoreTyme voucher and try a lower amount.</p>
                <p>Attempted amount: <span className="font-bold">{displayAmount(dashboardState.amount)}</span></p>
            </>,
            errButtonTitle: 'Try a lower amount'
        },
        '12620011': {
            title: 'That\'s lower than the minimum payment amount',
            body: <>
                <p>The lowest possible payment with MoreTyme is <span className="font-bold">R50.00</span>.</p>
                <p>Attempted amount: <span className="font-bold">{displayAmount(dashboardState.amount)}</span></p>
            </>,
            errButtonTitle: 'Try a higher amount'
        },
        '12620012': {
            title: 'This payment voucher has expired',
            body: <>
                <p>This payment voucher has expired. <br />
                    Ask the customer if they'd like to create a new payment voucher and then try again.</p>
                <p>Voucher code: <span className="font-bold">{displayVoucherCode(dashboardState.voucherCode)}</span></p>
            </>,
            errButtonTitle: 'OK'
        },
        '12620013': {
            title: 'We don\'t recognise that voucher code',
            body: <>
                <p>The voucher code you entered isn't in our system. <br /> Double-check the voucher number and try again.</p>
                <p>Voucher code: {' '}<span className="font-bold">{displayVoucherCode(dashboardState.voucherCode)}</span></p>
            </>,
            errButtonTitle: 'Try again'
        },
        '12620014': {
            title: 'This payment voucher is already being processed',
            body: <>
                <p>The voucher code you entered is currently in the process of being redeemed. Wait a moment and then check Purchase History</p>
                <p>Voucher code: <span className="font-bold">{displayVoucherCode(dashboardState.voucherCode)}</span></p>
            </>,
            errButtonTitle: 'OK'
        },
        '12620016': {
            title: 'That payment voucher has already been used',
            body: <>
                <p>Double-check that you entered the correct voucher number. <br />
                    If you did, ask the customer if they can create a new payment voucher and then try again.</p>
                <p>Voucher code: <span className="font-bold">{displayVoucherCode(dashboardState.voucherCode)}</span></p>
            </>,
            errButtonTitle: 'OK'
        },
        '12620019': {
            title: 'This payment voucher has been canceled',
            body: <>
                <p>This payment voucher has been canceled. <br /> Ask the customer if they'd like to create a new payment voucher and then try again.</p>
                <p>Voucher code: <span className="font-bold">{displayVoucherCode(dashboardState.voucherCode)}</span></p>
            </>,
            errButtonTitle: 'OK'
        }
    }
    const steps = {
        confirm: {
            name: 'confirm',
            title: 'Sure you want to redeem this payment voucher?',
            buttons: [
                {
                    name: 'Cancel',
                    classYellow: false,
                    click: e => hideModal(false, true),
                },
                {
                    name: 'Yes',
                    classYellow: true,
                    click: e => confirmSubmitVoucher(),
                    inprogress: inprogress
                }
            ],
            children: <>
                <p>Voucher code: <span className="font-bold">{displayVoucherCode(dashboardState.voucherCode)}</span></p>
                <p>Payment amount: <span className="font-bold">{displayAmount(dashboardState.amount)}</span></p>
                <Well>
                    <Media>
                        <Media.Left className="hidden-xs">
                            <img src={iconWarningHelp} alt="" />
                        </Media.Left>
                        <Media.Body align="middle">
                            <div className="text">{confirmWaring}</div>
                        </Media.Body>
                    </Media>
                </Well>
            </>
        },
        succeed: {
            name: 'succeed',
            title: 'Voucher has successfully been redeemed',
            buttons: [
                {
                    name: 'Close',
                    classYellow: true,
                    click: e => hideModal(true),
                }
            ],
            children: <>
                <p>Voucher code: <span className="font-bold">{displayVoucherCode(dashboardState.voucherCode)}</span></p>
                <p>Payment amount: <span className="font-bold">{displayAmount(dashboardState.amount)}</span></p>
                <p>Reference ID: <span className="font-bold">{dashboardState.referenceId}</span></p>
            </>
        },
        error: {
            name: 'error',
            title: dashboardState.title,
            buttons: [
                {
                    name: dashboardState.errButtonTitle,
                    classYellow: true,
                    click: e => hideModal(false, true),
                }
            ],
            children: <>{dashboardState.errorMessage}</>
        }
    }
    return (
        <ConfirmModal
            show={!dashboardState.isHiddenModal}
            onHide={e => hideModal(false, true)}
            title={dashboardState.title}
            mode="custom"
            animation
            icon={step === 'confirm' ? null : step === 'succeed' ? iconCompleted : iconError}
            buttons={steps[step].buttons}
        >
            {steps[step].children}
        </ConfirmModal>
    );
}

export default PurchaseVoucherConfirm;
