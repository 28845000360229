import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormControl, FormGroup, ControlLabel, Button, utils } from 'react-bootstrap';
import ApiService from 'services/apiService';
import { useUserContext, createUserObj } from 'context/UserContext';
import { useAppContext } from 'context/AppContext';
import imgLoginBg from 'assets/images/login-bg.jpg';
import imgLoginBgXs from 'assets/images/login-bg_xs.jpg';

import { ReactComponent as LogoWhiteIcon } from 'assets/images/logo-tyme-digital-white.svg';

import Actions from 'actions';
import ForgotPasswordConfirm from './ForgotPasswordConfirm';
import { colorMapping } from '../../utils/index';
import Constant from 'utils/Constant';

utils.bootstrapUtils.addStyle(Button, 'yellow');
const Login = () => {
  const phoneNumber = Constant.PHONE_NUMBER;
  const emailSupport = Constant.EMAIL_SUPPORT;
  const { setUserObj } = useUserContext();
  const setAppState = useAppContext()[1];
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({
    userName: '',
    userNameError: '',
    loginButtonLabel: 'Login',
    loginButtonEnabled: true,
    password: '',
    passwordError: '',
    showForgotPassword: false,
    title: '',
    errorMsg: ''
  });

  useEffect(() => {
    // Set auto fill username if logged before
    if (sessionStorage.getItem('username')) {
      setState({
        ...state,
        userName: sessionStorage.getItem('username'),
      });
    }
  }, []);
  const validate = () => {
    // Handler validate login input
    const { userName, password } = state;
    if (userName.trim().length === 0) {
      setState({
        ...state,
        userNameError: 'Please enter a username to continue',
        passwordError: ''
      });
      return false;
    };
    if (password.trim().length === 0) {
      setState({
        ...state,
        passwordError: 'Please enter a valid password',
        userNameError: ''
      });
      return false;
    };
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const verifyLoginForm = () => {
    if (!validate()) return;
    login();
  };

  const isFederatedLogin = (userName) => {
    return !!Constant.ACCEPTED_EMAIL_DOMAIN_ACCOUNT.find(account=> userName.toUpperCase().endsWith(account))
  }

  const getProviderName = (userName) => {
    const index = Constant.ACCEPTED_EMAIL_DOMAIN_ACCOUNT.findIndex(account=> userName.toUpperCase().endsWith(account));
    return Constant.FEDERATED_PROVIDER_NAME[index];
  }

  const federatedLogin = (userName) => {
    const { from } = location.state || { from: { pathname: '/' } };
    ApiService.federatedLogin(getProviderName(userName), from.pathname)
    .then((response) => {
      updateUser(response)
    })
    .catch((err) => {
      console.debug(err);
    });
  }

  const redirectFederatedLogin = () => {
    const { userName } = state;
    if(isFederatedLogin(userName)) {
      federatedLogin(userName);
    }
  }

  const updateUser = (response) => {
    const { from } = location.state || { from: { pathname: '/' } };
    const needUpdatePassword = response.challengeName === 'NEW_PASSWORD_REQUIRED';
    let merchantId = null;
    let storeId = null;
    let termsAndConditions = null;
    let userTitle = 'Unknown'
    let featureRestriction = [];
    let userRole = [];
    if (response.attributes) {
      if (response.attributes['custom:merchant_id']) {
        merchantId = response.attributes['custom:merchant_id'];
      }
      if (response.attributes['custom:store_id']) {
        storeId = response.attributes['custom:store_id'];
      }
      if (response.attributes['custom:terms_n_conditions']) {
        termsAndConditions = response.attributes['custom:terms_n_conditions'];
      }
      if (response.attributes['custom:full_name']) {
        userTitle = response.attributes['custom:full_name'];
      }
      if(response.attributes['custom:restriction']){
        featureRestriction = response.attributes['custom:restriction'].split(',');
      }
      if(response.attributes['custom:role']){
        userRole = response.attributes['custom:role'].split(',');
      }
    }
    setUserObj(createUserObj(response.username, userTitle, true, needUpdatePassword, response, merchantId, storeId, new Date().getTime(), null, termsAndConditions, featureRestriction, userRole));
    sessionStorage.setItem('username', response.username);
    if (needUpdatePassword) {
      history.push('/reset-password');
    } else {
      history.replace(from);
    }
  }

  const login = async () => {
    if (!validate()) return;
    const { userName, password } = state;
    setState({
      ...state,
      loginButtonLabel: 'Logging you in...',
      loginButtonEnabled: false,
      errorMsg: null,
    });

    // let payload = '';
    sessionStorage.clear();
    if(isFederatedLogin(userName)) {
      federatedLogin();
      return null;
    }

    ApiService.login(userName, password)
      .then((response) => {
        updateUser(response);
      })
      .catch((err) => {
        if (err.message === 'Incorrect username or password.') {
          setState({
            ...state,
            loginButtonLabel: 'Login',
            loginButtonEnabled: true,
            password: '',
            errorMsg: 'Invalid username or password. If you enter wrong password many times your account will be locked'
          });
        } else if (err.code === 'NotAuthorizedException' || err.code === 'UserLambdaValidationException') {
          setState({
            ...state,
            loginButtonLabel: 'Login',
            loginButtonEnabled: true,
            password: '',
            errorMsg: '',
            passwordError: '',
            userNameError: ''
          });
          setAppState({
            type: Actions.toast.type,
            hidden: false,
            props: {
              type: 'error',
              message: <>For security reasons, your account has been locked. For asssistance, call <span className="font-bold">{phoneNumber}</span> (7 AM SAST to 8 PM SAST 7 days a week) </>
            }
          });
        } else {
          setState({
            ...state,
            loginButtonLabel: 'Login',
            loginButtonEnabled: true,
            password: '',
            errorMsg: 'Login is failed',
            passwordError: '',
            userNameError: ''
          });
        }
        return err;
      });
    return null;
  };


  const handleOnBlurUserName = (e) => {
    handleOnBlur(e);
    redirectFederatedLogin();

  }

  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'userName' && value) {
      setState({
        ...state,
        userNameError: ''
      });
    }
    if (name === 'password' && value) {
      setState({
        ...state,
        passwordError: ''
      });
    }
  }

  const handleKeyPress = (e, enable) => {
    if (e.key === 'Enter' && enable) {
      verifyLoginForm();
    }
  };

  const showForgotPasswordConfirm = (show) => {
    setState({
      ...state,
      showForgotPassword: show
    })
  };

  const { loginButtonEnabled } = state;
  const disableLoginButton = !loginButtonEnabled;
  return (
    <div className="loginPage">
      <style>
        {`
                        html{
                            overflow: auto;
                        }
                        #root {
                            background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${imgLoginBg}) no-repeat center top;
                            background-size: cover;
                        }

                        @media screen and (max-width:767px) {
                            #root {
                                background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${imgLoginBgXs});
                                background-position: 70% 0;
                            }
                        }
                        footer {
                            position: relative;
                        }
                        .page-footer {
                            margin-top:0;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                        }

                        .text-about-cba {
                            display:block!important;
                        }

                        body{
                            height:auto;
                            min-height:100%;
                            overflow:auto;
                            padding-right: 0px!important;
                        }

                        #body {
                            margin-top: 0;
                            position: relative;
                        }

                        .login-errors-line{
                            font-size: 16px;
                            margin-bottom:1em;
                        }

                        .logo svg {
                            width: 360px;
                            height: 90px
                        }

                        @media screen and (max-width:767px) {
                            .login-errors-line{
                                font-size: 14px;
                            }
                            .logo svg {
                                width: 240px;
                                height: 62px;
                            }
                        }

                    `}
      </style>
      <p className="bg-overlay" />
      <div className="wrapper">
        <div className="header">
          <div className="container text-center">
            <a className="logo">
              <LogoWhiteIcon />
            </a>
          </div>
        </div>
        {state.showForgotPassword ? <ForgotPasswordConfirm callBack={() => showForgotPasswordConfirm(false)} /> : null}
        <div className="mainContent">
          <div className="loginBox">
            <form action="">
              <div className="container login-box-container">
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <FormGroup>
                      <ControlLabel>Username</ControlLabel>
                      <FormControl
                        disabled={!loginButtonEnabled}
                        type="text"
                        name="userName"
                        autoComplete="username"
                        value={state.userName}
                        placeholder="Enter your Username"
                        onChange={handleInputChange}
                        onKeyPress={(e) => handleKeyPress(e, loginButtonEnabled)}
                        onBlur={handleOnBlurUserName}
                        maxLength={Constant.USERNAME_MAX_LENGTH}
                      />
                      <div className="error-text">
                        {state.userNameError}
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        disabled={!loginButtonEnabled}
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        value={state.password}
                        placeholder="Enter your Password"
                        onChange={handleInputChange}
                        onKeyPress={(e) => handleKeyPress(e, loginButtonEnabled)}
                        onBlur={handleOnBlur}
                        maxLength={Constant.PASSWORD_MAX_LENGTH}
                      />
                      <div className="error-text">
                        {state.passwordError}
                      </div>
                      <div className="reset-password">
                        <Button
                          bsStyle='link'
                          style={{ background: 'None', border: 'None', color: 'white' }}
                          onClick={e => showForgotPasswordConfirm(true)}
                        >I forgot my password</Button>
                      </div>
                    </FormGroup>
                  </div>
                </div>
                {state.errorMsg && (
                  <div className="row">
                    <div className="login-errors-line form-group">
                      {state.errorMsg && (
                        <span className="error-text">{state.errorMsg}</span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="buttons">
                <Button
                  bsStyle="yellow"
                  block
                  disabled={disableLoginButton}
                  onClick={verifyLoginForm}
                  type="button">
                  {state.loginButtonLabel}
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="footer">
          <div className="text-contact-us">
            Need help? Call us on{' '} <span className="font-bold" style={{ color: colorMapping('yellow') }} >{phoneNumber}</span> (7 AM SAST to 8 PM SAST 7 days a week) <br />
            or contact us via email{' '} <span className="font-bold" style={{ color: colorMapping('yellow') }} >{emailSupport}</span>
          </div>
          <div className="text-about-cba">
            <div className="container">
              TymeBank is an authorised Financial Services (FSP 49140) and Registered
              Credit Provider (NCRCP 10774). Tyme Bank Limited Reg no: 2015/231510/06
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
