import React, { useContext, useReducer, createContext } from "react";
import RefundRequestSubmission from "./RefundRequestSubmission";
import RefundRequestSuccess from "./RefundRequestSuccess";
import RefundRequestConfirm from "./RefundRequestConfirm";

const reducer = (state, action) => {
  return {
    ...state,
    ...action,
  };
};
const initialState = {
  voucherNumber: "",
  referenceNumber: "",
  purchaseDate: "",
  purchaseAmount: "",
  approvedRefundAmount: "",
  note: "",
  isValidated: false,
  isAccepted: false,
  isVoucherRefunding: false,
};

export function getInitialRefundRequestState() {
  return initialState;
}

const RefundRequestContext = createContext(initialState);

const RefundRequest = () => {
  return (
    <RefundRequestContext.Provider value={useReducer(reducer, initialState)}>
      <RefundRequestSubmission></RefundRequestSubmission>
      <RefundRequestConfirm></RefundRequestConfirm>
      <RefundRequestSuccess />
    </RefundRequestContext.Provider>
  );
};

export function useRefundRequestContext() {
  return useContext(RefundRequestContext);
}
export default RefundRequest;
