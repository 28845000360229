import React from 'react';
import { Well, Media, Grid, Row, Col } from 'react-bootstrap';
import { useAppContext } from 'context/AppContext';
import { ConfirmModal } from '../common/ConfirmModal';
import { useRefundRequestContext } from './RefundRequest';
import { useDashboardContext } from 'pages/dashboard/Dashboard';
import {
  displayAmount,
  formatDate,
  displayVoucherCode,
  convertVoucherToNumber,
} from 'utils';
import iconWarningHelp from 'assets/images/warning-help.svg';
import Styled from 'styled-components';
import Actions from 'actions';
import uuid from 'uuid';
import ApiService from 'services/apiService';

const StyledCol = Styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

const SecondStyledCol = Styled(Col)`
  float: right;
  padding-right: 0px;
`;

const StyledGrid = Styled(Grid)`
  padding-top: 30px;
  padding-bottom: 32px;
`;

const StyledRefundAmountInfo = Styled.div`
  font-size: 20px
`;

const SpanValue = Styled.span`
  padding-left: 10px;
  white-space: nowrap;
`;

const StyledWell = Styled(Well)`
    margin-left: -40px;
    margin-right: -40px;
    height: 90px;
    padding-top: 22px;
    padding-left: 40px;
    padding-right: 40px;
    border-style: none;
    box-shadow: none;
`;

const RefundRequestConfirm = (props) => {
  const [dashboardState, setDashboardState] = useDashboardContext();
  const [refundRequestState, setRefundRequestState] = useRefundRequestContext();
  const [appState, setAppState] = useAppContext();

  const hideModal = () => {
    setRefundRequestState({ isValidated: !refundRequestState.isValidated });
  };

  const buttons = [
    {
      name: 'Edit',
      classYellow: false,
      click: (e) => hideModal(false, true),
    },
    {
      name: 'Confirm',
      classYellow: true,
      click: (e) => requestRefund(),
    },
  ];

  const requestRefund = async () => {
    try {
      setAppState({
        type: Actions.waitModal.type,
        hidden: false,
      });
      const originId = uuid();
      const request = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          id: originId,
          amount: refundRequestState.approvedRefundAmount,
          voucherNumber: convertVoucherToNumber(
            refundRequestState.voucherNumber
          ),
          note: refundRequestState.note,
        },
      };
      const responseData = await ApiService.requestRefund(request);
      showRequestAccepted(responseData);
    } catch (error) {
      setAppState({
        type: Actions.technicalIssue.type,
        hidden: false,
      });
    } finally {
      setAppState({
        type: Actions.waitModal.type,
        hidden: true,
      });
      setRefundRequestState({ isValidated: false });
    }
  };

  const showRequestAccepted = () => {
    setRefundRequestState({ isAccepted: true });
    setDashboardState({
      ...dashboardState,
      forceReload: true,
      reloadAction: 'refund'
    });
  };

  return (
    <ConfirmModal
      show={refundRequestState.isValidated}
      onHide={(e) => hideModal()}
      title='Confirm the return amount'
      mode='custom'
      animation
      buttons={buttons}
    >
      <StyledRefundAmountInfo>
        Returning{' '}
        <span className='font-bold'>
          {displayAmount(refundRequestState.approvedRefundAmount)}
        </span>{' '}
        from:
      </StyledRefundAmountInfo>
      <StyledGrid fluid={true}>
        <Row className='show-grid'>
          <StyledCol xs={6} sm={6} md={6}>
            Voucher number:{' '}
            <SpanValue className='font-bold'>
              {displayVoucherCode(refundRequestState.voucherNumber)}
            </SpanValue>
          </StyledCol>
          <SecondStyledCol xs={6} sm={6} md={6}>
            Purchase date:{' '}
            <SpanValue className='font-bold'>
              {formatDate(refundRequestState.purchaseDate, 'DD MMM YYYY')}
            </SpanValue>
          </SecondStyledCol>
        </Row>
        <Row className='show-grid'>
          <StyledCol xs={6} sm={6} md={6}>
            Reference ID:{' '}
            <SpanValue className='font-bold'>
              {refundRequestState.referenceNumber}
            </SpanValue>
          </StyledCol>
          <SecondStyledCol xs={6} sm={6} md={6}>
            <span> Purchase amount: </span>
            <SpanValue className='font-bold'>
              {displayAmount(refundRequestState.purchaseAmount)}
            </SpanValue>
          </SecondStyledCol>
        </Row>
      </StyledGrid>
      <StyledWell>
        <Media>
          <Media.Left className='hidden-xs'>
            <img src={iconWarningHelp} alt='' />
          </Media.Left>
          <Media.Body align='middle'>
            <div className='text'>
              First double-check that the voucher number and amount are correct
              because the return can't be reversed.
            </div>
          </Media.Body>
        </Media>
      </StyledWell>
    </ConfirmModal>
  );
};
export default RefundRequestConfirm;
