import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { Flex, Box } from "rebass/styled-components";
import { Divider } from "@material-ui/core";
import _ from "lodash";
import ApiService from "services/apiService";
import iconPurchaseHistory from "assets/images/purchase-history.svg";
import Spinner from "components/common/Spinner";
import Button from "react-bootstrap/lib/Button";
import { Input } from "reactstrap";
import Actions from "actions/index";
import { useAppContext } from "context/AppContext";
import { Modal } from "react-bootstrap";
import moment from "moment";
import TextArea from "react-textarea-autosize";
import { colorMapping } from "utils";
import uuid from 'uuid';
import { displayAmount } from 'utils';

const VoucherManagement = () => {
  const initState = {
    keyword: "",
    voucherNumber: "",
    valueError: "",
    note: "",
    reasonError: false,
  };
  const [state, setState] = useState(initState);
  const setAppState = useAppContext()[1];
  const [isShowLoadMore, setShowMore] = useState(true);
  const [data, setHistories] = useState([]);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const defaultLimit = 10;

  const title = (
    <span className="font-bold">
      {" "}
      <img src={iconPurchaseHistory} alt="" /> {"Voucher Management"}
    </span>
  );

  const BoxContent = ({ children, ...rest }) => (
    <Box {...rest}>
      <Box px={[2, 3, 4]}>{children}</Box>
    </Box>
  );

  const Header = ({ headers }) => {
    return (
      <Flex fontSize={["14px", "16px"]} py={3} className="font-bold">
        {headers.map((header, index) =>
          header.isVisible ? (
            <Box key={`purchase-header-${index}`} width={header.width}>
              {header.Header}
            </Box>
          ) : null
        )}
      </Flex>
    );
  };

  const VoucherManagementTable = () => {
    // init one time
    const [columns] = useState(
      useMemo(
        () => [
          {
            Header: "Voucher Number",
            accessor: "voucherNumber",
            width: "20%",
            style: "cursor:pointer",
            Cell: (props) => (
              <a onClick={() => handleModalShow(props.row.original)}>
                {props.value}
              </a>
            ),
          },
          {
            Header: "Short Code",
            accessor: "shortCode",
            width: "20%",
          },
          {
            Header: "Amount",
            accessor: "amount",
            width: "20%",
            Cell: ({ value }) => displayAmount(value)
          },
          {
            Header: "Purchase Date",
            accessor: "purchaseDate",
            width: "20%",
            Cell: ({ value }) => {
              if (value) {
                return moment(new Date(value)).format("DD MMM YYYY, HH:mm:ss");
              }
              return value;
            },
            filter: "between",
          },
          {
            Header: "Account Number",
            accessor: "accountNumber",
            width: "20%",
          },
          {
            Header: "Status",
            accessor: "status",
            width: "20%",
          },
        ],
        []
      )
    );

    // Init a instance of table by usetable
    const instanceTable = useTable(
      {
        columns,
        data,
        initialState: {},
      },
      useFilters,
      useSortBy
    );
    const { headers, rows, prepareRow } = instanceTable;
    const loadMoreData = (callBack = null, curdata = data, curPage = page) => {
      // make header request
      const initHeader = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      // make a http call to fetch data
      ApiService.getVoucherList(
        initHeader,
        curPage,
        defaultLimit,
        state.keyword
      )
        .then((resData) => {
          if (resData) {
            if (resData.length === 0) {
              setShowMore(false);
            } else {
              const tempHistory = [...curdata, ...resData];
              const histories = _.uniq(tempHistory, "keyword");
              setHistories(histories);
              setPage(curPage + 1);
            }
          }
        })
        .catch((err) => {
          // handle log
        });
    };

    return (
      <Fragment>
        <BoxContent>
          <Header headers={headers} />
        </BoxContent>
        <BoxContent>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <div key={`row-${row.values.voucherNumber}`}>
                <Divider />
                <Flex fontSize={["14px", "16px"]} py={3}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Box
                        width={cell.column.width}
                        key={`Cell-${rowIndex}-${cellIndex}`}
                      >
                        {cell.render("Cell")}
                      </Box>
                    );
                  })}
                </Flex>
              </div>
            );
          })}
        </BoxContent>
        <Box mx={[2, 4]} className="hidden-xs">
          <Divider />
        </Box>
        <EndingSection
          isShowLoadMore={isShowLoadMore}
          loadMoreData={loadMoreData}
        />
      </Fragment>
    );
  };

  const EndingSection = ({ isShowLoadMore, loadMoreData }) => {
    const [loading, setLoading] = useState(false);
    const loadMoreClick = () => {
      setLoading(true);
      if (loadMoreData) {
        loadMoreData(afterLoadMore);
      }
    };
    const afterLoadMore = () => {
      setLoading(false);
    };
    return (
      <Flex p={3} justifyContent="center">
        {loading ? (
          <Spinner />
        ) : isShowLoadMore ? (
          <Button onClick={loadMoreClick}>Load more transactions</Button>
        ) : (
          "End of list."
        )}
      </Flex>
    );
  };

  const search = () => {
    let curPage = 0;
    if (!validate(state.keyword)) {
      setState({
        ...state,
        valueError: "Please enter a keyword to search",
      });
      return;
    }

    setState({
      ...state,
      valueError: "",
    });
    const initData = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    ApiService.getVoucherList(initData, curPage, defaultLimit, state.keyword)
      .then((resData) => {
        if (resData) {
          if (resData.length === 0) {
            setShowMore(false);
            const tempHistory = [...resData];
            const histories = _.uniq(tempHistory, "voucherNumber");
            setHistories(histories);
          } else {
            setShowMore(true);
            const tempHistory = [...resData];
            const histories = _.uniq(tempHistory, "voucherNumber");
            setHistories(histories);
            setPage(curPage + 1);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Internal server error";
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
        // handle log
      })
      .finally((fn) => {});
  };

  const validate = (value) => {
    if (value == null || value === '') {
      return false;
    }
    return true;
  };

  const inputChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleModalClose = () => {
    state.note = '';
    state.reasonError = false;
    setShow(false);
  };

  const handleModalShow = (value) => {
    state.voucherNumber = value.voucherNumber;
    setShow(true);
  };

  const submitRefund = () => {
    if (!validate(state.note)) {
      setState({
        ...state,
        reasonError: true,
      });
      return;
    }

    setState({
      ...state,
      reasonError: false,
    });
    const initData = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        id: uuid(),
        voucherNumber: state.voucherNumber,
        note: state.note
      },
    };

    const successMessage = "Refund successfully";
    ApiService.refundVoucher(initData)
      .then((resData) => {
          handleModalClose();
          setAppState({
            type: Actions.toast.type,
            hidden: false,
            props: {
              type: "success",
              message: successMessage,
            },
          });
          search();
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Internal server error";
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
        console.debug(err);
        // handle log
      })
      .finally((fn) => {
        
      });
  };

  const submitRedeemReversal = () => {
    if (!validate(state.note)) {
      setState({
        ...state,
        reasonError: true,
      });
      return;
    }

    setState({
      ...state,
      reasonError: false,
    });
    const initData = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        id: uuid(),
        voucherNumber: state.voucherNumber,
        note: state.note
      },
    };

    const successMessage = "Reverse successfully";
    ApiService.redeemReversalVoucher(initData)
      .then((resData) => {
          handleModalClose();
          setAppState({
            type: Actions.toast.type,
            hidden: false,
            props: {
              type: "success",
              message: successMessage,
            },
          });
          search();
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Internal server error";
        setAppState({
          type: Actions.toast.type,
          hidden: false,
          props: {
            type: "error",
            message: errorMessage,
          },
        });
        console.debug(err);
        // handle log
      })
      .finally((fn) => {
        
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  }

  return (
    <div className="box" style={{ overflow: "visible", zIndex: 0 }}>
      <Flex alignItems="center">
        <Box mx={5} py={2}>
          <h3>{title}</h3>
        </Box>
      </Flex>
      <Box mx={[2, 4]}>
        <Divider />
      </Box>
      <Flex alignItems="center">
        <Box
          mx={5}
          py={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Search: </span>
          <div
            style={{
              margin: "0 12px",
              minWidth: "250px",
            }}
          >
            <Input
              name="keyword"
              placeholder="Enter the keyword"
              value={state.keyword}
              defaultValue={state.keyword}
              onChange={inputChange}
              type="text"
              onKeyDown={handleKeyDown}
            />
          </div>

          <Button
            style={{
              padding: "4px 8px",
              height: "32px",
              lineHeight: "16px",
            }}
            onClick={search}
          >
            Search
          </Button>

          <Modal show={show}>
            <Modal.Header closeButton onClick={handleModalClose}>
              <Modal.Title>
                Warning for voucher number: {state.voucherNumber}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TextArea
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderColor: state.reasonError ? colorMapping("error") : null,
                  resize: "none",
                }}
                name="note"
                value={state.note}
                placeholder="Enter the note for voucher"
                onChange={inputChange}
                minRows={4}
                maxRows={4}
                rows={4}
                className="form-control"
              />
              <div
                style={{ display: state.reasonError ? "" : "none" }}
                className={state.reasonError ? "error-text" : ""}
              ></div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => submitRedeemReversal()}>
                Reverse
              </Button>
              <Button variant="primary" onClick={() => submitRefund()}>
                Refund
              </Button>
            </Modal.Footer>
          </Modal>
        </Box>
      </Flex>
      <div
        style={{
          marginLeft: "130px",
        }}
        className="error-text"
      >
        {state.valueError}
      </div>
      <Box mx={[2, 4]}>
        <Divider />
      </Box>
      <VoucherManagementTable
        key="VoucherManagementTable"
        voucherNumber={state.voucherNumber}
      />
    </div>
  );
};

export default VoucherManagement;
