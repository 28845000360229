import React, { useEffect, useState } from 'react';

import { useHistory } from "react-router-dom";
import { ConfirmModal } from 'components/common/ConfirmModal';
import { useUserContext } from 'context/UserContext';
import ApiService from 'services/apiService';
import imgLoginBg from 'assets/images/login-bg.jpg';
import imgLoginBgXs from 'assets/images/login-bg_xs.jpg';
import { ReactComponent as LogoWhiteIcon } from 'assets/images/logo-tyme-digital-white.svg';
import policyIcon from 'assets/images/policy.svg';
import errorIcon from 'assets/images/error.svg';
import { Configuration } from 'config';

const TERMS_AND_CONDITIONS_VERSION = Configuration.termAndCondition.VERSION;
const TERMS_AND_CONDITIONS = <>
    <p>By Using the MoreTyme Portal I agree to the following:</p>
    <p>1. I will use the site responsibly in the way it is intended to be used.</p>
    <p>2. I will protect my login credentials and will be responsible for the activities of anyone I share my details with.</p>
    <p>3. I will make sure that everyone that has access to my profile does so responsibly and protects the login credentials.</p>
    <p>4. I will not reverse engineer any part of the portal, use any bots or scripts, inject code, or attempt to gain unauthorised access to any other part of the part or Tyme Infrastructure.</p>
</>;
const TermAndConditions = () => {
    const { userObj, setUserObj } = useUserContext();
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);
    const [submittingError, setSubmittingError] = useState(false);
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (userObj.termsAndConditions === '1') {
            history.push('/');
        }
    }, [userObj.termsAndConditions]);
    const hideModal = () => {
        history.push('/logout');
    };

    const acceptTnCs = () => {
        setSubmitting(true);
        const initHeader = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "version": TERMS_AND_CONDITIONS_VERSION
            }
        };
        ApiService.acceptTermAndConditions(initHeader)
        .then(res => {
            console.debug(res);
            ApiService.updateUserAttributes(userObj.congitoUser, {
                'custom:terms_n_conditions': '1'
            }).then(res => {
                setUserObj({
                    ...userObj,
                    termsAndConditions: '1'
                })
            }).catch(err => {
                console.debug(err);
                setSubmittingError(true);
                setShow(false);
            }).finally(() => {
                setSubmitting(false);
            });
        }).catch(err => {
            console.debug(err.response);
            setSubmittingError(true);
            setShow(false);
        }).finally(() => {
            setSubmitting(false);
        });

    };
    const title = 'MoreTyme Merchant Ts&Cs';
    const buttons = [
        {
            name: 'Exit',
            classYellow: false,
            click: hideModal,
        },
        {
            name: 'Accept Ts&Cs',
            classYellow: true,
            click: acceptTnCs,
            inprogress: submitting
        },
    ];
    const errorButtons = [
        {
            name: 'OK',
            classYellow: true,
            click: hideModal,
        }
    ];
    return (
        <div className="loginPage">
            <style>
                {`
                        html{
                            overflow: auto;
                        }
                        #root {
                            background: url(${imgLoginBg}) no-repeat center top;
                            background-size: cover;
                        }

                        @media screen and (max-width:767px) {
                            #root {
                                background-image: url(${imgLoginBgXs});
                                background-position: 70% 0;
                            }
                        }
                        footer {
                            position: relative;
                        }
                        .page-footer {
                            margin-top:0;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                        }

                        .text-about-cba {
                            display:block!important;
                        }

                        body{
                            height:auto;
                            min-height:100%;
                            overflow:auto;
                            padding-right: 0px!important;
                        }

                        #body {
                            margin-top: 0;
                            position: relative;
                        }

                        .login-errors-line{
                            font-size: 16px;
                            margin-bottom:1em;
                        }

                        .logo svg {
                            width: 360px;
                            height: 90px
                        }

                        @media screen and (max-width:767px) {
                            .login-errors-line{
                                font-size: 14px;
                            }
                            .logo svg {
                                width: 240px;
                                height: 62px;
                            }
                        }

                    `}
            </style>
            <p className="bg-overlay" />
            <div className="wrapper">
                <div className="header">
                    <div className="container text-center">
                        <a className="logo">
                            <LogoWhiteIcon />
                        </a>
                    </div>
                </div>
                <div className="mainContent">
                    <div className="loginBox">

                    </div>
                </div>
            </div>
            {submittingError && <ConfirmModal
                show={true}
                onHide={hideModal}
                title={'We\'re having some technical issues'}
                mode="custom"
                animation
                icon={errorIcon}
                buttons={errorButtons}
            >
                Sorry about that. Come back and try again later.
                </ConfirmModal>}
            <ConfirmModal
                show={show}
                onHide={hideModal}
                title={title}
                mode="custom"
                animation
                onConfirmYes={acceptTnCs}
                onConfirmNo={hideModal}
                icon={policyIcon}
                buttons={buttons}
            >
                <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    {TERMS_AND_CONDITIONS}
                </div>
            </ConfirmModal>
        </div>
    );
}

export default TermAndConditions;
